import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import axios from "axios";

import {
    SET_PRODUCT_REPORT,
    SET_PRODUCT_REPORT_ACTION_BEFORE,
    SET_PRODUCT_REPORT_ACTION_FINISH,
    SET_PRODUCT_REPORT_FILTERS
} from "../../../actionTypes";


const setProductReportActionBefore = () => ({
    type: SET_PRODUCT_REPORT_ACTION_BEFORE,
});

const setProducteReportActionFinished = () => ({
    type: SET_PRODUCT_REPORT_ACTION_FINISH,
});

const setProductReport = data => ({
    type: SET_PRODUCT_REPORT,
    payload: { report: data },
});

export const setProductReportFilters = filters => ({
    type: SET_PRODUCT_REPORT_FILTERS,
    payload: { filters },
});

export const getProductReport = () => {
    return (dispatch, getState) => {
        let filters = { ...getState().productReport.filters };

        filters.userType = filters.user[0];
        filters.userNameOrId = filters.user[1];

        delete filters.user;

        if (!filters.currencyCode) {
            return;
        }

        dispatch(setProductReportActionBefore());

        return axios({
            url: ApiUrls.GET_PRODUCT_REPORT,
            method: Methods.GET,
            params: {
                ...filters
            },
        })
            .then(({ data: { value } }) => {

                dispatch(setProductReport(value));

                dispatch(setProducteReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setProducteReportActionFinished());
            });
    }
}
