import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {Form, Spin, Row, Col, Divider, Switch} from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { getProjectChatUI, saveProjectChatUI } from "store/actions/dashboard/projects/chatUI.action";

import projectChatUIType from "types/project/chatUI.type";

import { copyToClipboard } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import TextAreaInput from "components/common/textAreaInput";


/** Project Edit Page Integration Tab Chat subtab Component */
const ChatIntegrationComponent = ({
    getProjectChatUI,
    saveProjectChatUI,
    isSaving,
    isLoading,
    chatUI,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const isEnableChatScript = Form.useWatch("isEnableChatScript", formInstance);

    /** Get project chat ui */
    useEffect(() => {
        getProjectChatUI()
    }, []);

    useEffect(() => {
        formInstance.setFieldsValue({...chatUI})
    }, [chatUI]);

    /** Fires when form submitted
       * @function
       * @memberOf ChatIntegrationComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectChatUI({
                    isEnableChat: data.isEnableChatScript,
                    chatScriptData: data.script
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])


    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={
                        (changed, formValues) => {
                            setIsFormTouched(isFormChanged({ ...formValues }, { ...chatUI }));
                        }
                    }
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} xl={14}>
                            <h4 className="rt--form-section-title rt--title rt--mt-8 rt--mb-16 rt--font-bold rt--font-biger">{t('backoffice.projects.script')}</h4>
                            <div className="rt--flex rt--align-center rt--mb-16">
                                <Form.Item name="isEnableChatScript" noStyle label="" valuePropName="checked">
                                    <Switch/>
                                </Form.Item>

                                <span
                                    className="rt--ml-8 rt--font-normal">{t("backoffice.projects.enableScript")}</span>
                            </div>

                            <div className="rt--chat-integration-script">
                                <Form.Item
                                    name="script"
                                    label=""
                                    className={!isEnableChatScript ? "rt--form-item-disabled" : ""}
                                >
                                    <TextAreaInput
                                        disabled={!isEnableChatScript}
                                        placeholder={t('backoffice.projects.enterYourScriptHere')}
                                        maxLength={2000}
                                        rows={6}
                                    />
                                </Form.Item>

                                <i
                                    className='icon-copy rt--chat-integration-script-copy rt--font-bigest'
                                    onClick={() => copyToClipboard(formInstance.getFieldValue(["script"]))}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** ChatIntegrationComponent propTypes
    * PropTypes
*/
ChatIntegrationComponent.propTypes = {
    /** Redux action to save project chat ui */
    saveProjectChatUI: PropTypes.func,
    /** Redux state property, is true when chat UI is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when chat UI is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing project chat UI */
    chatUI: projectChatUIType,
    /** Redux action to get project chat ui */
    getProjectChatUI: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectChatUI: () => {
            dispatch(getProjectChatUI());
        },

        saveProjectChatUI: data => {
            dispatch(saveProjectChatUI(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        chatUI: state.projects.edit.chatUI,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatIntegrationComponent)
