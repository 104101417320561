//#region react
import React, {useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
//#endregion

//#region actions
import {
    getPermissionRequests,
    approvePermissionRequest,
    rejectPermissionRequest
} from "store/actions/dashboard/userManagement/requests/pending.action";
//#endregion

//#region hooks
import useFirstRender from 'hooks/useFirstRender';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import Confirmation from "components/common/confirmation";
import TabTableDashboardLayout from "components/layouts/tab/table";
import Notes from "components/common/notes";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { hasPermission } from 'utils/permissions';
import {binaryToFlags, isMobile} from "utils/common";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { PERMISSION_REQUEST_TYPE } from "constants/permissionRequest.constants";
import { PENDING_PERMISSION_REQUESTS } from "constants/pageName.constants";
import { getTableColumns, expandColumns } from "../columns";
//#endregion

//#region types
import permissionRequestType from "types/permissionRequest/request.type";
import notificationType from 'types/notification/notification.type';
import {ROW_CHOOSER_TYPES} from "components/common/table/constants";
import {USER_ROLE, USER_STATE} from "constants/user.constants";
import Dropdown from "components/common/dropdown";
import {Button, Col, Form, Row} from "antd";
import {isArray} from "utils/javaScriptTypes";
import Filters from "pages/userManagement/requests/pending/filters";
import ProcessResultModal from "pages/userManagement/requests/pending/processResultModal";
import TextAreaInput from "components/common/textAreaInput";
import Modal from "components/common/modal";
import ProcessModal from "pages/userManagement/requests/pending/processModal";
//#endregion

const EXPANDED_ROW_UNIQUE_KEY_PROP = "index";

const ACTIONS = {
    APPROVE: 1,
    REJECT: 2
}

const mapPropToEachRowData = (rowsData) => {
    return (
        rowsData.map((rowData, index) => ({
            change: rowData,
            index: index + 1
        }))
    );
}

const getExpandTableDetails = ({ record, expandTableColumns }) => {
    if (!record.changes) {
        return null;
    }

    const expandTableData = mapPropToEachRowData(record.changes);

    return {
        columns: expandTableColumns,
        data: expandTableData,
        uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
    }
}

/** Permission Requests Page Component */
const PermissionRequestsComponent = ({
    getPermissionRequests,
    requests,
    isLoading,
    isSaving,
    globalCompanyId,
    approvePermissionRequest,
    rejectPermissionRequest,
    notifications
}) => {
    const { t } = useTranslation();

    const { search } = useLocation();

    const [processedRequests, setProcessedRequests] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [checkedRows, setCheckedRows] = useState([]);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({
        pageName: PENDING_PERMISSION_REQUESTS
    });

    const _dropdownRef = useRef(null);

    const [formInstance] = Form.useForm();

    const objectId = (new URLSearchParams(search)).get("objectId");
    const objectType = (new URLSearchParams(search)).get("objectType");
    const actionTypesFromSearch = (new URLSearchParams(search)).get("actionTypes") ?? 0;
    const actionTypes = binaryToFlags(Object.values(PERMISSION_REQUEST_TYPE), Number(actionTypesFromSearch));

    useFirstRender({
        dependencies: [globalCompanyId, notifications.length],
        onFirstRenderCB: () => getPermissionRequests(objectId, actionTypes, objectType),
        afterFirstRenderCB: () => getPermissionRequests()
    })

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasPermissionRequestModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        expandTableColumns,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns,
            includedColumns,
            constructForInclude: true,
            additionalProps: {
                isHistory: false
            }
        });
    }, [includedColumns])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPermissionRequestModifyPermission) {
        tableRowActions.push(
            {
                title: t('backoffice.common.reject'),
                icon: "icon-close",
                onClick: record => {
                    setConfirmation({
                        ids: [record.id],
                        type: ACTIONS.REJECT
                    })
                },
            },
            {
                title: t('backoffice.common.approve'),
                icon: "icon-ok",
                onClick: record => {
                    setConfirmation({
                        ids: [record.id],
                        type: ACTIONS.APPROVE
                    })
                },
            },
        )
    }

    const handleConfirmation = () => {
        if (confirmation.type === ACTIONS.APPROVE) {
            approvePermissionRequest(confirmation.ids)
                .then((value) => {
                    if (value && value.processRequestIds.length > 1) {
                        setProcessedRequests({ processed: value.processRequestIds?.length ?? 0, completed: value.completedRequestIds?.length ?? 0 })
                    }

                    setCheckedRows([]);
                })

            setConfirmation(null);
        } else if (confirmation.type === ACTIONS.REJECT) {
            formInstance.validateFields().then(({note}) => {
                rejectPermissionRequest(confirmation.ids, note)
                    .then((value) => {
                        if (value && value.processRequestIds.length > 1) {
                            setProcessedRequests({ processed: value.processRequestIds?.length ?? 0, completed: value.completedRequestIds?.length ?? 0 })
                        }

                        setCheckedRows([]);
                    })

                formInstance.resetFields();
                setConfirmation(null);
            })
        }
    }

    const handleTableCheckboxChange = value => {
        setCheckedRows(value)
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    const suspendUnsuspendButtonsContent = (
        <div className={"rt--flex rt--align-center" + (isMobile() ? " rt--justify-between rt--flex-equal" : "")}>
            <span
                className={"rt--text-secondary " + (isMobile() ? " rt--font-normal rt--font-bold" : " rt--pl-16 rt--pr-16 rt--font-big")}
            >
                {
                    `${checkedRows.length} ${t("backoffice.common.selected")}`
                }
            </span>
            {
                isMobile()
                    ? (
                        <Dropdown
                            ref={_dropdownRef}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            title={t("backoffice.common.actions")}
                            menu={{
                                items: [
                                    {
                                        key: ACTIONS.APPROVE,
                                        label: (
                                            <span className='rt--title rt--font-normal rt--font-regular'>
                                                {t(`backoffice.common.approve`)}
                                            </span>
                                        ),
                                        onClick: () => {
                                            _dropdownRef.current?.close();
                                            setConfirmation({type: ACTIONS.APPROVE, ids: checkedRows});
                                        },
                                    },
                                    {
                                        key: ACTIONS.REJECT,
                                        label: (
                                            <span className='rt--title rt--font-normal rt--font-regular'>
                                                {t(`backoffice.common.reject`)}
                                            </span>
                                        ),
                                        onClick: () => {
                                            _dropdownRef.current?.close();
                                            setConfirmation({type: ACTIONS.REJECT, ids: checkedRows});
                                        },
                                    }
                                ]
                            }}
                            button={{
                                className: "rt--button-dropdown",
                                onClick: () => _dropdownRef.current && _dropdownRef.current.open(),
                            }}
                        >
                            {
                                t("backoffice.common.actions")
                            }
                        </Dropdown>
                    ) : (
                        <>
                            <Button
                                type="primary"
                                className={"rt--button rt--mr-16" + (isMobile() ? "" : " rt--button-main rt--button-main-without-icon")}
                                onClick={() => setConfirmation({ type: ACTIONS.APPROVE, ids: checkedRows })}
                            >
                                {t("backoffice.common.approve")}
                            </Button>
                            <Button
                                type="secondary"
                                className={"rt--button" + (isMobile() ? "" : " rt--button-secondary rt--button-main-without-icon")}
                                onClick={() => setConfirmation({ type: ACTIONS.REJECT, ids: checkedRows })}
                            >
                                {t("backoffice.common.reject")}
                            </Button>
                        </>

                    )
            }
        </div>
    )

    if (checkedRows.length > 0) {
        headerPartsData.leftContent = suspendUnsuspendButtonsContent
    }
    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={requests}
                total={requests?.length}
                disableFullView={true}
                noPagination={true}
                actions={tableRowActions}
                expandAllAvailable
                expandable={{
                    title: t('backoffice.users.changes'),
                    disabled: requests?.filter(requestData => !requestData.changes.length).map(request => request.id),
                    details: record => (
                        getExpandTableDetails({
                            record,
                            expandTableColumns,
                        })
                    ),
                    type: EXPAND_TABLE_TYPES.TABLE
                }}
                chooser={{
                    type: ROW_CHOOSER_TYPES.CHECKBOX,
                    value: checkedRows,
                    valueIndex: "id",
                    onChange: handleTableCheckboxChange,
                }}
            />
            {confirmation && (
                <ProcessModal
                    confirmation={confirmation}
                    formInstance={formInstance}
                    onOk={handleConfirmation}
                    onCancel={() => setConfirmation(null)}
                />
            )}

            <ProcessResultModal
                open={!!processedRequests}
                processed={processedRequests?.processed}
                completed={processedRequests?.completed}
                onClose={() => setProcessedRequests(null)}
            />
        </TabTableDashboardLayout>
    )
};

/** PermissionRequestsComponent propTypes
 * PropTypes
*/
PermissionRequestsComponent.propTypes = {
    /** Redux action to get permission requests */
    getPermissionRequests: PropTypes.func,
    /** Redux state property, represents the array of requests  */
    requests: PropTypes.arrayOf(permissionRequestType),
    /** Redux state property, is true when loading requests */
    isLoading: PropTypes.bool,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux action to approve permission request */
    approvePermissionRequest: PropTypes.func,
    /** Redux action to reject permission request */
    rejectPermissionRequest: PropTypes.func,
    /** Redux state, represents the array of notifications  */
    notifications: PropTypes.arrayOf(notificationType),
};

const mapDispatchToProps = dispatch => ({
    getPermissionRequests: (userId, actionType, objectType) => {
        return dispatch(getPermissionRequests(userId, actionType, objectType));
    },

    approvePermissionRequest: id => {
        return dispatch(approvePermissionRequest(id));
    },

    rejectPermissionRequest: (id, note) => {
        return dispatch(rejectPermissionRequest(id, note));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.permissionRequests.isLoading,
        isSaving: state.permissionRequests.isSaving,
        requests: state.permissionRequests.pending.requests,
        globalCompanyId: state.common.globalCompanyId,
        notifications: state.notifications.notifications,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionRequestsComponent);
