import React from 'react';
import i18n from "translations/config";

import LockInfo from 'components/common/lockInfo';
import Status from 'components/common/status';

import { isRedirectToSubAgentsEnabledFn } from "utils/agents";

import { WALLET_TYPE } from 'constants/wallet.constants';
import { USER_STATE } from 'constants/user.constants';
import { STATUS_TOOLTIP_PLACEMENT, STATUS_TYPES } from 'constants/status.constants';
import { TRANSFER_OPERATION_GROUP } from 'components/common/transfer';

export const getTableColumns = (additionalProps) => {
    const {
        userInfo,
        onUserNameClick,
        hasRetail,
        hasLiteMode,
        formatAmount,
        setTransferPopup,
        canTransferToAgent
    } = additionalProps;

    return [
        {
            title: "backoffice.agents.id",
            dataIndex: "longId",
            copy: true,
            sorter: true,
        },
        {
            title: "backoffice.agents.userName",
            dataIndex: "userName",
            sorter: true,
            mobileLevel: 1,
            render: (value, record) => {
                const redirectionEnabled = isRedirectToSubAgentsEnabledFn(record);

                return (
                    <div className="rt--flex rt--align-center rt--table-col-with-icon">
                        <span
                            onClick={redirectionEnabled ? () => onUserNameClick(record) : undefined}
                            className={`rt--title rt--font-normal rt--font-regular rt--pr-4 ${redirectionEnabled ? "rt--table-col-link" : ""}`}
                        >
                            {
                                redirectionEnabled
                                    ? <b>{value}</b>
                                    : value
                            }
                        </span>
                    </div>
                )
            },
        },
        ...(
            hasLiteMode
                ? (
                    [{
                        title: "backoffice.players.operations",
                        dataIndex: "operations",
                        alwaysVisible: true,
                        disableFillViewTooltip: true,
                        mobileLevel: 2,
                        render: (_, record) => {
                            if (!canTransferToAgent({ agentData: record, userInfo })) {
                                return null;
                            }

                            return (
                                <div className="rt--table-operations">
                                    <button onClick={() => setTransferPopup(record, {
                                        group: TRANSFER_OPERATION_GROUP.DEPOSIT
                                    })}>
                                        <i className="icon-deposit"/>
                                    </button>
                                    <button onClick={() => setTransferPopup(record, {
                                        group: TRANSFER_OPERATION_GROUP.WITHDRAWAL
                                    })}>
                                        <i className="icon-withdrawal"/>
                                    </button>
                                </div>
                            );
                        }
                    }]
                )
                : []
        ),
        {
            title: "backoffice.agents.agentLevel",
            dataIndex: "hierarchy",
            render: value => {
                return (
                    <div className="rt--flex rt--align-center">
                        <i className="icon-level rt--font-bigest" />
                        <small className="rt--pl-4 rt--font-normal rt--font-regular">
                            {(value.match(/\//g) || []).length}
                        </small>
                    </div>
                )
            }
        },
        {
            title: "backoffice.agents.belongsTo",
            dataIndex: "parentName",
            render: value => value || "-"
        },
        {
            title: "backoffice.agents.country",
            dataIndex: "country",
            mobileLevel: 4,
            render: value => value ? i18n.t(`backoffice.countries.${value}`) : "-",
        },
        {
            title: "backoffice.agents.status",
            dataIndex: "state",
            sorter: true,
            mobileLevel: 6,
            render: (value, record) => (
                <Status
                    status={value}
                    type={STATUS_TYPES.USER}
                    showIcon={[USER_STATE.LOCKED2FA, USER_STATE.LOCKEDLOGIN].includes(value)}
                    tooltipOptions={{
                        placement: STATUS_TOOLTIP_PLACEMENT.ICON,
                        title: (
                            <LockInfo
                                is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                                lastLogin={record?.lastLogin}
                                lockTime={record?.loginAttemptDetails?.lockTime}
                            />
                        )
                    }}
                />
            ),
        },
        ...[
            hasLiteMode
                ? {
                    title: "backoffice.common.balance",
                    dataIndex: "wallets",
                    isLTR: true,
                    disabledForFranchisingMode: true,
                    withCurrencySymbol: true,
                    render: (value) => {
                        const wallet = value[0]?.[WALLET_TYPE.BALANCE];

                        if (!wallet) {
                            return 0;
                        }

                        return formatAmount(wallet.amount ?? 0, wallet.currencyCode);
                    }
                }
                : {
                    title: "backoffice.agents.currencies",
                    dataIndex: "currencyCodes",
                    multi: true,
                    mobileLevel: 5,
                }
        ],
        {
            title: "backoffice.agents.currencies",
            dataIndex: "currencyCodes",
            multi: true,
            mobileLevel: 5,
        },
        {
            title: "backoffice.agents.subAgents",
            dataIndex: "subAgentsCount",
            disabledForFranchisingMode: true,
            render: value => value || 0,
        },
        {
            title: "backoffice.agents.players",
            dataIndex: "playersCount",
            disabledForFranchisingMode: true,
            render: value => value || 0,
        },
        hasRetail && {
            title: "backoffice.agents.betshops",
            dataIndex: "betShopsCount",
            render: value => value || 0
        },
        {
            title: "backoffice.agents.registrationDate",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.agents.lastLoginDate",
            dataIndex: "lastLogin",
            isDateTime: true,
        },
        {
            title: "backoffice.agents.label",
            dataIndex: "label",
            disabledForFranchisingMode: true,
        }
    ]
}

export const getExpandTableColumns = (additionalProps) => {
    const {
        franchisingMode,
        formatAmount
    } = additionalProps;

    return [
        {
            title: "backoffice.agents.currency",
            dataIndex: "currencyCode",
        },
        {
            title: "backoffice.common.balance",
            dataIndex: "balance",
            isLTR: true,
            disabledForFranchisingMode: true,
            render: (_, record) => formatAmount(record[WALLET_TYPE.BALANCE]?.amount ?? 0, record.currencyCode)
        },
        {
            title: "backoffice.common.credit",
            dataIndex: "credit",
            render: (_, record) => formatAmount((record[WALLET_TYPE.CREDIT]?.amount ?? 0) + (franchisingMode ? (record[WALLET_TYPE.CREDIT_LINE]?.amount ?? 0) : 0), record.currencyCode)
        },
        {
            title: "backoffice.common.creditLine",
            dataIndex: "creditLine",
            disabledForFranchisingMode: true,
            render: (_, record) => formatAmount(record[WALLET_TYPE.CREDIT_LINE]?.amount ?? 0, record.currencyCode)
        },
    ]
}
