import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Checkbox } from 'antd';

import Select from 'components/common/select';
import AutoComplete from "components/common/autoComplete";
import Input from 'components/common/input';
import DateRangePicker from 'components/common/dateRangePicker';

import FiltersWrapper from "components/common/filters";

import useAutosuggestion from 'hooks/useAutosuggestion';

import { getAgents, setAgentsFilters } from "store/actions/dashboard/agentSystem/agents/agents.action";
import { FILTER_USER_STATE, FILTER_COUNTRY } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { USER_ROLE } from 'constants/user.constants';

import { getPossibleParentsName } from "utils/common";
import { getCurrencyFieldOptions } from 'utils/currency';
import { getUser } from 'utils/auth';

import countries from "systemData/countries";
import useProjectType from "hooks/useProjectType";


const FILTERS_FIELD_NAMES = {
    CURRENCIES: "currencies",
    STATE: "state",
    EMAIL: "email",
    PARENT_USER_NAME_OR_ID: "parentUserNameOrId",
    COUNTRIES: "countries",
    DATE: "date",
    LAST_LOGIN_DATE: "lastLogin",
    SUSPENDED: "suspended"
}

/** Agents Page Filters Component */
const Filters = ({
    hasOneCurrency,
    userCurrencies,
    setAgentsFilters,
    getAgents,
    filters,
    globalProjectId,
}) => {
    const { t } = useTranslation();
    const { hasLiteMode } = useProjectType();

    const { role } = getUser();

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: { excludeAgent: role === USER_ROLE.ADMIN },
        autoGet: false
    })

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    return (
        <FiltersWrapper
            loadFn={getAgents}
            updateProps={[globalProjectId]}
            setFiltersFn={setAgentsFilters}
            filters={filters}
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: false },
                { name: FILTERS_FIELD_NAMES.LAST_LOGIN_DATE, time: false }
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getAgentNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.agents.registrationDate')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.agents.lastLoginDate')}
                        name={FILTERS_FIELD_NAMES.LAST_LOGIN_DATE}
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>
                {
                    (!hasLiteMode) || (hasLiteMode && !hasOneCurrency)
                        ? <Col span={24}>
                            <Form.Item
                                label={t('backoffice.agents.currency')}
                                name={FILTERS_FIELD_NAMES.CURRENCIES}
                            >
                                <Select
                                    options={currencyFieldOptions}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.agents.currency')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    isMultiple={true}
                                />
                            </Form.Item>
                        </Col>
                        : null
                }

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name={FILTERS_FIELD_NAMES.STATE}
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.common.inactive') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_USER_STATE.DELETED, text: t('backoffice.common.deleted') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.state')}
                        name={FILTERS_FIELD_NAMES.SUSPENDED}
                    >
                        <Select
                            options={[
                                { value: null, text: t('backoffice.common.all') },
                                { value: true, text: t('backoffice.agents.suspend') },
                                { value: false, text: t('backoffice.agents.unsuspend') },
                            ]}
                            placeholder={`${t('backoffice.common.select')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.agents.email')}
                        className='rt--general-form-item'
                        name={FILTERS_FIELD_NAMES.EMAIL}
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.email')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.email')}`}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.agents.belongsTo')}
                        name={FILTERS_FIELD_NAMES.PARENT_USER_NAME_OR_ID}
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.agents.belongsTo')}`}
                            items={getPossibleParentsName({
                                globalProjectId,
                                agentNames,
                            })}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.agents.country')}
                        name={FILTERS_FIELD_NAMES.COUNTRIES}
                    >
                        <Select
                            options={
                                [
                                    { value: FILTER_COUNTRY.NONE, text: t('backoffice.common.none') },
                                    ...(
                                        countries.map(c => (
                                            { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                        ))
                                    )
                                ]

                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.agents.country')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            isMultiple={true}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                        <Form.Item
                            className='rt--form-item-inline rt--form-item-without-margin'
                            name="sendDeleted"
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.agents.includeDeletedUsers')}</span>
                    </div>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get agents */
    getAgents: PropTypes.func,
    /** Redux action to set agents filters */
    setAgentsFilters: PropTypes.func,
    /** Redux state property, agents filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAgents: () => {
            dispatch(getAgents())
        },
        setAgentsFilters: filters => {
            dispatch(setAgentsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.agents.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
