import i18n from 'i18next';

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";
import { isFranchisingMode } from './common';

export const makeTransactionText = (transactionType, hasLiteMode) => {
    // WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
    // WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
    // WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE,
    // WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,

    switch (transactionType) {
        case WALLET_OPERATION_TYPE.BET:
            return i18n.t("backoffice.wallet.bet");
        case WALLET_OPERATION_TYPE.CANCEL_BET:
            return i18n.t("backoffice.wallet.cancelBet");
        case WALLET_OPERATION_TYPE.PAIDOUT:
            return i18n.t("backoffice.wallet.payout");
        case WALLET_OPERATION_TYPE.WON:
            return i18n.t("backoffice.wallet.won");
        case WALLET_OPERATION_TYPE.ROLLBACK:
            return i18n.t("backoffice.wallet.rollback");
        case WALLET_OPERATION_TYPE.REJECT_BET:
            return i18n.t("backoffice.wallet.rejected");
        case WALLET_OPERATION_TYPE.RECALCULATE:
            return i18n.t("backoffice.wallet.recalculate");
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE:
            if (hasLiteMode) {
                return i18n.t("backoffice.wallet.depositToAgent");
            }

            return i18n.t("backoffice.wallet.depositFromBalance");
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT:
            return isFranchisingMode() ? i18n.t("backoffice.wallet.depositPayDebt") : i18n.t("backoffice.wallet.depositFromBalancePayDebt");
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT:
            return i18n.t("backoffice.wallet.depositFromCredit");
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT:
            return i18n.t("backoffice.wallet.depositFromCreditPayDebt");
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE:
            if (hasLiteMode) {
                return i18n.t("backoffice.wallet.withdrawalFromAgent");
            }

            return i18n.t("backoffice.wallet.withdrawalFromBalance");
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT:
            return isFranchisingMode() ? i18n.t("backoffice.wallet.withdrawalPayDebt") : i18n.t("backoffice.wallet.withdrawalFromBalancePayDebt");
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT:
            return i18n.t("backoffice.wallet.withdrawalFromCredit");
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT:
            return i18n.t("backoffice.wallet.withdrawalFromCreditPayDebt");

        case WALLET_OPERATION_TYPE.AGENT_GIVE_CREDIT:
            return i18n.t("backoffice.wallet.giveCredit");

        case WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH:
            return isFranchisingMode() ? i18n.t("backoffice.wallet.commissionPayout") : i18n.t("backoffice.wallet.debtPayoutByCash");

        case WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_NON_CASH:
            return i18n.t("backoffice.wallet.debtPayoutNonCash");

        case WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_FROM_BALANCE:
            return i18n.t("backoffice.wallet.debtPayoutFromBalance");

        case WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE:
            if (hasLiteMode) {
                return i18n.t("backoffice.wallet.depositToPlayer");
            }

            return i18n.t("backoffice.wallet.playerDepositFromBalance");
        case WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_WITH_DEBT:
            return i18n.t("backoffice.wallet.playerDepositWithDebt");
        case WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE:
            if (hasLiteMode) {
                return i18n.t("backoffice.wallet.withdrawalFromPlayer");
            }

            return i18n.t("backoffice.wallet.playerWithdrawalToBalance");
        case WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT:
            return i18n.t("backoffice.wallet.playerWithdrawalToDebt");

        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_TO_CREDIT_LINE:
            return i18n.t("backoffice.wallet.depositToCreditLine");
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAW_FROM_CREDIT_LINE:
            return i18n.t("backoffice.wallet.withdrawFromCreditLine");

        case WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT:
            return i18n.t("backoffice.wallet.betshopDeposit");
        case WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL:
            return i18n.t("backoffice.wallet.betshopWithdrawal");

        case WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT:
            return i18n.t("backoffice.wallet.projectBalanceDeposit");
        case WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT:
            return i18n.t("backoffice.wallet.projectBalanceWithdraw");

        case WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH:
            return isFranchisingMode() ? i18n.t("backoffice.wallet.commissionPayout") : i18n.t("backoffice.wallet.adminDebtPayoutInCash");

        case WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT:
            return i18n.t("backoffice.wallet.adminDebtPayoutNonCash");

        case WALLET_OPERATION_TYPE.SUPER_AGENT_DEPOSIT_BETSHOP:
            return i18n.t("backoffice.wallet.adminBetshopDeposit");
        case WALLET_OPERATION_TYPE.SUPER_AGENT_WITHDRAWAL_BETSHOP:
            return i18n.t("backoffice.wallet.adminBetshopWithdrawal");

        case WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BETSHOP:
            return i18n.t("backoffice.wallet.depositToPlayer");
        case WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP:
            return i18n.t("backoffice.wallet.withdrawFromPlayer");

        case WALLET_OPERATION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER:
            return i18n.t("backoffice.wallet.depositToCashier");
        case WALLET_OPERATION_TYPE.BETSHOP_MANAGER_WITHDRAWAL_CASHIER:
            return i18n.t("backoffice.wallet.withdrawFromCashier");

        case WALLET_OPERATION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER:
            return i18n.t("backoffice.wallet.shiftHandover");

        case WALLET_OPERATION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT:
            return i18n.t("backoffice.wallet.betshopLimitManualAdjustment");
        case WALLET_OPERATION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT:
            return i18n.t("backoffice.wallet.betshopLimitAutoAdjustment");

        case WALLET_OPERATION_TYPE.AGENT_COMISSION_CALCULATION:
            return i18n.t("backoffice.wallet.commissionCalculation");
        case WALLET_OPERATION_TYPE.AGENT_COMISSION_RECALCULATION:
            return i18n.t("backoffice.wallet.commissionRecalculation");

        case WALLET_OPERATION_TYPE.ISSUE_VOUCHER:
            return i18n.t("backoffice.wallet.issueVoucher");
        case WALLET_OPERATION_TYPE.PAYOUT_VOUCHER:
            return i18n.t("backoffice.wallet.payoutVoucher");
        case WALLET_OPERATION_TYPE.TERMINAL_CASH_REMOVAL:
            return i18n.t("backoffice.wallet.cashRemoval");
        default:
            return ""
    }
}

export const makeTransactionTextShort = transactionType => {
    switch (transactionType) {

        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE:
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT:
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT:
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT:
        case WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE:
        case WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_WITH_DEBT:
        case WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT:
        case WALLET_OPERATION_TYPE.SUPER_AGENT_DEPOSIT_BETSHOP:
        case WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BETSHOP:
        case WALLET_OPERATION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER:
        case WALLET_OPERATION_TYPE.AGENT_DEPOSIT_TO_CREDIT_LINE:
            return i18n.t("backoffice.wallet.deposit");
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE:
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT:
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT:
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT:
        case WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE:
        case WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT:
        case WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL:
        case WALLET_OPERATION_TYPE.SUPER_AGENT_WITHDRAWAL_BETSHOP:
        case WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP:
        case WALLET_OPERATION_TYPE.BETSHOP_MANAGER_WITHDRAWAL_CASHIER:
        case WALLET_OPERATION_TYPE.AGENT_WITHDRAW_FROM_CREDIT_LINE:
            return i18n.t("backoffice.wallet.withdrawal");
        case WALLET_OPERATION_TYPE.AGENT_GIVE_CREDIT:
            return i18n.t("backoffice.wallet.giveCredit");
        case WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH:
        case WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_NON_CASH:
        case WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_FROM_BALANCE:
        case WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH:
        case WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT:
            return i18n.t("backoffice.wallet.payCommission");
        case WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT:
        case WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT:
            return i18n.t("backoffice.wallet.adjustBalance");
        case WALLET_OPERATION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT:
        case WALLET_OPERATION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT:
            return i18n.t("backoffice.wallet.limitAdjustment");
        case WALLET_OPERATION_TYPE.ROLLBACK:
            return i18n.t("backoffice.wallet.rollback");
        case WALLET_OPERATION_TYPE.BET:
            return i18n.t("backoffice.wallet.bet");
        case WALLET_OPERATION_TYPE.CANCEL_BET:
            return i18n.t("backoffice.wallet.cancelBet");
        case WALLET_OPERATION_TYPE.PAIDOUT:
            return i18n.t("backoffice.wallet.payout");
        case WALLET_OPERATION_TYPE.WON:
            return i18n.t("backoffice.wallet.won");
        case WALLET_OPERATION_TYPE.REJECT_BET:
            return i18n.t("backoffice.wallet.rejectBet");
        case WALLET_OPERATION_TYPE.RECALCULATE:
            return i18n.t("backoffice.wallet.recalculate");
        case WALLET_OPERATION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER:
            return i18n.t("backoffice.wallet.shiftHandover");
        case WALLET_OPERATION_TYPE.AGENT_COMISSION_CALCULATION:
            return i18n.t("backoffice.wallet.commissionCalculation");
        case WALLET_OPERATION_TYPE.ISSUE_VOUCHER:
            return i18n.t("backoffice.wallet.issueVoucher");
        case WALLET_OPERATION_TYPE.PAYOUT_VOUCHER:
            return i18n.t("backoffice.wallet.payoutVoucher");
        default:
            return ""
    }
}
