import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';
import { getAgentWallets } from "store/actions/dashboard/wallet/agentWallet.action";

import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { WALLET_OWNER_TYPE } from 'constants/wallet.constants';

import {
    SET_PROJECT_CURRENCIES, SET_PROJECT_SUBPROJECTS
} from "../../../actionTypes";
import {getCurrencies} from "store/actions/dashboard/profile/currencies.action";

const setProjectCurrencies = currencies => ({
    type: SET_PROJECT_CURRENCIES,
    payload: { currencies }
});

const setProjectSubProjects = subProjects => ({
    type: SET_PROJECT_SUBPROJECTS,
    payload: { subProjects }
})

export const getProjectCurrencies = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_CURRENCIES,
            method: Methods.GET
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setProjectCurrencies(currencies))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const getProjectSubProjects = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_SUB_PROJECTS,
            method: Methods.GET
        })
            .then(({data : {value: subProjects}}) => {
                dispatch(setProjectSubProjects(subProjects));
                dispatch(setProjectsActionFinished());

                return subProjects;
            })
            .catch((ex) => {
                dispatch(setProjectsActionFinished());
            })
    }
}

export const addProjectCurrency = (currency, id, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PROJECT_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectCurrencies());

                if(hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW })){
                    dispatch(getAgentWallets(id, WALLET_OWNER_TYPE.SUPER_AGENT))
                }

                onSuccess && onSuccess();
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const saveProjectCurrency = (currency, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectCurrencies());
                dispatch(getCurrencies());
                onSuccess && onSuccess();
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deleteProjectCurrency = code => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_CURRENCY,
            method: Methods.DELETE,
            data: {
                code: code
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectCurrencies());
            }
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

