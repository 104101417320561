import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA,
    RETAIL_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA, TOTALS_TYPES
} from "pages/dashboard/constants";
import {isMobile} from "utils/common";
import {Carousel, Col, Row, Tabs as AntTabs} from "antd";
import TotalsCard from "pages/dashboard/components/totalsCard";
import RetailLineChart from "pages/dashboard/components/retailLineChart";

const RetailStatisticsTab = ({ filters, updateLastUpdatedDate, hasOneCurrency}) => {
    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
    const { t } = useTranslation();

    const lineChartItems = useMemo(() => (
        RETAIL_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA.map(data => ({
            key: data.id,
            label: t(data.title),
            children: (
                <RetailLineChart
                    key={data.id}
                    title={t(data.title)}
                    dataType={data.dataType}
                    playerLineColor={data.playerLineColor}
                    betshopLineColor={data.betshopLineColor}
                    disableLegend={data.disableLegend}
                    filters={filters}
                    updateLastUpdatedDate={updateLastUpdatedDate}
                    calcTotalAmount={data.calcTotalAmount}
                    hasOneCurrency={hasOneCurrency}
                />
            )
        }))
    ), [filters, t, updateLastUpdatedDate]);

    const handleCarouselChange = (prevIndex, nextIndex) => {
        setCarouselActiveIndex(nextIndex);
    }

    return (
        <div className="rt--dashboard-statistics-retail">
            {!isMobile()
                ? (
                    <Row gutter={16} className="rt--mb-24">
                        <Col span={6}>
                            <TotalsCard
                                type={TOTALS_TYPES.BET}
                                color="#A162F7"
                                filters={filters}
                                title={t("backoffice.dashboard.betAmount")}
                                subtitle={t("backoffice.dashboard.betCount")}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Col>
                        <Col span={6}>
                            <TotalsCard
                                type={TOTALS_TYPES.PENDING}
                                color="#FFBE0C"
                                filters={filters}
                                title={t("backoffice.dashboard.pendingBetAmount")}
                                subtitle={t("backoffice.dashboard.pendingBetCount")}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Col>
                        <Col span={6}>
                            <TotalsCard
                                type={TOTALS_TYPES.WIN}
                                color="#00BA88"
                                filters={filters}
                                title={t("backoffice.dashboard.winAmount")}
                                subtitle={t("backoffice.dashboard.winCount")}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Col>
                        <Col span={6}>
                            <TotalsCard
                                type={TOTALS_TYPES.PAYOUT}
                                color="#3A86FE"
                                filters={filters}
                                title={t("backoffice.dashboard.payoutAmount")}
                                subtitle={t("backoffice.dashboard.payoutCount")}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Col>
                    </Row>
                )
                : (
                    <div className='rt--dashboard-carousel rt--pb-26 rt--mb-24'>
                        <Carousel
                            infinite={false}
                            dots
                            beforeChange={handleCarouselChange}
                        >
                            <TotalsCard
                                type={TOTALS_TYPES.BET}
                                color="#A162F7"
                                filters={filters}
                                title={t("backoffice.dashboard.betAmount")}
                                subtitle={t("backoffice.dashboard.betCount")}
                                isActive={carouselActiveIndex === 0}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />


                            <TotalsCard
                                type={TOTALS_TYPES.PENDING}
                                color="#FFBE0C"
                                filters={filters}
                                title={t("backoffice.dashboard.pendingBetAmount")}
                                subtitle={t("backoffice.dashboard.pendingBetCount")}
                                isActive={carouselActiveIndex === 1}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />


                            <TotalsCard
                                type={TOTALS_TYPES.WIN}
                                color="#00BA88"
                                filters={filters}
                                title={t("backoffice.dashboard.winAmount")}
                                subtitle={t("backoffice.dashboard.winCount")}
                                isActive={carouselActiveIndex === 2}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />


                            <TotalsCard
                                type={TOTALS_TYPES.PAYOUT}
                                color="#3A86FE"
                                filters={filters}
                                title={t("backoffice.dashboard.payoutAmount")}
                                subtitle={t("backoffice.dashboard.payoutCount")}
                                isActive={carouselActiveIndex === 3}
                                updateLastUpdatedDate={updateLastUpdatedDate}
                            />
                        </Carousel>
                    </div>
                )
            }

            <div className="rt--chart-section rt--dashboard-statistics-retail-tabs">
                <AntTabs
                    animated={false}
                    destroyInactiveTabPane={true}
                    className='rt--tabs rt--chart-tab'
                    items={lineChartItems}
                />
            </div>
        </div>
    );
};

export default RetailStatisticsTab;
