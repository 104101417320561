import React, { useState } from 'react';

import {Spin, Checkbox, Radio} from "antd";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {DndContext} from "@dnd-kit/core";

import Actions from './actions';
import EditableCellModal from './editableCellModal';
import TableColumnContent from './tableColumnContent';
import DefaultEmptyView from '../defaultEmptyView';
import DitalseModal from './detailsModal';
import ExpandViewModal from './expandViewModal';
import DragHandle from "components/common/table/dragHandle";
import DraggableRow from "components/common/table/draggableRow";

import useInfiniteScroll from '../hooks/infiniteScroll';

import { classNames, isFunction } from 'utils/common';

import {
    filterAndSortColumnsByMobileLevel,
    mobileTableColumnsCreator
} from '../helpers';

import {
    ACTIONS_MAX_NUMBER_WITH_DETAILS,
    ACTIONS_MAX_NUMBER_WITHOUT_DETAILS,
    DEFAULT_UNIQUE_PROP,
    EXPANDABLE_TYPES,
    ROW_CHOOSER_TYPES
} from '../constants';

import { TablePropTypes } from '../propTypes';
import TitleWithCurrencySymbol from "components/common/table/columns/columnTitleModes/titleWithCurrencySymbol";
import {TABLE_PAGINATION_MODE} from "components/common/table/desktop/constants";

const TableMobile = ({
    mobileHeaderContent, // for each item
    actions = [],
    chooser,
    expandable,
    uniqueKey = DEFAULT_UNIQUE_PROP,
    disableFullView,
    data = [],
    columns = [],
    clickable,
    smallInMobile,
    switcher,
    mobileLayoutVertical,
    mobileListView,
    loadFn,
    updateProps = [],
    loading = false,
    noPagination,
    paginationMode = TABLE_PAGINATION_MODE.DEFAULT,
    hasNext,
    renderEmpty,
    fullViewClassName,
    formatNumber,
    sortable,
    onRowSort,
    sortableData = [],
}) => {
    const [editingFieldData, setEditingFieldData] = useState(null);
    const [expandRowData, setExpandRowData] = useState(null);
    const [detailsRowData, setDetailsRowData] = useState(null);

    const elementRef = useInfiniteScroll({
        dataLoadFn: loadFn,
        preventFunctionality: paginationMode === TABLE_PAGINATION_MODE.PREV_NEXT ? !hasNext : noPagination,
        dependencies: updateProps
    });

    //#region --------------------------------------------- HANDLERS ----------------------------------------------//

    const handleClick = (rowData, e) => {
        if (e.target && e.target.closest(".rt--table-item")) {
            if (clickable && (!clickable.disabled || !clickable.disabled(rowData))) {
                e.preventDefault();
                e.stopPropagation();
                clickable.onClick(rowData)
            }
        }
    }

    const handleEditIconClick = (editCellData) => {
        setEditingFieldData(editCellData);
    }

    const handleRadioChooserChange = e => {
        if (!isFunction(chooser.onChange)) {
            return;
        }

        chooser.onChange(e.target.value);
    }

    const handleCheckboxChooserChange = value => {
        if (!isFunction(chooser.onChange)) {
            return;
        }

        chooser.onChange(value);
    }

    const handleDragEnd = ({active, over}) => {
        if (active.id !== over.id) {
            onRowSort?.(active.id, over.id);
        }
    }

    const getIsDraggable = (rowData) => {
        return sortable && sortableData.findIndex(item => item[uniqueKey] === rowData[uniqueKey])!== -1
    }

    const getTitle = (columnData) => {
        let title = '';

        if (columnData.mobileTitle) {
            title = columnData.mobileTitle;
        } else if (isFunction(columnData.title)) {
            title = columnData.title();
        } else {
            title = columnData.title;
        }

        if (columnData.withCurrencySymbol) {
            title = <TitleWithCurrencySymbol title={title} symbol={columnData.withCurrencySymbol} />;
        }

        return title;
    }

    //#endregion

    //#region ----------------------------------------- Construct Columns -----------------------------------------//

    const mobileTableColumns = mobileTableColumnsCreator({
        mainColumns: columns,
        additionalColumnsObj: {
            switcher,
        },
        otherFeatures: {
            formatNumber,
            sortableData: {
                uniqueKey,
                sortable,
                sortableData,
            }
        }
    })

    const filteredAndSortedColumnsByMobileLevel = filterAndSortColumnsByMobileLevel(mobileTableColumns);

    //#endregion

    //#region --------------------------------------- Construct Actions Data --------------------------------------//

    const actionsMaxNumberInRow = (
        expandable || !disableFullView
            ? ACTIONS_MAX_NUMBER_WITH_DETAILS
            : ACTIONS_MAX_NUMBER_WITHOUT_DETAILS
    )

    //#endregion

    if (!loading && data.length === 0) {
        return (
            isFunction(renderEmpty)
                ? renderEmpty()
                : <DefaultEmptyView />
        )
    }

    const renderChooser = rowData => {
        // In chooserData disabled is a function
        const { type, valueIndex, disabled, renderIcon } = chooser;

        if (type === ROW_CHOOSER_TYPES.RADIO) {
            return (
                <div>
                    <Radio
                        value={rowData[valueIndex]}
                        disabled={disabled?.(rowData)}
                    />
                </div>
            )
        } else if (type === ROW_CHOOSER_TYPES.CHECKBOX) {
            return (
                <div>
                    <Checkbox
                        value={rowData[valueIndex]}
                        disabled={disabled?.(rowData)}
                    />
                </div>
            )
        } else if (type === ROW_CHOOSER_TYPES.ICON) {
            return (
                <div className='rt--pr-8'>
                    { renderIcon(rowData) }
                </div>
            )
        }

        return null
    }

    const renderTableContent = () => (
        <>
            <Spin spinning={loading}>
                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
                    <SortableContext items={data.map(rowData => rowData[uniqueKey])} strategy={verticalListSortingStrategy}>
                        <div className="rt--table-wrapper" ref={elementRef}>
                            <div className="rt--table">
                                {
                                    data.map(rowData => (
                                        <DraggableRow
                                            id={rowData[uniqueKey]}
                                            key={rowData[uniqueKey]}
                                            data-row-key={rowData[uniqueKey]}
                                            onClick={(e) => handleClick(rowData, e)}
                                            className={classNames(
                                                "rt--table-item rt--pb-4 rt--mb-12",
                                                smallInMobile && "rt--table-item-small",
                                                (smallInMobile && mobileLayoutVertical) && "rt--table-item-small-vertical"
                                            )}
                                            sortableData={sortableData}
                                            uniqueKey={uniqueKey}
                                        >
                                            {
                                                isFunction(mobileHeaderContent)
                                                    ? mobileHeaderContent(rowData)
                                                    : null
                                            }
                                            <div className="rt--table-item-inner rt--flex">
                                                {
                                                    sortable
                                                        ? getIsDraggable(rowData) ? <DragHandle /> : <button className='rt--table-drag-handle'></button>
                                                        : null
                                                }
                                                {
                                                    chooser && (
                                                        <div className='rt--table-item-chooser'>
                                                            { renderChooser(rowData) }
                                                        </div>
                                                    )
                                                }
                                                <div className="rt--table-item-row rt--flex-equal rt--flex">
                                                    {
                                                        filteredAndSortedColumnsByMobileLevel.map(columnData => {
                                                            const hideColumn = (
                                                                isFunction(columnData.isHiddenForMobile)
                                                                ? columnData.isHiddenForMobile(rowData)
                                                                : columnData.isHiddenForMobile
                                                            )

                                                            if (hideColumn) {
                                                                return;
                                                            }

                                                            return (
                                                                <div
                                                                    key={columnData.mobileLevel}
                                                                    className={classNames(
                                                                        "rt--table-item-col rt--flex rt--flex-col rt--justify-start rt--mb-8",
                                                                        mobileListView && "rt--table-item-col-list",
                                                                        columnData.isSwitcher && "rt--table-item-col-switcher",
                                                                        columnData.mobileFullWidth && "rt--table-item-col-full-width"
                                                                    )}
                                                                >
                                                                    {
                                                                        (!columnData.showOnlyValue && !columnData.isSwitcher) && (
                                                                            <b
                                                                                className={classNames(
                                                                                    "rt--title rt--font-normal rt--font-regular rt--pb-4",
                                                                                    columnData.isRed && "rt--error-text"
                                                                                )}
                                                                            >
                                                                                {getTitle(columnData)}
                                                                            </b>
                                                                        )
                                                                    }
                                                                    {
                                                                        <TableColumnContent
                                                                            rowData={rowData}
                                                                            columnData={columnData}
                                                                            onEdit={handleEditIconClick}
                                                                        />
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    <Actions
                                                        actions={actions}
                                                        chooserData={chooser}
                                                        maxNumberOfActionsInRow={actionsMaxNumberInRow}
                                                        rowData={rowData}
                                                        expandableData={{
                                                            fullViewData: {
                                                                isActive: (
                                                                    Boolean(expandable) && !expandable.disabled?.includes(rowData[uniqueKey])
                                                                ) || !disableFullView,
                                                                onClick: (rowData) => setDetailsRowData(rowData),
                                                            },
                                                            innerTableData: {
                                                                isActive: (
                                                                    expandable?.type === EXPANDABLE_TYPES.BOTH &&
                                                                    !expandable?.disabledForTable?.includes(rowData[uniqueKey])
                                                                ),
                                                                onClick: (rowData) => setExpandRowData(rowData)
                                                            }
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </DraggableRow>
                                    ))
                                }
                            </div>
                        </div>
                    </SortableContext>
                </DndContext>
            </Spin>

            {
                Boolean(editingFieldData) && (
                    <EditableCellModal
                        title={editingFieldData.title}
                        label={editingFieldData.label}
                        onClose={() => setEditingFieldData(null)}
                        onSave={editingFieldData.onSave}
                        initialValue={editingFieldData.initialValue}
                        extraButtons={editingFieldData.extraButtons}
                        record={editingFieldData.record}
                        maxLength={editingFieldData.maxLength}
                        regex={editingFieldData.regex}
                        type={editingFieldData.editableFieldType}
                    />
                )
            }

            {
                Boolean(detailsRowData) && (
                    <DitalseModal
                        columns={mobileTableColumns}
                        rowData={detailsRowData}
                        renderEmpty={renderEmpty}
                        expandable={expandable}
                        mobileListView={mobileListView}
                        disableFullView={disableFullView}
                        uniqueKey={uniqueKey}
                        onCancel={() => setDetailsRowData(null)}
                        fullViewClassName={fullViewClassName}
                    />
                )
            }

            {
                Boolean(expandRowData) && (
                    <ExpandViewModal
                        modlaTitle={expandable.tableTitle || expandable.title}
                        onCancel={() => setExpandRowData(null)}
                        detailsObj={expandable?.details?.(expandRowData)}
                        renderEmpty={renderEmpty}
                        isLoading={expandable.loading}
                        mobileListView={mobileListView}
                        detailsLoadFn={expandable?.detailsLoadFn}
                        rowData={detailsRowData}
                    />
                )
            }
        </>
    );

    const renderTable = () => {

        const tableContent = renderTableContent();

        switch (chooser?.type) {
            case ROW_CHOOSER_TYPES.RADIO:
                return (
                    <Radio.Group
                        style={{ width: "100%" }}
                        value={chooser.value}
                        onChange={handleRadioChooserChange}
                    >
                        {tableContent}
                    </Radio.Group>
                )
            case ROW_CHOOSER_TYPES.CHECKBOX:
                return (
                    <Checkbox.Group
                        style={{ width: "100%" }}
                        value={chooser.value}
                        onChange={handleCheckboxChooserChange}
                    >
                        {tableContent}
                    </Checkbox.Group>
                )
            default:
                return tableContent;
        }
    }

    return renderTable();
}

/** TableMobile propTypes
 * PropTypes
*/
TableMobile.propTypes = TablePropTypes;

export default TableMobile;
