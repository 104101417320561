import React from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from './sections/general';
import LimitSettingsComponent from './sections/limitSettings';
import CurrencyAndLimitsComponent from './sections/currencyAndLimits';
import IPAccessRuleComponent from './sections/ipAccessRule';
import WalletsComponent from './sections/wallets';
import AdditionalAccesses from './sections/additionalAccesses';
import TerminalComponent from './sections/terminal';

import { USER_ROLE } from "constants/user.constants";
import Paths from 'constants/path.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { getUser } from "utils/auth";

import useProjectType from 'hooks/useProjectType';

/** Betshop Edit Page Component */
const BetshopEditComponent = () => {
    const { t } = useTranslation();

    const { hasTerminal } = useProjectType();

    const items = [
        {
            title: t("backoffice.betshops.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETSHOP_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.projects.terminal"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETSHOP_TERMINALS, action: PERMISSION_ACTION.VIEW }],
            disabled: !hasTerminal,
            component: <TerminalComponent />
        },
        {
            title: t("backoffice.betshops.wallets"),
            permissions: [{ resource: getUser()?.role === USER_ROLE.ADMIN ? PERMISSION_RESOURCE.BETSHOP_WALLETS : PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW }],
            component: <WalletsComponent />
        },
        {
            title: t("backoffice.betshops.currencyAndLimits"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETSHOP_CURRENCIES, action: PERMISSION_ACTION.VIEW }],
            component: <CurrencyAndLimitsComponent />
        },
        {
            title: t("backoffice.betshops.limitSettings"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETSHOP_LIMIT_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <LimitSettingsComponent />
        },
        {
            title: t("backoffice.betshops.ipAccessRule"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETSHOP_IP_ACCESS_RULE, action: PERMISSION_ACTION.VIEW }],
            component: <IPAccessRuleComponent />
        },
        {
            title: t("backoffice.betshops.additionalAccesses"),
            permissions: [{ resource: PERMISSION_RESOURCE.BETSHOP_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.VIEW }],
            component: <AdditionalAccesses />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.betshops'), path: Paths.BETSHOPS }}
        />
    )
}

export default BetshopEditComponent;