//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region actions
import {
    setOnlineBetHistorySorting,
    setOnlineBetHistoryFilters,
    getOnlineBetHistory,
    getOnlineBetsTotals
} from 'store/actions/dashboard/betHistory/online.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import BetDetails from "../../betDetails.component";
import TotalsComponent from 'components/common/totals';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
//#endregion

//#region utils
import { isMobile } from "utils/common";
import { getDisabledExpandRowIds } from 'utils/bet';
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import { ONLINE_BETSLIPS } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
//#endregion

//#region types
import betType from 'types/bet/bet.type';
import sortingType from 'types/common/sorting.type';
import currencyType from 'types/currency/currency.type';
import betsTotalsType from 'types/bet/betsTotals.type';
import useUserCurrencies from "hooks/useUserCurrencies";
//#endregion

const OnlineBetHistoryComponent = ({
    bets,
    sorting,
    filters,
    total,
    isLoading,
    isTotalsLoading,
    getOnlineBetHistory,
    setOnlineBetHistorySorting,
    setOnlineBetHistoryFilters,
    globalProjectId,
    getOnlineBetsTotals,
    totals
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();
    const [userCurrencies, _, hasOneCurrency] = useUserCurrencies();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: ONLINE_BETSLIPS });

    const isMobileDevice = isMobile();

    const disabledExpandRowsIds = getDisabledExpandRowIds(bets);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasTotalsViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE_TOTALS,
        action: PERMISSION_ACTION.VIEW
    });

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setOnlineBetHistoryFilters({
            ...filters,
            playerUserNameOrId: value ? item ? item.id : value : ""
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                formatAmount,
                hasOneCurrency
            }
        })
    }, [includedColumns, formatAmount, hasOneCurrency])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters userCurrencies={userCurrencies} hasOneCurrency={hasOneCurrency} />,
        search: {
            loadFn: getOnlineBetHistory,
            onSearch: handleSearchChange,
            placeholder: `${t('backoffice.common.enter')} ${t('backoffice.bets.playerUsernameOrId')}`,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                autoGet: false,
                searchFieldName: "userNameOrId"
            }
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.bets.sportBets"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_ONLINE_BET_HISTORY,
            filters: filters
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobileDevice && hasTotalsViewPermission}
            extraContent={hasTotalsViewPermission && (
                <TotalsComponent
                    isLoading={isTotalsLoading}
                    getTotals={getOnlineBetsTotals}
                    totals={totals}
                    dependencies={[globalProjectId, filters]}
                    currencyCode={filters.currencyCode}
                    totalType={REPORT_TOTAL_TYPE.BET_HISTORY}
                />
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={bets}
                loadFn={getOnlineBetHistory}
                sorting={sorting}
                setSortingFn={setOnlineBetHistorySorting}
                total={total}
                updateProps={[globalProjectId]}
                expandable={{
                    title: t("backoffice.bets.bets"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    disabled: disabledExpandRowsIds,
                    details: record => Boolean(record.bets) && ({
                        data: record.bets,
                        content: (
                            <BetDetails
                                bets={record.bets}
                                currencyCode={record.currencyCode}
                            />
                        ),
                    }),
                }}
            />
        </TabTableDashboardLayout>
    )
}

/** OnlineBetHistoryComponent propTypes
    * PropTypes
*/
OnlineBetHistoryComponent.propTypes = {
    /** Redux state property, represents the array of online sport bet history */
    bets: PropTypes.arrayOf(betType),
    /** Redux state property, online bet history sorting details */
    sorting: sortingType,
    /** Redux state property, online bet history filters */
    filters: PropTypes.object,
    /** Redux state property, online bet history total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading bet history */
    isLoading: PropTypes.bool,
    /** Redux action to get online bet history */
    getOnlineBetHistory: PropTypes.func,
    /** Redux action to set online bet history sorting details */
    setOnlineBetHistorySorting: PropTypes.func,
    /** Redux action to set online bet history filters */
    setOnlineBetHistoryFilters: PropTypes.func,
    /** Redux state property, the current user currency */
    currency: currencyType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, online bets totals */
    totals: betsTotalsType,
    /** Redux action to get online bets totals */
    getOnlineBetsTotals: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getOnlineBetHistory: nextPage => {
            dispatch(getOnlineBetHistory(nextPage))
        },
        setOnlineBetHistorySorting: sorting => {
            dispatch(setOnlineBetHistorySorting(sorting));
        },
        setOnlineBetHistoryFilters: filters => {
            dispatch(setOnlineBetHistoryFilters(filters));
        },
        getOnlineBetsTotals: () => {
            dispatch(getOnlineBetsTotals());
        }
    }
)

const mapStateToProps = state => {
    return {
        bets: state.betHistory.online.sport.bets,
        total: state.betHistory.online.sport.total,
        sorting: state.betHistory.online.sport.sorting,
        filters: state.betHistory.online.sport.filters,
        totals: state.betHistory.online.sport.totals.data,
        isLoading: state.betHistory.online.sport.loading,
        isTotalsLoading: state.betHistory.online.sport.totals.loading,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineBetHistoryComponent)
