import React from 'react';
import i18n from "translations/config";

import Tooltip from 'components/common/tooltip';
import Status from 'components/common/status';
import LockInfo from 'components/common/lockInfo';

import { isRedirectToSubAgentsEnabledFn } from "utils/agents";
import {isNull, isUndefined} from "utils/javaScriptTypes";

import { WALLET_TYPE } from 'constants/wallet.constants';
import { STATUS_TOOLTIP_PLACEMENT, STATUS_TYPES } from 'constants/status.constants';
import { USER_STATE } from 'constants/user.constants';

import { TRANSFER_OPERATION_GROUP } from 'components/common/transfer';

export const getTableColumns = (additionalProps) => {
    const {
        userInfo,
        formatAmount,
        onUserNameClick,
        hasRetail,
        franchisingMode,
        hasLiteMode,
        hasOneCurrency,
        showTransferPopup,
        canTransferToAgent
    } = additionalProps;

    const columns = [
        {
            title: "backoffice.agents.id",
            dataIndex: "longId",
            copy: true,
            sorter: true,
            alwaysVisible: true,
        },
        {
            title: "backoffice.agents.userName",
            dataIndex: "userName",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
            render: (value, record) => {
                const redirectionEnabled = isRedirectToSubAgentsEnabledFn(record);

                return (
                    <div className="rt--flex rt--align-center rt--table-col-with-icon">
                        <span
                            onClick={redirectionEnabled ? () => onUserNameClick(record) : undefined}
                            className={`rt--title rt--font-normal rt--font-regular rt--pr-4 ${redirectionEnabled ? "rt--table-col-link" : ""}`}
                        >
                            {
                                redirectionEnabled
                                    ? <b>{value}</b>
                                    : value
                            }
                        </span>
                        {
                            record.suspended && (
                                <Tooltip
                                    placement="top"
                                    enableMobile={true}
                                    title={i18n.t("backoffice.agents.suspended")}
                                >
                                    <i className='icon-info rt--font-prebigest rt--font-error' />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            },
        },
        ...(
            hasLiteMode
                ? (
                    [{
                        title: "backoffice.agents.operations",
                        dataIndex: "operations",
                        alwaysVisible: true,
                        disableFillViewTooltip: true,
                        mobileLevel: 2,
                        render: (_, record) => {
                            if (!canTransferToAgent({ agentData: record, userInfo })) {
                                return null;
                            }

                            return (
                                <div className="rt--table-operations">
                                    <button onClick={() => showTransferPopup(record,
                                        {
                                            group: TRANSFER_OPERATION_GROUP.DEPOSIT
                                        }
                                    )}>
                                        <i className="icon-deposit"/>
                                    </button>
                                    <button onClick={() => showTransferPopup(record,{
                                        group: TRANSFER_OPERATION_GROUP.WITHDRAWAL
                                    })}>
                                        <i className="icon-withdrawal"/>
                                    </button>
                                </div>
                            );
                        }
                    }]
                )
                : []
        ),
        !franchisingMode && {
            title: "backoffice.agents.agentLevel",
            dataIndex: "hierarchy",
            render: value => {
                return (
                    <div className="rt--flex rt--align-center">
                        <i className="icon-level rt--font-bigest" />
                        <small className="rt--pl-4 rt--font-normal rt--font-regular">
                            {(value.match(/\//g) || []).length}
                        </small>
                    </div>
                )
            }
        },
        !franchisingMode && {
            title: "backoffice.agents.belongsTo",
            dataIndex: "parentName",
            render: value => value || "-"
        },
        {
            title: "backoffice.agents.country",
            dataIndex: "country",
            mobileLevel: 4,
            render: value => value ? i18n.t(`backoffice.countries.${value}`) : "-",
        },
        {
            title: "backoffice.agents.status",
            dataIndex: "state",
            sorter: true,
            mobileLevel: 6,
            render: (value, record) => (
                <Status
                    status={value}
                    type={STATUS_TYPES.USER}
                    showIcon={[USER_STATE.LOCKED2FA, USER_STATE.LOCKEDLOGIN].includes(value)}
                    tooltipClassName="rt--tooltip-locked-wrapper"
                    tooltipOptions={{
                        placement: STATUS_TOOLTIP_PLACEMENT.ICON,
                        title: (
                            <LockInfo
                                is2FALocked={record?.loginAttemptDetails?.is2FALocked}
                                lastLogin={record?.lastLogin}
                                lockTime={record?.loginAttemptDetails?.lockTime}
                            />
                        )
                    }}
                />
            ),
        },
        ...(
            !hasLiteMode
                ? [
                    {
                        title: "backoffice.agents.currencies",
                        dataIndex: "currencyCodes",
                        multi: true,
                        mobileLevel: 5,
                    }
                ]
                : hasOneCurrency
                    ? [
                        {
                            title: "backoffice.agents.commission",
                            dataIndex: "commissions",
                            render: (value, record) => {
                                const commission = value[record.currencyCodes[0].toLowerCase()];

                                if (isNull(commission) || isUndefined(commission)) {
                                    return "-";
                                }

                                return value[record.currencyCodes[0].toLowerCase()] + '%';
                            },
                        },
                        {
                            title: "backoffice.common.balance",
                            dataIndex: "wallets",
                            isLTR: true,
                            disabledForFranchisingMode: true,
                            withCurrencySymbol: true,
                            render: (value) => {
                                const wallet = value[0]?.[WALLET_TYPE.BALANCE];

                                if (!wallet) {
                                    return 0;
                                }

                                return formatAmount(wallet.amount ?? 0, wallet.currencyCode);
                            }
                        }
                    ]
                    : []
        ),
        {
            title: "backoffice.agents.subAgents",
            dataIndex: "subAgentsCount",
            disabledForFranchisingMode: true,
            render: value => value || 0,
        },
        {
            title: "backoffice.agents.players",
            dataIndex: "playersCount",
            disabledForFranchisingMode: true,
            render: value => value || 0,
        },
        hasRetail && {
            title: "backoffice.agents.betshops",
            dataIndex: "betShopsCount",
            render: value => value || 0
        },
        {
            title: "backoffice.agents.registrationDate",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.agents.lastLoginDate",
            dataIndex: "lastLogin",
            isDateTime: true,
        },
        {
            title: "backoffice.agents.label",
            dataIndex: "label",
            disabledForFranchisingMode: true,
        }
    ];

    return columns;
}

export const getExpandTableColumns = (additionalProps) => {
    const {
        formatAmount,
        hasLiteMode
    } = additionalProps;

    return [
        {
            title: "backoffice.agents.currency",
            dataIndex: "currencyCode",
        },
        {
            title: "backoffice.common.balance",
            dataIndex: "balance",
            isLTR: true,
            disabledForFranchisingMode: true,
            render: (_, record) => formatAmount(record[WALLET_TYPE.BALANCE]?.amount ?? 0, record.currencyCode)
        },
        ...(
            !hasLiteMode
                ? [
                    {
                        title: "backoffice.common.credit",
                        dataIndex: "credit",
                        render: (_, record) => formatAmount((record[WALLET_TYPE.CREDIT]?.amount ?? 0), record.currencyCode)
                    },
                    {
                        title: "backoffice.common.creditLine",
                        dataIndex: "creditLine",
                        render: (_, record) => formatAmount(record[WALLET_TYPE.CREDIT_LINE]?.amount ?? 0, record.currencyCode)
                    },
                ]
                : [
                    {
                        title: "backoffice.agents.commission",
                        dataIndex: "commission",
                        render: (value) => {
                            if (isNull(value) || isUndefined(value)) {
                                return "-";
                            }

                            return value + '%';
                        },
                    }
                ]
        )
    ]
}
