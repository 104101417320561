import i18n from "translations/config";

import {PLAYER_TRANSACTION_DIRECTION, PLAYER_TRANSACTION_TYPE} from "constants/player.constants";

const TRANSACTION_TYPE_NAMES = {
    [PLAYER_TRANSACTION_TYPE.BET]: 'bet',
    [PLAYER_TRANSACTION_TYPE.WON]: 'won',
    [PLAYER_TRANSACTION_TYPE.OTHER]: 'other',
    [PLAYER_TRANSACTION_TYPE.DEPOSIT]: 'deposit',
    [PLAYER_TRANSACTION_TYPE.WITHDRAWAL]: 'withdrawal',
};

const TRANSACTION_DIRECTION_NAMES = {
    [PLAYER_TRANSACTION_DIRECTION.DEBIT]: 'debit',
    [PLAYER_TRANSACTION_DIRECTION.CREDIT]: 'credit',
};

export const getTableColumns = ({ formatAmount }) => [
    {
        title: 'backoffice.players.date',
        dataIndex: 'date',
        isDateTime: true,
        mobileLevel: 1,
    },
    {
        title: 'backoffice.players.operationType',
        dataIndex: 'transactionDirection',
        render: (value) => i18n.t(`backoffice.players.${TRANSACTION_DIRECTION_NAMES[value]}`),
        mobileLevel: 2,
    },
    {
        title: 'backoffice.players.amount',
        dataIndex: 'amount',
        withCurrencySymbol: true,
        render: (value) => formatAmount(value),
        mobileLevel: 3,
    },
    {
        title: 'backoffice.players.balanceBefore',
        dataIndex: 'balanceBefore',
        withCurrencySymbol: true,
        render: (value) => formatAmount(value),
        mobileLevel: 4,
    },
    {
        title: 'backoffice.players.balanceAfter',
        dataIndex: 'balanceAfter',
        withCurrencySymbol: true,
        render: (value) => formatAmount(value),
        mobileLevel: 5,
    },
    {
        title: 'backoffice.players.product',
        dataIndex: 'product',
        mobileLevel: 6,
        alwaysVisible: true,
    }
];
