import React from 'react';

import "./styles.scss";

const ExpandIcon = ({ isActive }) => {
    return (
        <i className={"icon-down rt--collapse-expand-icon" + (isActive ? " rt--collapse-expand-icon__expanded" : "")} />
    );
};

export default ExpandIcon;
