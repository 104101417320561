import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';

import { getJobSettings, saveJobSettings } from "store/actions/dashboard/developer/jobs/jobs.action";

import jobSettingsType from "types/job/jobSettings.type";

import { isMobile } from 'utils/common';
import TextAreaInput from 'components/common/textAreaInput';

/** Job Editing Popup Component */
const JobEditComponent = ({
    isSaving,
    getJobSettings,
    saveJobSettings,
    jobSettings,
    onClose,
    id
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    /** Load job settings */
    useEffect(() => {
        getJobSettings(id)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setTimeout(() => {
            setFieldsValue({
                ...jobSettings
            })
        }, 0)

    }, [jobSettings])


    /** Fires when form submitted
       * @function
       * @memberOf JobEditComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ cronExpression, allowedServerNames }) => {
                saveJobSettings({
                    cronExpression,
                    id,
                    allowedServerNames,
                    enabled: jobSettings.enabled
                });
                onClose()
            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.jobs.editJob')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.jobs.cronDescription')}
                        >
                            <div className='rt--job-info'>
                                <span className='rt--title rt--font-normal rt--font-bold'>{jobSettings.cronDescription}</span>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.jobs.cronExpression')} *`}
                            name="cronExpression"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.jobs.cronExpression')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.jobs.cronExpression')}`}
                                autoFocus={(isMobile() ? false : true)}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.jobs.allowedServerNames')} *`}
                            name="allowedServerNames"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.jobs.allowedServerNames')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.jobs.allowedServerNames')}`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** JobEditComponent propTypes
    * PropTypes
*/
JobEditComponent.propTypes = {
    /** Redux state property, is true when job edit request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to save job settings */
    saveJobSettings: PropTypes.func,
    /** Redux action to get job settings */
    getJobSettings: PropTypes.func,
    /** Redux state, represents the settings of current editing job  */
    jobSettings: jobSettingsType,
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Current editing job id */
    id: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getJobSettings: id => {
            dispatch(getJobSettings(id));
        },
        saveJobSettings: settings => {
            dispatch(saveJobSettings(settings));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.jobs.isSaving,
        jobSettings: state.jobs.jobSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobEditComponent)