import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Divider, Menu } from 'antd';

import Dropdown from "components/common/dropdown";
import Flag from "components/common/flag"
import Notifications from 'components/common/notifications';

import useUnsavedFormConfirmation from 'hooks/useUnsavedFormConfirmation';

import { logout, getUser, changeCurrency } from 'utils/auth';
import { isMobile } from "utils/common";

import Paths from 'constants/path.constants';
import { setUrlParam } from "utils/common";
import LanguageUtils from 'utils/languages';
import sessionStorageUtils from 'utils/sessionStorage';

import { USER_ROLE } from "constants/user.constants";
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import userInfoType from "types/profile/userInfo.type";
import currencyType from 'types/profile/currency.type';

/** Profile dropdown component on Header */

const ProfileDropdown = ({
    userInfo,
    currency,
    currencies,
    languages
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();


    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showCurrenciesList, setShowCurrenciesList] = useState(false);
    const [showLanguagesList, setShowLanguagesList] = useState(false);

    const currentLn = LanguageUtils.getSelectedLanguage();

    const logoutWithConfirmation = useUnsavedFormConfirmation({
        cb: logout,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const navigateWithConfirmation = useUnsavedFormConfirmation({
        cb: navigate,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const changeLanguage = (key) => {
        setUrlParam('language', key)
        LanguageUtils.setSelectedLanguageToStorage(key, languages);
        sessionStorageUtils.remove("userInfo");
        window.location.reload();
    }

    const changeLanguageWithConfirmation = useUnsavedFormConfirmation({
        cb: changeLanguage,
        dependencies: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    useEffect(() => {
        if (!dropdownVisible) {
            setShowCurrenciesList(false);
            setShowLanguagesList(false);
        }
    }, [dropdownVisible])

    /** Fires on dropdown item click
       * @function
       * @param {string} value - selected item value
       * @memberOf ProfileDropdown
   */
    const handleClick = value => {
        if (value === "logout") {
            setDropdownVisible(false)
            logoutWithConfirmation()
        } else if (value === "currency") {
            setShowCurrenciesList(true)
        } else if (value === "language") {
            if (Object.keys(languages).length > 1) {
                setDropdownVisible(true);
                setShowLanguagesList(true)
            }
        } else if (value === "profile") {
            navigateWithConfirmation(Paths.PROFILE);
            setDropdownVisible(false)
        }
    }

    /** Function, fires on currencies dropdown item click
       * @function
       * @param {string} key
       * @memberOf ProfileDropdown
   */
    const handleCurrencyDropdownChange = key => {
        if (key) {
            if (key === "back") {
                setShowCurrenciesList(false);
            } else {
                const curr = currencies.find(c => c.currencyCode === key);
                if (currency !== curr?.currencyCode) {
                    changeCurrency(curr?.currencyCode);
                }
                setShowCurrenciesList(false);
            }
        }
    }

    /** Function, fires on languages dropdown item click
     * @function
     * @param {string} key
     * @memberOf ProfileDropdown
    */
    const handleLanguageDropdownChange = key => {
        if (key) {
            if (key === "back") {
                setShowLanguagesList(false)
            } else {
                setDropdownVisible(false)
                changeLanguageWithConfirmation(key)
            }
        }
    }

    /** Function, to build avatar path
       * @function
       * @returns {string} path
       * @memberOf ProfileDropdown
   */
    const buildAvatarPath = () => {
        const avatarId = userInfo?.avatarId ?? null;
        const companyLongId = userInfo?.mainCompanyLongId ?? null;
        const projectLongId = getUser()?.projectLongId ?? null;
        const userLongId = userInfo?.longId ?? null;
        if (getUser()?.role === USER_ROLE.ADMIN || getUser()?.role === USER_ROLE.ACCESS_MANAGER) {
            return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/users/${userLongId}/${avatarId}_small.png`
        } else {
            return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/users/${userLongId}/${avatarId}_small.png`
        }
    }

    return (
        <div className='rt--header-actions'>
            {
                (
                    <Dropdown
                        className="rt--dropdown-menu-with-back"
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        dropdownRender={() => (
                            showCurrenciesList ? (
                                <Menu
                                    onClick={e => handleCurrencyDropdownChange(e ? e.key : null)}
                                    items={[
                                        {
                                            key: 'back',
                                            className: 'rt--menu-item-back',
                                            label: (
                                                <div className='rt--flex rt--align-center'>
                                                    <i className='icon-left rt--font-bigest' />
                                                    <span className='rt--title rt--font-normal rt--font-bold rt--pl-4'>
                                                        {t('backoffice.currencies.currency')}
                                                    </span>
                                                </div>
                                            )
                                        },
                                        ...(
                                            currencies.map(c =>
                                                ({
                                                    key: c.currencyCode,
                                                    className: c.currencyCode === currency ? "rt--dropdown-item-active" : "",
                                                    label: (
                                                        <>
                                                            <span
                                                                className='rt--title rt--font-normal rt--font-regular'>
                                                                {c.currencyCode}
                                                            </span>
                                                            {
                                                                isMobile() && c.currencyCode === currency && (
                                                                    <i className='icon-ok rt--font-bigest'/>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }))
                                        )
                                    ]}
                                />
                            ) : showLanguagesList ? (
                                <Menu
                                    onClick={e => handleLanguageDropdownChange(e ? e.key : null)}
                                    items={[
                                        {
                                            key: 'back',
                                            className: 'rt--menu-item-back',
                                            label: (
                                                <div className='rt--flex rt--align-center'>
                                                    <i className='icon-left rt--font-bigest' />
                                                    <span className='rt--title rt--font-normal rt--font-bold rt--pl-4'>
                                                        {t('backoffice.languages.language')}
                                                    </span>
                                                </div>
                                            )
                                        },
                                        ...Object.keys(languages).map(lang =>
                                            (
                                                {
                                                    key: lang,
                                                    className: lang === currentLn? "rt--dropdown-item-active" : "",
                                                    label: (
                                                        <>
                                                            <div className='rt--flex rt--align-center'>
                                                                <Flag code={lang}/>
                                                                <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>
                                                                    {t(`backoffice.languages.${lang}`)}
                                                                </span>
                                                            </div>
                                                            {
                                                                isMobile() && lang === currentLn && (
                                                                    <i className='icon-ok rt--font-bigest'/>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            )
                                        )
                                    ]}
                                />
                            ) : (
                                <Menu
                                    onClick={e => e.key && handleClick(e.key)}
                                    items={[
                                        {
                                            key: 'profile',
                                            label: (
                                                isMobile()
                                                    ? (
                                                        <div className='rt--flex rt--align-center rt--mt-4'>
                                                            <div className='rt--header-avatar rt--header-avatar-mobile rt--flex rt--align-center rt--justify-center'>
                                                                <span className='rt--title rt--font-normal rt--font-bold'>{getUser()?.userName?.substring(0, 2)}</span>
                                                            </div>
                                                            <div className='rt--flex rt--flex-col rt--ml-6'>
                                                                <span className='rt--title rt--font-bold rt--font-big'>{getUser()?.userName}</span>
                                                                <span className='rt--header-subTitle rt--title rt--font-bold rt--font-normal'> {t('backoffice.common.id')} {userInfo.longId}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                    : (
                                                        <span className='rt--title rt--font-normal rt--font-regular'>
                                                            {t('backoffice.common.profile')}
                                                        </span>
                                                    )
                                            )
                                        },
                                        ...(
                                            isMobile() && getUser()?.role !== USER_ROLE.ACCESS_MANAGER
                                                ? [
                                                    {
                                                        key: 'currency',
                                                        className: "rt--dropdown-item-not-selected",
                                                        label: (
                                                            <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                                                                <span className='rt--title rt--font-normal rt--font-regular'>
                                                                    {`${t("backoffice.currencies.currency")}: `}
                                                                    <strong className='rt--title rt--font-normal rt--font-regular rt--pl-4'>
                                                                        { currency ?? ""}
                                                                    </strong>
                                                                </span>
                                                                <i className="icon-right rt--font-bigest" />
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        key: 'notification',
                                                        className: "rt--dropdown-item-not-selected",
                                                        label: (
                                                            <Notifications
                                                                onClose={() => setDropdownVisible(false)} 
                                                                renderButton={(
                                                                    unreadNotificationsCount,
                                                                    onClick
                                                                ) => (
                                                                    <div 
                                                                        className="rt--flex rt--width-full rt--align-center rt--justify-between"
                                                                        onClick={onClick}
                                                                    >
                                                                        <div className='rt--flex rt--align-center'>
                                                                            <span className='rt--title rt--font-normal rt--font-regular rt--pr-4'>
                                                                                {`${t("backoffice.notifications.notifications")}`}
                                                                            </span>
                                                                            {
                                                                                unreadNotificationsCount > 0 && (
                                                                                    <div className="rt--notifications-mark rt--flex rt--justify-center rt--align-center">
                                                                                        <span className='rt--title rt--font-smallest rt--font-bold'>{unreadNotificationsCount <= 99 ? unreadNotificationsCount : '99+'}</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        
                                                                        <i className="icon-right rt--font-bigest"/>
                                                                    </div>
                                                                )}
                                                            />
                                                        )
                                                    }
                                                ] : []
                                        ),
                                        {
                                            key: 'language',
                                            className: 'rt--dropdown-item-not-selected',
                                            label: (
                                                <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                                                    <span className='rt--title rt--font-normal rt--font-regular'>
                                                        {`${t("backoffice.languages.language")}: `}
                                                        <strong className='rt--title rt--font-normal rt--font-regular rt--pl-4'>
                                                            {t(`backoffice.languages.${currentLn}`)}
                                                        </strong>
                                                    </span>

                                                    {
                                                        Object.keys(languages).length > 1 && (
                                                            <i className="icon-right rt--font-bigest"/>
                                                        )
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            key: 'divider',
                                            className: 'rt--divider-dropdown-item',
                                            label: (
                                                <Divider className='rt--divider-dropdown' />
                                            )
                                        },
                                        {
                                            key: 'logout',
                                            label: (
                                                <span className='rt--title rt--font-normal rt--font-regular'>
                                                    {t('backoffice.common.logout')}
                                                </span>
                                            )
                                        }
                                    ]}
                                />
                            )
                        )}
                        disabled={false}
                        popupVisible={dropdownVisible}
                        onVisibleChange={(visible, {source}) => {
                            if (source === 'trigger') {
                                setDropdownVisible(visible);
                            }
                        }}
                    >
                        {
                            isMobile() ? (
                                <div className='rt--header-avatar rt--flex rt--align-center rt--justify-center'>
                                    <span className='rt--title rt--font-normal rt--font-bold'>{getUser()?.userName?.substring(0, 2)}</span>
                                </div>
                            ) : (
                                <div className='rt--header-actions-content rt--flex rt--align-center'>
                                    <div className='rt--header-avatar rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                        {
                                            userInfo?.avatarId ?? null !== null ? (
                                                <img src={buildAvatarPath()}
                                                />
                                            ) : (
                                                <i className='icon-user-avatar rt--font-bigest' />
                                            )
                                        }
                                    </div>
                                    <div className='rt--flex rt--flex-col rt--pr-4'>
                                        <span className='rt--header-actions-name rt--title rt--font-normal rt--font-bold'>
                                            {getUser()?.userName}
                                        </span>
                                        <span className='rt--header-actions-id rt--title rt--font-small'>
                                            ID: {userInfo.longId}
                                        </span>
                                    </div>
                                    <i className='icon-down-small rt--font-bigest rt--header-actions-content-arrow' />
                                </div>
                            )
                        }


                    </Dropdown>
                )
            }

        </div>
    )
}

/** ProfileDropdown propTypes
    * PropTypes
*/
ProfileDropdown.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux state property, the current user currency */
    currency: PropTypes.string,
    /** Redux state property, current user currencies */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, system languages */
    languages: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        currency: state.profile.currency,
        currencies: state.profile.currencies,
        languages: state.auth.translations.languages
    }
}

export default connect(mapStateToProps, null)(ProfileDropdown)

