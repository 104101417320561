import PropTypes from 'prop-types';

import { TERMINAL_STATUS } from 'constants/terminal.constants';

export default PropTypes.shape({
    id: PropTypes.string,
    longId: PropTypes.number,
    ownerId: PropTypes.string,
    betShopId: PropTypes.string,
    name: PropTypes.string,
    isTesting: PropTypes.bool,
    status: PropTypes.oneOf(Object.values(TERMINAL_STATUS)),
    lastCashRemovalDate: PropTypes.string,
    label: PropTypes.string,
    version: PropTypes.string,
    basketBalance: PropTypes.number,
    ownerName: PropTypes.string,
    betShopName: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    modifiedAt: PropTypes.string
})
