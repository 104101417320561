import React from 'react';
import {Col, Row} from "antd";
import CommissionItem
    from "pages/agentSystem/agents/edit/sections/general/components/commissions/components/commissionItem";

const Commissions = ({ currencies, value = {}, onChange }) => {
    const handleItemChange = (itemValue, currency) => {
        onChange({ ...value, [currency]: itemValue });
    }

    return (
        <Row gutter={[24, 16]}>
            {currencies.map(currency => {
                const name = currency.currency.toLowerCase();
                const currencyValue = value?.[name];

                return (
                    <Col key={currency.currency} lg={8} xs={12}>
                        <CommissionItem
                            currency={currency}
                            value={currencyValue}
                            onChange={(value) => handleItemChange(value, name)}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default Commissions;
