const mapSubProjectsToForm = (subProjects) => (
    subProjects.reduce((acc, subProject) => {
        const { currencyCodes } = subProject;

        currencyCodes.filter(currencyCode => {
            currencyCode = currencyCode.toLowerCase();

            if (!acc[currencyCode]) {
                acc[currencyCode] = [];
            }

            acc[currencyCode].push(subProject.projectId);
        });

        return acc;
    }, {})
)

export default mapSubProjectsToForm;
