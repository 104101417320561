import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getWorkingHoursConfigs = id => {
    return axios({
        url: `${ApiUrls.GET_BETSHOP_TERMINAL_WORKING_HOURS}`,
        method: Methods.GET,
        params: {
            id
        }
    }).then(({ data: {value} }) => {
        return value
    });
}

export const saveWorkingHoursConfigs = (data, id) => {
    return axios({
        url: `${ApiUrls.SAVE_BETSHOP_TERMINAL_WORKING_HOURS}`,
        method: Methods.POST,
        data: {
            ...data,
            id
        },
    }).then(({data: {value, message}}) => {
        return {value, message};
    });
}