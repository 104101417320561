import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';
import {Badge, Tag} from "antd";

export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        t
    } = additionalProps;

    return [
        {
            title: "backoffice.terminals.id",
            dataIndex: "longId",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.terminals.name",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 2,
            render: (value, record) => Boolean(value) ? record.isTesting ? `${value} (Test)` : value : "-",
        },
        {
            title: "backoffice.terminals.state",
            dataIndex: "isRunning",
            alwaysVisible: true,
            mobileLevel: 2,
            render: (value) => (
                value
                    ? <Tag icon={<Badge className="rt--mr-4" color="#00BA88" />} color="success">{t("backoffice.common.running")}</Tag>
                    : <Tag className="rt--terminal-state__not-running" icon={<Badge className="rt--mr-4" color="#A0A3BD" />} color="#F7F7FC">{t("backoffice.common.notRunning")}</Tag>
            ),
        },
        {
            title: 'backoffice.terminals.betshop',
            dataIndex: 'betShopName',
            mobileLevel: 3
        },
        {
            title: "backoffice.terminals.status",
            dataIndex: "status",
            sorter: true,
            mobileLevel: 4,
            render: value => value === 0 ? "-" : (
                <Status
                    status={value}
                    type={STATUS_TYPES.TERMINAL}
                />
            ),
        },

        {
            title: "backoffice.terminals.lastCashRemoval",
            dataIndex: "lastCashRemovalDate",
            isDateTime: true,
            sorter: true,
            mobileLevel: 6
        },
        {
            title: "backoffice.terminals.label",
            dataIndex: "label",
            render: value => value ?? "-"
        },
        {
            title: "backoffice.terminals.version",
            dataIndex: "version",
            render: value => value ?? "-"
        },
        {
            title: "backoffice.terminals.basketBalance",
            dataIndex: "basketBalance",
            render: (value, record) => formatAmount(value ?? 0, record.currencyCode),
            mobileLevel: 4,
        },
        {
            title: "backoffice.terminals.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true
        },
        {
            title: "backoffice.terminals.createdBy",
            dataIndex: "createdBy",
            sorter: true
        },
        {
            title: "backoffice.terminals.lastUpdatedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
        },
    ]
};
