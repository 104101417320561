import React, {useCallback} from 'react';
import {isMobile} from "utils/common";

import StatisticsFiltersDesktop from "pages/dashboard/tabs/statisticsTab/filters/desktop";
import StatisticsFiltersMobile from "pages/dashboard/tabs/statisticsTab/filters/mobile";
import {FILTER_TYPE} from "constants/common.constants";

const StatisticsFilters = ({ filters, currencyOptions, hasOneCurrency, onChange }) => {
    const globalFilters = filters.global;

    const handleFiltersChange = useCallback((value) => {
        if (value.betShopNameOrId) {
            value = { ...value, filterType: FILTER_TYPE.NETWORK };
        }

        onChange('global', value);
    }, [onChange]);

    if (isMobile()) {
        return (
            <StatisticsFiltersMobile
                filters={globalFilters}
                onChange={handleFiltersChange}
                currencyOptions={currencyOptions}
                hasOneCurrency={hasOneCurrency}
            />
        );
    }

    return (
        <StatisticsFiltersDesktop
            filters={globalFilters}
            onChange={handleFiltersChange}
            currencyOptions={currencyOptions}
            hasOneCurrency={hasOneCurrency}
        />
    );
};

export default StatisticsFilters;
