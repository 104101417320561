//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getRequests,
    setRequestsSorting
} from "store/actions/dashboard/developer/requests/requests.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useDate from "hooks/useDate";
//#endregion

//#region components
import Table from "components/common/table";
import TabTableDashboardLayout from "components/layouts/tab/table";
import Filters from "./filters.component";
import Details from "./details.component"
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { copyToClipboard, prettyJson } from "utils/common";
//#endregion

//#region constants
import { REQUESTS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
import { EXPANDABLE_TYPES } from "components/common/table/constants";
import { INTEGRATION_TYPE } from "constants/project.constants";
import METHODS from "constants/methods.constants";
//#endregion

//#region types
import requestType from "types/request/request.type";
import sortingType from "types/common/sorting.type";
//#endregion

const RequestsComponent = ({
    getRequests,
    requests,
    isLoading,
    total,
    setRequestsSorting,
    sorting,
    type
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: REQUESTS });

    //#region ---------------------------------- HANDLERS -----------------------------------//

    const handleCopyIconClick = record => {
        const request = record.request;
        const response = record.response;
        const method = record.method?.toLowerCase();

        const requestJson = method === METHODS.GET ? 
                record?.request?.queryStrings ? JSON.stringify(record?.request?.queryStrings) : "" :
                record?.request?.body ?? "" ;
        const responseStatus = response?.status ? `${response?.status} ${response?.errorMessage ? " - " + response?.errorMessage : ""}` : "";
        const responseJson = response?.content ?? "";

        let str = `---------------------------------------------
${record.name}, ${record.method}, ${record.httpStatusCode} - ${record.httpStatus}

${t("backoffice.errorlogs.time")}: ${dateService.format(record.createdAt)}

${t("backoffice.errorlogs.url")}: ${record?.request?.url}

${t("backoffice.errorlogs.machineName")}: ${record.machineName}

${ method === METHODS.GET ? t("backoffice.errorlogs.queryStrings") : t("backoffice.errorlogs.body") }
${ prettyJson(requestJson) }

Request Headers: ${prettyJson(JSON.stringify(request?.headers))}

Authorization: ${request?.headers?.authorization}

${t("backoffice.errorlogs.response")} - ${responseStatus}

Response Headers: ${prettyJson(JSON.stringify(response?.headers))}

${ prettyJson(responseJson) }
---------------------------------------------`;
        copyToClipboard(str)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        })
    }, [includedColumns])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters type={type} />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.integrationRequests') }]
        }
    }

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    tableRowActions.push({
        title: t('backoffice.common.copy'),
        icon: "icon-copy",
        onClick: record => handleCopyIconClick(record),
    })

    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={requests}
                loadFn={nextPage => getRequests(nextPage, type)}
                sorting={sorting}
                setSortingFn={sorting => setRequestsSorting(sorting, type)}
                total={total}
                actions={tableRowActions}
                expandable={{
                    title: " ",
                    type: EXPANDABLE_TYPES.CONTENT,
                    details: record => ({
                        content: (
                            <Details
                                request={record}
                            />
                        ),
                    }),
                }}
            />
        </TabTableDashboardLayout>
    )
};

/** RequestsComponent propTypes
 * PropTypes
*/
RequestsComponent.propTypes = {
    /** Redux action to get requests */
    getRequests: PropTypes.func,
    /** Redux state property, represents the array of requests  */
    requests: PropTypes.arrayOf(requestType),
    /** Redux state property, is true when loading errors */
    isLoading: PropTypes.bool,
    /** Redux state property, requests total count */
    total: PropTypes.number,
    /** Redux action to set requests sorting details */
    setRequestsSorting: PropTypes.func,
    /** Redux state property, requests sorting details */
    sorting: sortingType,
    /** Type */
    type: PropTypes.oneOf(Object.values(INTEGRATION_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getRequests: (nextPage, type) => {
        dispatch(getRequests(nextPage, type));
    },
    setRequestsSorting: (sorting, type) => {
        dispatch(setRequestsSorting(sorting, type));
    }
});

const mapStateToProps = (state, props) => {
    const type = props.type;
    return {
        isLoading: state.requests.isLoading,
        requests: state.requests[type].requests,
        total: state.requests[type].total,
        sorting: state.requests[type].sorting
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestsComponent);
