import { message } from 'antd';
import i18n from 'i18next';

import dateService from "./date";

import store from 'store/configureStore';

import { PROJECT_TYPE } from "constants/project.constants";
import { ENVIRONMENT_TYPE, LOGO_TYPES } from 'constants/common.constants';
import { USER_ROLE } from "constants/user.constants";

import { getUser } from "./auth";
import { isNumber, isString } from './javaScriptTypes';
import { BETSHOP_BET_TYPE } from 'constants/betshop.constants';

/** Get query params
     * @function
     * @param {string} path - location path
     * @returns {object} - query params
 */
export const getUrlVars = path => {
    const href = path || window.location.href;
    const vars = {};
    href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value ? value.split("#")[0] : value;
        }
    );
    return vars;
}

/** Cheks if the page is RTL
      * @function
      * @param {string} language - the language
      * @returns {boolean}
 */
export const isRTL = language => {
    const farsiLanguages = ['fa', 'FA', 'ar', 'AR', 'he', 'HE'];
    return farsiLanguages.includes(language);
}


/** Check if device is ios
      * @function
      * @returns {boolean}
 */
export const isIOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;

export const IOSversion = () => {
    if (/iP(hone|od|ad)/.test(window.navigator.userAgent)) {
        const versionInfoArr = window.navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return {
            fullName: versionInfoArr[0],
            version: parseInt(versionInfoArr[1]) || 0,
            subBersion: parseInt(versionInfoArr[2]) || 0,
            fixVersion: parseInt(versionInfoArr[3]) || 0,
        }
    }
}

/** Check if device is mobile
     * @function
     * @returns {boolean}
 */
export const isMobile = () => {
    const isMobileBrowser = {
        Android: () => navigator.userAgent.match(/Android/i) !== null,
        BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
        iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null || (navigator.userAgent.includes("Mac") && "ontouchend" in document),
        Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
        Windows: () => navigator.userAgent.match(/IEMobile/i) !== null || navigator.userAgent.match(/WPDesktop/i) !== null,
        any: () => isMobileBrowser.Android() || isMobileBrowser.BlackBerry() || isMobileBrowser.iOS() || isMobileBrowser.Opera() || isMobileBrowser.Windows()
    };
    return isMobileBrowser.any() || getUrlVars()["isMobile"] === "true";
};

/** Copy text to clipboard
     * @function
     * @param {string} text - text to copy
     * @returns {string}
*/

export const copyToClipboard = text => {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    message.success(i18n.t('backoffice.common.copied'));
    return result;
}

/** Copy element to clipboard
     * @function
     * @param {object} eleemnt - element to copy
     * @returns {string}
*/
export const copyElementToClipboard = element => {
    window.getSelection().removeAllRanges();
    let range = document.createRange();
    range.selectNode(element);
    window.getSelection().addRange(range);
    const result = document.execCommand('copy');
    window.getSelection().removeAllRanges();
    message.success(i18n.t('backoffice.common.copied'));
    return result;
}

/** Make first letter of string to upper case
     * @function
     * @param {string} str - string to convert
     * @returns {string}
 */
export const toUpperCaseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

/** Make first letter of string to lower case
     * @function
     * @param {string} str - string to convert
     * @returns {string}
 */
export const toLowerCaseFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1)

/** function which make a binary integer, from array of enum values, so server can detect selected enum values
    * @function
    * @param {array} arr - array of enum values(1,2,4,8,16,...)
    * @returns {number}
*/
export const flagsToBinary = arr => arr.reduce((a, b) => a ^ b, 0)

/** function which filter enum values, to values which are true with given binary number
     * @function
     * @param {array} flags - array of all enum values(1,2,4,8,16,...)
     * @param {binaryNum} number - binary integer, which represents the array of selected flags
     * @returns {array}
 */
export const binaryToFlags = (flags, binaryNum) => flags.filter(f => f & binaryNum)

/** Update location hash
     * @function
     * @param {string} hash - New hash
     * @param {boolean} add - If true , will add new param to hash, otherwise will replace
 */
export const updateLocationHash = (hash, add) => {
    const scrollmem = document.body.scrollTop;
    let h = window.location.hash.replace("#", "");
    const params = h.split("&").filter(p => p !== "" && p.split("=")[0] !== hash.split("=")[0]);
    window.location.hash = add && params.length > 0 ? params.join("&") + "&" + hash : hash;
    document.body.scrollTop = scrollmem;
}

/** Get hash param value from hash
     * @function
     * @param {string} param - Param name
     * @returns {string}
 */
export const getHashValue = param => {
    const urlParams = new URLSearchParams(window.location.hash.replace("#", "?"));

    return urlParams.get(param) || "";
}

/** Clear location hash
     * @function
 */
export const clearLocationHash = () => {
    window.location.hash = "";
}

/** function which transform string to Number if possible
     * @function
     * @description used for transform rule of NumericInput component
     * @param {string} v  - string to convert
     * @returns {number}
 */
export const numberTransform = v => !isNaN(v) && v !== "" ? Number(v) : v;

/** function which counts the character counts of floating number after "."
     * @function
     * @param {number} num
     * @returns {number}
 */
export const countDecimals = num => {
    if (Math.floor(num) === num || isNaN(num)) return 0;
    return num.toString().split(".")[1] ? num.toString().split(".")[1].length : 0;
}

/** Distinct array of objects by property
     * @param {array} arr - array to distinct
     * @param {string} propName - the property name to distinct by
     * @returns {array} - distincted array
     * @function
 */
export const distinctArrayOfObjects = (arr, propName) => {
    const result = [];
    const map = new Map();
    for (const item of arr) {
        if (!map.has(item[propName])) {
            map.set(item[propName], true);
            result.push({
                ...item
            });
        }
    }
    return result;
}

/** Distinct array of objects by property
     * @param {array} arr - array to distinct
     * @param {string} propName - the property name to distinct by
     * @returns {array} - distincted array
     * @function
 */
export const downloadURI = (uri, name) => {
    fetch(uri)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => console.log("cant download"));
}

/** Format bytes as human-readable text.
     * @function
     * @param {number} bytes - Number of bytes
     * @returns {string} - Formatted string.
 */
export const humanFileSize = bytes => {
    const thresh = 1024;
    const dp = 2

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

/** function which prints DOM elemnt content
     * @function
     * @param {string} id - The DOM elemnt id to print
 */
export const printElement = id => {
    const element = document.getElementById(id);
    const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
    const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter(l => l.rel === "stylesheet");
    const elementsToCopy = [...styleTags, ...linkTags];
    let pri;
    const iframe = document.getElementById(id + "-iframe") ? document.getElementById(id + "-iframe") : document.createElement('iframe');
    iframe.setAttribute("id", id + "-iframe");
    iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
    iframe.setAttribute("height", "560px")
    iframe.setAttribute("width", "420px")
    document.body.appendChild(iframe);
    pri = iframe.contentWindow;
    pri.document.open();
    pri.document.write("<div class='rt--print-view rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16'>" + element.innerHTML + "</div>");
    elementsToCopy.forEach(el => {
        const node = el.cloneNode(true);
        node.setAttribute("media", "print")
        pri.document.body.appendChild(el.cloneNode(true))
    })
    pri.document.close()
    pri.focus();

    pri.addEventListener('load', function () {
        pri.print();
    }, false);
}

/** Function to check if the project is retail
     * @function
     * @param {array} allCompanies - Array of companies
     * @param {string} globalCompanyId - selected company id
     * @param {string} globalProjectId - selected project id
     * @returns {boolean}
 */
export const isCurrentProjectRetail = (allCompanies, globalCompanyId, globalProjectId) => {
    const company = allCompanies.find(p => p.id === globalCompanyId);
    let projects = [];
    if (company && company.projects) {
        projects = company.projects;
    }
    const project = projects.find(p => p.id === globalProjectId);
    return binaryToFlags(Object.values(PROJECT_TYPE), project?.type ?? 0).includes(PROJECT_TYPE.RETAIL_SYSTEM)
}

/** Function to check if the project is agent system
     * @function
     * @param {array} allCompanies - Array of companies
     * @param {string} globalCompanyId - selected company id
     * @param {string} globalProjectId - selected project id
     * @returns {boolean}
 */
export const isCurrentProjectAgentSystem = (allCompanies, globalCompanyId, globalProjectId) => {
    const company = allCompanies.find(p => p.id === globalCompanyId);
    let projects = [];
    if (company && company.projects) {
        projects = company.projects;
    }
    const project = projects.find(p => p.id === globalProjectId);

    return binaryToFlags(Object.values(PROJECT_TYPE), project?.type ?? 0).includes(PROJECT_TYPE.AGENT_SYSTEM)
}

/** Set query params
     * @function
     * @param {string} param - param name
     * @param {string} value - param name
     * @returns {object} - query params
 */
export const setUrlParam = (param, value) => {
    let queryParams = new URLSearchParams(window.location.search);
    if (value) {
        queryParams.set(param, value);
    } else {
        queryParams.delete(param, value);
    }

    window.history.pushState(null, null, queryParams.toString() ? "?" + queryParams.toString() : "");
}

/** Validate function for regex
     * @function
     * @param {string} value - value of field
 */
export const validateRegex = value => {

    if (value) {
        try {
            new RegExp(value);
        } catch (ex) {
            return Promise.reject(i18n.t('backoffice.validation.fieldInvalid'))
        }
    }

    return Promise.resolve();
}

/** Function to get current environement type
     * @function
     * @returns {number} environement type
 */
export const getEnvironment = () => {
    let et = null;
    if (import.meta.env.SYSTEM_APP_MODE === "production") {
        if (window.location.origin === import.meta.env.SYSTEM_RETAIL_DOMAIN) {
            et = ENVIRONMENT_TYPE.RETAIL;
        } else if (window.location.origin === import.meta.env.SYSTEM_AGENT_SYSTEM_DOMAIN) {
            et = ENVIRONMENT_TYPE.AGENT_SYSTEM;
        }
    } else {
        et = (new URLSearchParams(window.location.search)).get("et");
    }

    if (Number(et) === ENVIRONMENT_TYPE.RETAIL) {
        return ENVIRONMENT_TYPE.RETAIL;
    }
    return ENVIRONMENT_TYPE.AGENT_SYSTEM;
}

/** Function , check if the current mode is franchising
     * @function
     * @returns {bool}
 */
export const isFranchisingMode = () => {
    return getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL
}

/** Send Data To Iframe
     * @param {string} data - ifrmae element id
     * @param {object} data - the data to send
     * @param {string} name - message name
     * @function
 */
export const sendDataToIframe = (iframeId, data) => {
    const iframeEl = document.getElementById(iframeId);
    if (!iframeEl) { return; }
    iframeEl.contentWindow.postMessage(data, "*");
}

/** Checks if the object has defined values
     * @param {obj} object - object to check
     * @returns {boolean}
     * @function
 */
export const objectHasDefinedValue = obj => {
    if (obj === null || obj === undefined) return false;

    return Object.values(obj).some(v => v !== null && v !== "")
}

export const classNames = (...classNames) => {
    let classes = "";

    for (let className of classNames) {
        if (!className) {
            continue;
        }

        if (isString(className) || isNumber(className)) {
            classes = (
                classes !== ""
                    ? `${classes} ${className}`
                    : `${className}`
            )
        }
    }

    return classes;
}
// TODO: move to javaScriptTypes.js
export const isFunction = (param) => {
    return typeof param === "function";
}

/** Function to generate telegram link from phone number or username
     * @function
     * @param {string} contact
     * @returns {string}
 */
export const generateTelegramLink = contact => {
    if (!contact) return "";
    return `https://t.me/${contact}`;
}

/** Function to generate WhatsApp link from phone number or username
     * @function
     * @param {string} contact
     * @returns {string}
 */
export const generateWhatsAppLink = contact => {
    if (!contact) return "";
    return `https://wa.me/${contact}`;
}

/** Function to get global project name by global project id
     * @function
     * @param {array} companies
     * @param {string} global Project Id
     * @returns {string}
 */
export const getGlobalProjectData = (globalProjectId) => {
    const companies = store.getState().profile.userInfo.companies;

    for (const company of companies) {

        for (const project of company.projects) {
            if (project.id !== globalProjectId) {
                continue;
            }

            return {
                ...project
            }
        }

    }

    return null;
}

/** Function to get possible parents name by global project id
     * @function
     * @param {array} agentNames
     * @param {string} global Project Id
     * @param {array} companies
     * @returns {string}
 */
export const getPossibleParentsName = ({
    agentNames,
    globalProjectId,
}) => {
    const parentsNames = [...agentNames];

    const isAdmin = getUser()?.role === USER_ROLE.ADMIN;

    if (!isAdmin) {
        return parentsNames;
    }

    const globalProject = getGlobalProjectData(globalProjectId);

    if (globalProject) {
        parentsNames.unshift({
            id: globalProjectId,
            longId: globalProject.longId,
            name: globalProject.name
        });
    }

    return parentsNames;
}

/** Function to compare two objects and get the number of different propertis count
     * @function
     * @param {object} obj1
     * @param {object} obj2
     * @param {array} excludeFields
     * @returns {number}
 */
export const countDifferentProperties = (obj1, obj2, excludeFields=[]) => {

    const deepEqual = (val1, val2) => {

        if (val1 === null && val2 === "") {
            return true;
        }

        if (val1 === "" && val2 === null) {
            return true;
        }

        if (typeof val1 !== typeof val2) {
            return false;
        }

        if (val1 instanceof Date && val2 instanceof Date) {
            return val1.getTime() === val2.getTime();
        }

        if (typeof val1 !== 'object' || val1 === null) {
            if (typeof val1 === 'string') {
                return val1.trim() === val2.trim();
            }
            return val1 === val2;
        }

        if (Array.isArray(val1)) {
            if (!Array.isArray(val2) || val1.length !== val2.length) {
                return false;
            }

            for (let i = 0; i < val1.length; i++) {
                if (!deepEqual(val1[i], val2[i])) {
                    return false;
                }
            }

            return true;
        }

        const keys1 = Object.keys(val1);
        const keys2 = Object.keys(val2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(val1[key], val2[key])) {
                return false;
            }
        }

        return true;
    }

    let count = 0;

    for (let key in obj1) {
        if (excludeFields.includes(key)) {
            continue;
        }
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            if (!deepEqual(obj1[key], obj2[key])) {
                count++;
            }
        }
    }

    return count;
}

export const constructArrayForGivenRanges = ({ start, end }) => {
    if (!isNumber(start) || !isNumber(end)) {
        console.error('Invalid range format. Each range object should have start and end properties of type number.');
        return [];
    }

    if (start > end) {
        console.error('Invalid range. The start value should be less than or equal to the end value.');
        return [];
    }

    const constructedArray = [];

    for (let i = start; i <= end; i++) {
        constructedArray.push(i);
    }

    return constructedArray;
}

export const hasValue = (obj) => Object.keys(obj).length > 0;

/** function which print json pretty
     * @function
     * @param {string} str - string to pretify
     * @returns {string}
 */
export const prettyJson = str => {
    let result = ""
    try {
        const json = JSON.parse(str);
        result = JSON.stringify(json, null, 2);
    } catch (ex) {
        result = str;
    }
    return result;
}

export const openInNewTab = (url) => {
    window.open(url, '_blank');
}

export const isNonAnonymousBetsActivated = (globalProjectId) => {
    const globalProjectData = getGlobalProjectData(globalProjectId);

    return globalProjectData.betShopBettingType === BETSHOP_BET_TYPE.NON_ANONYMOUS;
}

export const debounce = (func, wait=1000, { before, after }={}) => {
    let timeoutId;

    return (...args) => {
        const context = this;
        if(before){
            before.apply(context, args);
        }
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            if(after){
                after.apply(context, args);
            }
            func.apply(context, args);
        }, wait);
    };
};

export const getProjectLogosPaths = (companies = [], projectId = "") => {
    const logoPaths = {
        [LOGO_TYPES.LOGO]: "",
        [LOGO_TYPES.COLLAPSED_LOGO]: "",
        [LOGO_TYPES.FAVICON]: ""
    }

    for(let companyData of companies) {
        for(let projectData of (companyData.projects ?? [])) {
            if (projectId === projectData.id) {
                projectData.logoPaths.forEach(logoData => {
                    logoPaths[logoData.type] = logoData.path
                });
                break
            }
        }
    }

    return logoPaths;
}

/** Function to get ranges for filters
     * @function
     * @param {boolean} - exclude last 3 Month
     * @returns {object} - ranges
 */
export const getFilterRanges = (disabledRanges = []) => {
    return dateService
            .getRanges()
            .filter(range => !disabledRanges.includes(range.label))
            .map(range => ({
                ...range,
                label: i18n.t(`backoffice.common.${range.label}`)
            }));
}

/** Trigger Custom Event*/

export const triggerCustomEvent = (eventName, data) => {
    const event = new CustomEvent(eventName, { detail: data });

    document.dispatchEvent(event);
}

/** Generate uid */
export const uid = () => {
    return Math.random().toString(36).substr(2, 9);
}

export const injectScript = (htmlString, targetElement) => {
    // Parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Find all scripts and links in the parsed document
    const scripts = Array.from(doc.querySelectorAll('script'));
    const links = Array.from(doc.querySelectorAll('link'));

    // Process and add each link tag
    links.forEach(link => {
        const newLink = document.createElement('link');

        // Copy all attributes from the original link
        Array.from(link.attributes).forEach(attr => {
            newLink.setAttribute(attr.name, attr.value);
        });

        // Append to document head (links typically go in the head)
        document.head.appendChild(newLink);
    });

    // Execute each script in order
    scripts.forEach(script => {
        const newScript = document.createElement('script');

        // Copy all attributes from the original script
        Array.from(script.attributes).forEach(attr => {
            newScript.setAttribute(attr.name, attr.value);
        });

        // Set the script content
        newScript.textContent = script.textContent;

        // Append to document to execute
        targetElement.appendChild(newScript);
    });
}
