import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Col, Form, message, Row, Switch } from "antd";

import Section from "components/common/section";
import Confirmation from 'components/common/confirmation';

import { updateAllTerminals } from '../../../api';
import useTerminalVersion from 'hooks/useTerminalVersion';

const VersionControl = ({ 
    hasModifyPermission,
    setIsSaving 
}) => {
    const { t } = useTranslation();

    const { version } = useTerminalVersion()

    const [showUpdateAllPopup, setShowUpdateAllPopup] = useState(false);

    const handleUpdateAll = () => {
        setIsSaving(true)
        updateAllTerminals({ updateVersion: version })
            .then(({ message: msg }) => {
                message.success(msg)
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    return (
        <Section title={t("backoffice.projects.versionControl")}>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <div className='rt--mb-8'>
                        <b className='rt--title rt--font-bold rt--font-small rt--pr-2'>{t('backoffice.projects.latestVersion')}:</b>
                        <span className='rt--title rt--font-regular rt--font-small'>{version}</span>
                    </div>
                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                        
                        <Form.Item
                            name="automaticUpdate"
                            valuePropName="checked"
                            className='rt--form-item-without-margin'
                        >
                            <Switch
                                disabled={!hasModifyPermission }
                            />
                        </Form.Item>
                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.projects.automaticUpdates')}</label>
                    </div>
                </Col>
                <Col span={24}>
                    <Button
                        type="secondary"
                        htmlType="button"
                        className="rt--button rt--button-secondary rt--mt-16"
                        disabled={!hasModifyPermission}
                        onClick={() => {
                            setShowUpdateAllPopup(true)
                        }}
                    >
                        { t("backoffice.projects.updateAllTerminals") }
                    </Button>
                </Col>
            </Row>
            {
                showUpdateAllPopup && (
                    <Confirmation
                        title={t('backoffice.projects.updateAllTerminals')}
                        message={t('backoffice.projects.updateAllTerminalsMessage')}
                        onOk={handleUpdateAll}
                        onCancel={() => setShowUpdateAllPopup(false)}
                        isVisible={true}
                        okText={t('backoffice.projects.update')}
                    />
                )
            }
        </Section>
    );
};

export default VersionControl;
