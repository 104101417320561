import React, {useCallback, useMemo, useState} from 'react';
import {LEADERBOARD_TYPE} from "components/common/leaderboard/constants";
import Leaderboard from "components/common/leaderboard";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useOperationalLeaderboard from "pages/dashboard/hooks/useOperationalLeaderboard";
import useFirstRender from "hooks/useFirstRender";
import {FINANCIAL_DASHBOARD_FILTER_NAMES, OPERATIONAL_FAKE_VALUES} from "pages/dashboard/constants";

const UsersLeaderboardOperational = ({ title, filters, updateLastUpdatedDate, hasOneCurrency }) => {
    const mappedFilters = useMemo(() => {
        const res = {
            ...filters,
            entityType: Number(Object.entries(OPERATIONAL_FAKE_VALUES).find(([_, value]) => value === filters.dataType)[0]),
        }

        delete res.dataType;

        return res;
    }, [filters]);

    const { leaderBoardData, isLoading } = useOperationalLeaderboard(mappedFilters, updateLastUpdatedDate);

    return (
        <Leaderboard
            leaders={leaderBoardData}
            isLoading={isLoading}
            title={title}
            currencyCode={filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]}
            type={LEADERBOARD_TYPE.OPERATIONAL}
            hasOneCurrency={hasOneCurrency}
        />
    );
};

export default UsersLeaderboardOperational;
