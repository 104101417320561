//#region react
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import {Button, message} from "antd";
//#endregion

//#region actions
import {
    getPlayers,
    setPlayersSorting,
    setPlayersFilters,
    updatePlayerData,
    resetPlayers
} from "store/actions/dashboard/players/players.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import ReparentComponent from "components/common/reparent";
import Filters from "./filters.component";
import PlayerCreateComponent from "./player-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
import useProjectType from "hooks/useProjectType";
import { TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME, useTransfer } from "components/common/transfer";
//#endregion

//#region utils
import { isMobile } from 'utils/common';
import { getUser } from 'utils/auth';
import { hasPlayerNonDirectTransactionAccess, hasPlayerReparentAccess } from "utils/access";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { USER_ROLE, USER_STATE } from "constants/user.constants";
import {PLAYER_STATE, PLAYERS_LITE_DEFAULT_COLUMNS} from "constants/player.constants"
import { REPARENT_ENTITY_TYPE } from 'constants/agent.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { PLAYERS, PLAYERS_LITE } from "constants/pageName.constants";
import { FILTER_PLAYER_STATE } from "constants/filter.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import playerType from "types/player/player.type";
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
//#endregion

import emptyPlayersImg from "assets/images/emptyPlayers.svg"
import {resendAgent} from "pages/players/api";

const transferToPlayerIconEnabled = ({ playerData, userInfo }) => {

    if (playerData.state !== PLAYER_STATE.ACTIVE) {
        return false;
    }

    if (getUser()?.role !== USER_ROLE.ADMIN) {
        return (
            hasPlayerNonDirectTransactionAccess(userInfo) ||
            playerData.parentId === userInfo.longId
        )
    }

    return true;
}

const PlayersComponent = ({
    getPlayers,
    players,
    isLoading,
    total,
    setPlayersSorting,
    setPlayersFilters,
    sorting,
    filters,
    globalCompanyId,
    globalProjectId,
    userInfo,
    updatePlayerData,
    resetPlayers
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isEmptyData, setIsEmptyData] = useState(true);

    const { role, userState } = getUser();

    const [createPlayerPopupVisible, setCreatePlayerPopupVisible] = useState(false);
    const [reparentPopupVisible, setReparentPopupVisible] = useState(false);
    const [resendAgentLoading, setResendAgentLoading] = useState(false);

    const { formatAmount } = useFormat();

    const { show: showTransfer} = useTransfer()

    const { hasStandartPayment, hasLiteMode } = useProjectType();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({
        pageName: hasLiteMode ? PLAYERS_LITE : PLAYERS,
        defaultIncludedColumns: hasLiteMode ? PLAYERS_LITE_DEFAULT_COLUMNS : undefined
    });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER,
        action: PERMISSION_ACTION.EXPORT
    });

    const hasPlayerCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER,
        action: PERMISSION_ACTION.CREATE
    });

    const hasPlayerModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER,
        action: PERMISSION_ACTION.MODIFY
    });

    const hasPlayerGeneralInfoViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER_GENERALINFO,
        action: PERMISSION_ACTION.VIEW
    })

    const hasPlayerOnlineBetsViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE,
        action: PERMISSION_ACTION.VIEW
    })

    const hasPlayerResendAgentPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PLAYER_RESEND_AGENT,
        action: PERMISSION_ACTION.MODIFY
    });

    //#endregion

    const [checkedRows, setCheckedRows] = useState([]);
    const [showCheckBoxes, setShowCheckBoxes] = useState(!isMobile() && userState !== USER_STATE.BLOCKED && hasPlayerModifyPermission)

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item, eventType) => {
        let playerUsername = "";
        let playerId = "";
        if(value){
            if(item && eventType === "select"){
                playerId = item.id
            } else {
                playerUsername = value
            }
        }
        setPlayersFilters({
            ...filters,
            from: "",
			to: "",
            modifiedFrom: "",
			modifiedTo: "",
            state: FILTER_PLAYER_STATE.ALL,
			email: "",
			parentUserNameOrId: "",
			currencies: [],
            externalId: "",
            playerUsername: playerUsername,
            playerId: playerId
        })

        if(playerId || playerUsername){
            setIsEmptyData(false);
            if(!isEmptyData){
                getPlayers();
            }
        } else {
            setIsEmptyData(true)
        }
    }

    const showPlayerCreatePopup = () => {
        setCreatePlayerPopupVisible(true)
    }

    const hidePlayerCreatePopup = () => {
        setCreatePlayerPopupVisible(false)
    }

    const showPlayerTransferPopup = (record, group) => {
        const { id, currencyCode, balance, withdrawableBalance, userName } = record;
        const transferData = {
            userId: id,
            entityType: TRANSFER_ENTITY_TYPE.PLAYER,
            currencyCode: currencyCode,
            additionalData: {
                balance,
                withdrawableBalance,
                userName
            },
        }

        if(group){
            transferData.group = group;
        }

        showTransfer(transferData)
    }

    const handleReloadIconClick = (record) => {
        updatePlayerData(record.id)
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.PLAYERS_EDIT}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}`
        )
    }

    const handleTableCheckboxChange = value => {
        setCheckedRows(value)
    }

    const handleResendAgent = () => {
        setResendAgentLoading(true);

        resendAgent(checkedRows)
            .then(() => {
                setCheckedRows([]);
                getPlayers();

                message.success(t("backoffice.players.parentIdSyncedSuccessfully"));
            })
            .finally(() => {
                setResendAgentLoading(false);
            });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns: includedColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                userInfo,
                formatAmount,
                hasLiteMode,
                transferToPlayerIconEnabled,
                showPlayerTransferPopup,
                hasPlayerGeneralInfoViewPermission
            }
        })
    }, [includedColumns, formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasPlayerGeneralInfoViewPermission) {
        tableRowActions.push({
            title: t('backoffice.common.refresh'),
            icon: "icon-reload",
            onClick: handleReloadIconClick,
        })

        if (!hasLiteMode && hasStandartPayment) {
            tableRowActions.push({
                title: t("backoffice.agents.transfer"),
                icon: "icon-transfer",
                onClick: showPlayerTransferPopup,
                disabled: (
                    record => !transferToPlayerIconEnabled({ playerData: record, userInfo })
                ),
            })
        }
    }

    if (hasPlayerGeneralInfoViewPermission || hasPlayerOnlineBetsViewPermission) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: (
            <Filters
                isEmptyData={isEmptyData}
                setIsEmptyData={setIsEmptyData}
                disabled={Boolean(filters.playerUsername || filters.playerId)}
            />
        ),
        search: {
            onSearch: handleSearchChange,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                autoGet: false,
                searchFieldName: "userNameOrId"
            },
            placeholder: t("backoffice.players.userNameOrId")
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [
            // {
            //     icon: "icon-plus",
            //     type: "primary",
            //     onClick: showPlayerCreatePopup,
            //     text: t("backoffice.players.createPlayer"),
            //     enabled: hasPlayerCreatePermission
            // },

            {
                type: "ghost",
                className: "rt--button-ghost",
                onClick: () => {
                    setCheckedRows([]);
                    setShowCheckBoxes(!showCheckBoxes)
                },
                text: showCheckBoxes ? t("backoffice.common.deselect") : t("backoffice.common.select"),
                enabled: isMobile() &&  userState !== USER_STATE.BLOCKED,
            }
        ],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.players') }]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.players"),
            url: ApiUrls.EXPORT_PLAYERS,
            filters: filters,
            disabled: isEmptyData
        }
    }

    const reparentButtonContent = (
        <div className={"rt--flex rt--align-center" + (isMobile() ? " rt--justify-between": "")}>
            <span
                className={"rt--text-secondary " + (isMobile() ? " rt--font-normal rt--font-bold" : " rt--pl-16 rt--pr-16 rt--font-big")}
            >
                {
                    `${checkedRows.length} ${t("backoffice.common.selected")}`
                }
            </span>
            <Button
                type="primary"
                className={"rt--button" + (isMobile() ? "" : " rt--button-main rt--button-main-without-icon")}
                onClick={() => setReparentPopupVisible(true)}
            >
                {t("backoffice.common.reparent")}
            </Button>
            {hasPlayerResendAgentPermission && filters.parentUserNameOrId && (
                <Button
                    type="primary"
                    className={"rt--button rt--ml-16" + (isMobile() ? "" : " rt--button-main rt--button-main-without-icon")}
                    loading={resendAgentLoading}
                    onClick={handleResendAgent}
                >
                    {t("backoffice.players.syncParentId")}
                </Button>
            )}
        </div>
    )

    if(!isMobile()){
        if (checkedRows.length > 0) {
            headerPartsData.leftContent = reparentButtonContent
        }
    }

    useEffect(() => {
        setIsEmptyData(true)
    }, [globalProjectId])

    useEffect(() => {
        if(isEmptyData){
            resetPlayers()
        }
    }, [isEmptyData])


    /** Reset checked rows on filters, pagination, globalProjectId change */
    const resetCheckboxesFields = [filters, globalProjectId].concat(!isMobile() ? [sorting.page] : [])

    useEffect(() => {
        setCheckedRows([]);
    }, resetCheckboxesFields)


    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    data.entityType === TRANSFER_ENTITY_TYPE.PLAYER && !isEmptyData
                ){
                    getPlayers();
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [isEmptyData])

    return (
        <MainDashboardLayout
            header={headerPartsData}
            extraFooter={checkedRows.length > 0 ? reparentButtonContent : null }
        >
            {
                !isEmptyData ? (
                    <Table
                        loading={isLoading}
                        columns={mainTableColumns}
                        data={players}
                        loadFn={getPlayers}
                        sorting={sorting}
                        setSortingFn={setPlayersSorting}
                        total={total}
                        //updateProps={[globalProjectId]}
                        actions={tableRowActions}
                        chooser={showCheckBoxes ? {
                            type: "checkbox",
                            valueIndex: "id",
                            value: checkedRows,
                            onChange: handleTableCheckboxChange,
                            getCheckboxProps: (record) => {
                                return {
                                    disabled: !hasPlayerReparentAccess(userInfo, record.parentId),
                                }
                            }
                        } : undefined}
                    />
                ) : (
                    <div className="rt--flex rt--flex-col rt--align-center rt--justify-center rt--height-full ">
                        <div className="rt--players-empty rt--flex rt--flex-col rt--align-center rt--justify-center ">
                            <img src={emptyPlayersImg} alt="" />
                            <span className="rt--title rt--font-big rt--font-bold">{t("backoffice.players.emptyText")}</span>
                        </div>
                    </div>
                )
            }


            {createPlayerPopupVisible && (
                <PlayerCreateComponent onClose={hidePlayerCreatePopup} />
            )}

            {
                reparentPopupVisible && (
                    <ReparentComponent
                        onSuccess={() => setCheckedRows([])}
                        onClose={() => setReparentPopupVisible(false)}
                        type={REPARENT_ENTITY_TYPE.PLAYER}
                        ids={checkedRows}
                        multiple
                    />
                )
            }

        </MainDashboardLayout>
    )
};

/** PlayersComponent propTypes
 * PropTypes
*/
PlayersComponent.propTypes = {
    /** Redux action to get players */
    getPlayers: PropTypes.func,
    /** Redux state property, represents the array of players  */
    players: PropTypes.arrayOf(playerType),
    /** Redux state property, is true when loading players */
    isLoading: PropTypes.bool,
    /** Redux state property, players total count */
    total: PropTypes.number,
    /** Redux action to set players sorting details */
    setPlayersSorting: PropTypes.func,
    /** Redux action to set players sorting details */
    setPlayersFilters: PropTypes.func,
    /** Redux state property, players sorting details */
    sorting: sortingType,
    /** Redux state property, players filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to update player data */
    updatePlayerData: PropTypes.func,
    /** Redux action to reset players */
    resetPlayers: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getPlayers: nextPage => {
        dispatch(getPlayers(nextPage));
    },
    setPlayersSorting: sorting => {
        dispatch(setPlayersSorting(sorting));
    },
    setPlayersFilters: filters => {
        dispatch(setPlayersFilters(filters));
    },
    updatePlayerData: id => {
        dispatch(updatePlayerData(id));
    },
    resetPlayers: () => {
        dispatch(resetPlayers());
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.players.isLoading,
        players: state.players.players,
        total: state.players.total,
        sorting: state.players.sorting,
        filters: state.players.filters,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayersComponent);
