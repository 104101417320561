//#region react
import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region actions
import {
    getAggregatedBetHistory,
    setAggregatedBetHistorySorting,
    setAggregatedBetHistoryFilters,
    getAggregatedBetTotals
} from 'store/actions/dashboard/betHistory/aggregated.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import TotalsComponent from 'components/common/totals';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
import useDate from 'hooks/useDate';
//#endregion

//#region utils
import { isMobile } from "utils/common";
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import { AGGREGATED_BETS } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import aggregatedBetType from 'types/bet/aggregatedBet.type';
import betsTotalsType from 'types/bet/betsTotals.type';
import sortingType from 'types/common/sorting.type';
import useUserCurrencies from "hooks/useUserCurrencies";
//#endregion

/** Online Aggregated Bet History Component */
const AggregatedBetHistoryComponent = ({
    bets,
    sorting,
    filters,
    total,
    isLoading,
    isTotalsLoading,
    totals,
    getAggregatedBetHistory,
    setAggregatedBetHistorySorting,
    setAggregatedBetHistoryFilters,
    getAggregatedBetTotals,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AGGREGATED_BETS });

    const isMobileDevice = isMobile();

    const { formatAmount } = useFormat();

    const { dateService } = useDate();

    const [ userCurrencies, _, hasOneCurrency ] = useUserCurrencies(false);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasTotalsViewPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE_TOTALS,
        action: PERMISSION_ACTION.VIEW
    });

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setAggregatedBetHistoryFilters({
            ...filters,
            playerUserNameOrId: value ? item ? item.id : value : ""
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                formatAmount,
                dateService,
                hasOneCurrency
            }
        })
    }, [includedColumns, formatAmount, dateService, hasOneCurrency]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters userCurrencies={userCurrencies} hasOneCurrency={hasOneCurrency} />,
        search: {
            placeholder: t("backoffice.bets.playerUsernameOrId"),
            loadFn: getAggregatedBetHistory,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                autoGet: false,
                searchFieldName: "userNameOrId"
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.bets.aggregated"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_AGGREGATED_BET_HISTORY,
            filters: filters
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobileDevice && hasTotalsViewPermission}
            extraContent={hasTotalsViewPermission && (
                <TotalsComponent
                    isLoading={isTotalsLoading}
                    getTotals={getAggregatedBetTotals}
                    totals={totals}
                    currencyCode={filters.currencyCode}
                    dependencies={[globalProjectId, filters]}
                    totalType={REPORT_TOTAL_TYPE.BET_HISTORY}
                />
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={bets}
                loadFn={getAggregatedBetHistory}
                sorting={sorting}
                setSortingFn={setAggregatedBetHistorySorting}
                total={total}
                updateProps={[globalProjectId]}
            />
        </TabTableDashboardLayout>
    )
}

/** AggregatedBetHistoryComponent propTypes
    * PropTypes
*/
AggregatedBetHistoryComponent.propTypes = {
    /** Redux state property, represents the array of online aggregated bet history */
    bets: PropTypes.arrayOf(aggregatedBetType),
    /** Redux state property, online aggregated bet history sorting details */
    sorting: sortingType,
    /** Redux state property, online aggregated bet history filters */
    filters: PropTypes.object,
    /** Redux state property, online aggregated bet history total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading bet history */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading bet history totals */
    isTotalsLoading: PropTypes.bool,
    /** Redux action to get aggregated bet history */
    getAggregatedBetHistory: PropTypes.func,
    /** Redux action to set aggregated bet history sorting details */
    setAggregatedBetHistorySorting: PropTypes.func,
    /** Redux action to set aggregated bet history filters */
    setAggregatedBetHistoryFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, aggregated bets totals */
    totals: betsTotalsType,
    /** Redux action to get aggregated bets totals */
    getAggregatedBetTotals: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAggregatedBetHistory: nextPage => {
            dispatch(getAggregatedBetHistory(nextPage))
        },
        setAggregatedBetHistorySorting: sorting => {
            dispatch(setAggregatedBetHistorySorting(sorting));
        },
        setAggregatedBetHistoryFilters: filters => {
            dispatch(setAggregatedBetHistoryFilters(filters));
        },
        getAggregatedBetTotals: () => {
            dispatch(getAggregatedBetTotals())
        }
    }
)

const mapStateToProps = state => {
    return {
        bets: state.betHistory.online.aggregated.bets,
        total: state.betHistory.online.aggregated.total,
        sorting: state.betHistory.online.aggregated.sorting,
        filters: state.betHistory.online.aggregated.filters,
        totals: state.betHistory.online.aggregated.totals.data,
        isLoading: state.betHistory.online.aggregated.loading,
        isTotalsLoading: state.betHistory.online.aggregated.totals.loading,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AggregatedBetHistoryComponent)
