//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

//#endregion

//#region libraries
import { Row, Col, Form } from 'antd';
//#endregion

//#region actions
import {
    getPlayerPerformanceReport,
    setPlayerPerformanceReportFilters
} from 'store/actions/dashboard/reports/playerPerformanceReport.action';

//#endregion

//#region components
import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import AutoComplete from "components/common/autoComplete";
import DateRangePicker from "components/common/dateRangePicker";
//#endregion

//#region hooks
import useAutosuggestion from 'hooks/useAutosuggestion';
//#endregion

//#region utils
import { getCurrencyFieldOptions } from 'utils/currency';
import { getPossibleParentsName } from "utils/common";
import { getUser } from 'utils/auth';
//#endregion

//#region constants
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';
import { FILTER_TYPE } from 'constants/common.constants';
import { USER_ROLE } from 'constants/user.constants';
//#endregion

//#region types
import userInfoType from 'types/profile/userInfo.type';
import useProjectType from "hooks/useProjectType";
//#endregion

const FILTERS_FIELD_NAMES = {
    DATE: "date",
    CURRENCIES: "currencies",
    REGISTERRED_PERIOD: "registerredPeriod",
    BELONGS_TO: "belongsTo",
    FILTER_TYPE: "filterType"
}

/** Player Performance Report Page Filters Component */
const Filters = ({
    userCurrencies,
    hasOneCurrency,
    globalProjectId,
    filters,
    getPlayerPerformanceReport,
    setPlayerPerformanceReportFilters,
    userInfo
}) => {
    const { t } = useTranslation();

    const currentUserId = getUser()?.role === USER_ROLE.ADMIN ? globalProjectId : userInfo.id;

    const [ agentNames, getAgentNames] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: { excludeAgent: false },
        autoGet: false
    });

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const belongsToFieldOptions = useMemo(() => {
        const options = getPossibleParentsName({
            globalProjectId,
            agentNames,
        })

        return options.map(o => ({
            value: o.id === currentUserId ? null : o.id, // for current user send null
            text: o.name
        }))
    }, [globalProjectId, agentNames, currentUserId])

    return (
        <FiltersWrapper
            loadFn={getPlayerPerformanceReport}
            setFiltersFn={setPlayerPerformanceReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            searchFieldName="userNameOrId"
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: true },
                { name: FILTERS_FIELD_NAMES.REGISTERRED_PERIOD, time: false },
            ]}
            onInit={() => {
                getAgentNames({
                    excludeAgent: false
                });
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker
                            showTime={true}
                            timePickerMode={TIME_PICKER_MODES.ONLY_HOURS}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                {!hasOneCurrency && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.reports.currency')}
                            name={FILTERS_FIELD_NAMES.CURRENCIES}
                        >
                            <Select
                                options={currencyFieldOptions}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                isMultiple={true}
                            />
                        </Form.Item>
                    </Col>
                )}

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.performancereport.registrationDate')}
                        name={FILTERS_FIELD_NAMES.REGISTERRED_PERIOD}
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.performancereport.belongsTo')}
                        name={FILTERS_FIELD_NAMES.BELONGS_TO}
                    >
                        <Select
                            options={belongsToFieldOptions}
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.performancereport.belongsTo')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.performancereport.type')}
                        name={FILTERS_FIELD_NAMES.FILTER_TYPE}
                        className="rt--form-item-without-margin"
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.performancereport.type')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: FILTER_TYPE.DIRECT, text: t('backoffice.performancereport.direct') },
                                { value: FILTER_TYPE.NETWORK, text: t('backoffice.performancereport.network') },
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux state property, player performance report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to set player performance report filters */
    setPlayerPerformanceReportFilters: PropTypes.func,
    /** Redux action to get player performance report */
    getPlayerPerformanceReport: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerPerformanceReport: () => {
            dispatch(getPlayerPerformanceReport());
        },
        setPlayerPerformanceReportFilters: filters => {
            dispatch(setPlayerPerformanceReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.playerPerformanceReport.filters,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
