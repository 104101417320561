import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getTerminalSettings = () => {
    return axios({
        url: `${ApiUrls.GET_PROJECT_TERMINAL_SETTINGS}`,
        method: Methods.GET,
    }).then(({data: {value}}) => {
        return value
    });
}

export const saveTerminalSettings = data => {
    return axios({
        url: `${ApiUrls.SAVE_PROJECT_TERMINAL_SETTINGS}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return { value, message };
    });
}

export const getVouchersConfigs = () => {
    return axios({
        url: `${ApiUrls.GET_PROJECT_VOUCHERS_CONFIGS}`,
        method: Methods.GET,
    }).then(({ data: {value} }) => {
        return value
    });
}

export const saveVouchersConfigs = data => {
    return axios({
        url: `${ApiUrls.SAVE_PROJECT_VOUCHERS_CONFIGS}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return {value, message};
    });
}

export const getTerminalInfo = () => {
    return axios({
        url: `${ApiUrls.GET_TERMINAL_INFO}`,
        method: Methods.GET,
    }).then(({data: {value}}) => {
        return value
    });
}

export const getWorkingHoursConfigs = () => {
    return axios({
        url: `${ApiUrls.GET_PROJECT_TERMINAL_WORKING_HOURS}`,
        method: Methods.GET,
    }).then(({ data: {value} }) => {
        return value
    });
}

export const saveWorkingHoursConfigs = data => {
    return axios({
        url: `${ApiUrls.SAVE_PROJECT_TERMINAL_WORKING_HOURS}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return {value, message};
    });
}

export const updateAllTerminals = data => {
    return axios({
        url: `${ApiUrls.UPDATE_ALL_TERMINALS}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return { value, message };
    });
}