import React, {useCallback, useEffect, useMemo} from 'react';
import { useTranslation } from "react-i18next";
import { Row, Tabs as AntTabs } from "antd";
import TabDashboardLayout from "components/layouts/tab";
import { isMobile } from "utils/common";
import LastUpdated from "pages/dashboard/components/lastUpdated";
import UsersTab from "pages/dashboard/tabs/usersTab";
import dateService from "NEW/src/core/services/dateService";
import { DASHBOARD_FILTERS, DASHBOARD_TAB_NAMES } from "pages/dashboard/tabs/constants";
import StatisticsTab from "pages/dashboard/tabs/statisticsTab";
import useProjectType from "hooks/useProjectType";
import useDashboardState from "pages/dashboard/hooks/useDashboardState";
import {withDashboardContextProvider} from "pages/dashboard/contexts/dashboardContext";
import {useSelector} from "react-redux";
import PATHS from "constants/path.constants";
import {useNavigate} from "react-router";
import useUserCurrencies from "hooks/useUserCurrencies";

const FiltersComponent = ({ activeTab, currentFilters, handleFiltersChange, currencyOptions, hasOneCurrency }) => {
    const Filters = useMemo(() => DASHBOARD_FILTERS[activeTab], [activeTab]);

    return (
        <Filters
            filters={currentFilters}
            onChange={handleFiltersChange(activeTab)}
            currencyOptions={currencyOptions}
            hasOneCurrency={hasOneCurrency}
        />
    );
};

const DashboardComponent = () => {
    const [currencies, _, hasOneCurrency] = useUserCurrencies(false);

    const { hasRetail, hasStandartPayment } = useProjectType();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const currencyOptions = useMemo(() => currencies.map(currency => ({
        text: currency.currencyCode.toUpperCase(),
        value: currency.currencyCode.toUpperCase()
    })), [currencies]);

    const {
        lastUpdatedDate,
        setLastUpdatedDate,
        activeTab,
        setActiveTab,
        filters,
        setFilters
    } = useDashboardState(hasRetail, currencies);

    const currentFilters = useMemo(() => filters[activeTab], [filters, activeTab]);

    useEffect(() => {
        if(!hasStandartPayment){
            navigate(PATHS.DEFAULT);
        }
    }, [hasStandartPayment, navigate])

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (prevState === null && !!newDate) {
                return newDate;
            }

            return prevState;
        });
    }, []);

    const handleTabChange = useCallback((key) => {
        setActiveTab(key);
    }, []);

    const handleFiltersChange = (source) => (scope, values) => {
        setFilters(state => {
            if (scope) {
                return {
                    ...state,
                    [source]: {
                        ...state[source],
                        [scope]: {
                            ...state[source][scope],
                            ...values
                        }
                    }
                }
            }

            return {
                ...state,
                [source]: {
                    ...(Object.keys(values).reduce((acc, scope) => ({
                        ...acc,
                        [scope]: Object.keys(values[scope]).reduce((acc, key) => ({
                            ...acc,
                            [key]: values[scope][key]
                        }), { ...(state[source][scope] ?? {}) })
                    }), { ...state[source] }))
                }
            }
        });
    };

    const tabs = useMemo(() => ([
        {
            key: DASHBOARD_TAB_NAMES.STATISTICS,
            label: t('backoffice.dashboard.statistics'),
            children: (
                <StatisticsTab
                    filters={currentFilters}
                    updateLastUpdatedDate={updateLastUpdatedDate}
                    hasOneCurrency={hasOneCurrency}
                />
            )
        },
        {
            key: DASHBOARD_TAB_NAMES.USERS,
            label: t('backoffice.dashboard.users'),
            children: (
                <UsersTab
                    filters={currentFilters}
                    onFiltersChange={handleFiltersChange(DASHBOARD_TAB_NAMES.USERS)}
                    updateLastUpdatedDate={updateLastUpdatedDate}
                    currencyOptions={currencyOptions}
                    hasOneCurrency={hasOneCurrency}
                />
            )
        }
    ]), [updateLastUpdatedDate, currentFilters, currencyOptions]);

    return (
        <TabDashboardLayout
            header={{
                extra: isMobile() && (
                    <>
                        <LastUpdated lastUpdatedDate={lastUpdatedDate} />

                        <FiltersComponent
                            activeTab={activeTab}
                            currentFilters={currentFilters}
                            handleFiltersChange={handleFiltersChange}
                            currencyOptions={currencyOptions}
                            hasOneCurrency={hasOneCurrency}
                        />
                    </>
                )
            }}
            breadcrumbs={{
                items: [{ title: t('backoffice.menu.dashboard') }]
            }}
        >
            <AntTabs
                animated={false}
                destroyInactiveTabPane={true}
                onChange={handleTabChange}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    <Row>
                        {!isMobile() && (
                            <>
                                <LastUpdated lastUpdatedDate={lastUpdatedDate} />

                                <FiltersComponent
                                    activeTab={activeTab}
                                    currentFilters={currentFilters}
                                    handleFiltersChange={handleFiltersChange}
                                    currencyOptions={currencyOptions}
                                    hasOneCurrency={hasOneCurrency}
                                />
                            </>
                        )}
                    </Row>
                }
                items={tabs}
            />
        </TabDashboardLayout>
    );
};

export default withDashboardContextProvider(DashboardComponent);
