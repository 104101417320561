import React, {useMemo} from 'react';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import {ENTITY_TYPE, TIME_PERIOD} from "pages/dashboard/constants";
import {FILTER_TYPE} from "constants/common.constants";
import {useTranslation} from "react-i18next";
import useProjectType from "hooks/useProjectType";
import {Form} from "antd";
import {isFranchisingMode} from "utils/common";
import useAutosuggestion from "hooks/useAutosuggestion";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const StatisticsFiltersMobile = ({ filters, onChange, currencyOptions, hasOneCurrency }) => {
    const { t } = useTranslation();
    const { hasAgentSystem } = useProjectType();

    const [ betshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: isFranchisingMode()
    });

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!filters.betShopNameOrId;

    const betShopOptions = useMemo(() => ([
        {
            text: t('backoffice.dashboard.allBetshops'),
            value: ''
        },
        ...betshopNames.map(betshop => ({
            text: betshop.name,
            value: betshop.id
        }))
    ]), [betshopNames, t]);

    const handleFilterChange = (values) => {
        onChange(values);
    }

    if (!filters.currencyCode) {
        return null;
    }

    return (
        <FiltersWrapper
            loadFn={() => { }}
            setFiltersFn={handleFilterChange}
            updateProps={[]}
            filters={filters}
        >

            {isFranchisingMode() && (
                <Form.Item
                    name="betShopNameOrId"
                    label={t("backoffice.dashboard.betShop")}
                >
                    <Select
                        placeholder={t('backoffice.dashboard.allBetshops')}
                        options={betShopOptions}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        search
                    />
                </Form.Item>
            )}

            {!hasOneCurrency && (
                <Form.Item
                    name="currencyCode"
                    label={t("backoffice.dashboard.currency")}
                >
                    <Select
                        options={[
                            ...currencyOptions
                        ]}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    />

                </Form.Item>
            )}

            {hasAgentSystem && !isBetShopOwner && !hasSelectedBetShop && (
                <Form.Item
                    name="filterType"
                    label={t("backoffice.dashboard.type")}
                >
                    <Select
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        options={[
                            {value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct")},
                            {value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network")},
                        ]}
                    />
                </Form.Item>
            )}

            {hasAgentSystem && (
                <Form.Item
                    name="timePeriod"
                    label={t("backoffice.dashboard.date")}
                >
                    <Select
                        options={
                            [
                                {value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today")},
                                {value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday")},
                                {value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek")},
                                {value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek")},
                                {value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth")},
                                {value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth")},
                                {value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter")},
                                {value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd")},
                            ]
                        }
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    />
                </Form.Item>
            )}
        </FiltersWrapper>
    );
};

export default StatisticsFiltersMobile;
