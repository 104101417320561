import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";
import NumericInput from 'components/common/numericInput';

import { setOnlineBetHistoryFilters, getOnlineBetHistory } from "store/actions/dashboard/betHistory/online.action";

import {BETSLIP_FILTER_TYPE, FILTER_BET_STATE} from "constants/filter.constants";
import { BET_TYPE, BETTING_TYPE } from "constants/bet.constants";
import {DATE_PICKER_RANGES, FILTER_TYPE} from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { USER_ROLE } from 'constants/user.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { makeBetStateText } from "utils/bet";
import { getCurrencyFieldOptions } from 'utils/currency';
import { getPossibleParentsName } from 'utils/common';
import { getUser } from 'utils/auth';

import userInfoType from 'types/profile/userInfo.type';

/** Online Bets Page Filters Component */

const Filters = ({
    hasOneCurrency,
    userCurrencies,
    setOnlineBetHistoryFilters,
    getOnlineBetHistory,
    filters,
    globalProjectId,
    userInfo
}) => {
    const { t } = useTranslation();

    const currentUserId = getUser()?.role === USER_ROLE.ADMIN ? globalProjectId : userInfo.id;

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: false
    });

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const belongsToFieldOptions = useMemo(() => {
        const options = getPossibleParentsName({
            globalProjectId,
            agentNames,
        })
        return options.map(o => ({
            value: o.id === currentUserId ? null : o.id, // for current user send null
            text: o.name
        }))
    }, [globalProjectId, agentNames, currentUserId])

    const statusOptions = useMemo(() => {
        const options = Object.keys(FILTER_BET_STATE).map(k => (
            {
                value: FILTER_BET_STATE[k],
                text: FILTER_BET_STATE[k] !== "" ? makeBetStateText(FILTER_BET_STATE[k]) : t("backoffice.common.all")
            }
        ));

        options.push({
            value: "cashed_out",
            text: t("backoffice.bets.cashedOut")
        });

        return options;
    }, [t]);

    const dateTypeOptions = useMemo(() => {
        return [
            {
                text: t('backoffice.bets.betDate'),
                value: BETSLIP_FILTER_TYPE.BET_TIME
            },
            {
                text: t('backoffice.bets.calculationDate'),
                value: BETSLIP_FILTER_TYPE.CALCULATION_TIME
            },
        ]
    }, []);


    return (
        <FiltersWrapper
            loadFn={getOnlineBetHistory}
            setFiltersFn={setOnlineBetHistoryFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true }
            ]}
            searchFieldName="playerUserNameOrId"
            onInit={() => {
                getAgentNames({
                    excludeAgent: false
                });
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.dateType')}
                        name="betSlipFilterType"
                    >
                        <Select options={dateTypeOptions}/>
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            disabledRanges={[DATE_PICKER_RANGES.YTD, DATE_PICKER_RANGES.LAST_YEAR]}
                        />
                    </Form.Item>
                </Col>
                {
                    !hasOneCurrency && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.bets.currency')}
                                name="currencyCode"
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.currency')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    options={[
                                        { value: "", text: t("backoffice.common.all") },
                                        ...currencyFieldOptions
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }


                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.searchByBetslipId")}
                        name="betSlipId"
                    >
                        <NumericInput
                            maxLength={12}
                            disableFormatting
                            placeholder={`${t('backoffice.common.enter')} ${t("backoffice.bets.betslipId")}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.status")}
                        name="status"
                    >
                        <Select
                            options={statusOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.type")}
                        name="type"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    { value: BET_TYPE.SINGLE, text: t("backoffice.bets.single") },
                                    { value: BET_TYPE.MULTI, text: t("backoffice.bets.multi") },
                                    { value: BET_TYPE.SYSTEM, text: t("backoffice.bets.system") }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.type')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.bettingType")}
                        name="bettingType"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    { value: BETTING_TYPE.PREMATCH, text: t("backoffice.common.preMatch") },
                                    { value: BETTING_TYPE.LIVE, text: t("backoffice.common.live") },
                                    { value: BETTING_TYPE.KOMBI, text: t("backoffice.common.kombi") }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.bettingType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.performancereport.belongsTo')}
                        name="belongsTo"
                    >
                        <Select
                            options={belongsToFieldOptions}
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.performancereport.belongsTo')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.bets.filterType')}
                        name="filterType"
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.filterType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={
                                [
                                    { value: FILTER_TYPE.NETWORK, text: t('backoffice.bets.network') },
                                    { value: FILTER_TYPE.DIRECT, text: t('backoffice.bets.direct') }
                                ]
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get online bet histpry */
    getOnlineBetHistory: PropTypes.func,
    /** Redux action to set online bet history filters */
    setOnlineBetHistoryFilters: PropTypes.func,
    /** Redux state property, online bet history filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType,
}

const mapDispatchToProps = dispatch => (
    {
        getOnlineBetHistory: () => {
            dispatch(getOnlineBetHistory())
        },
        setOnlineBetHistoryFilters: filters => {
            dispatch(setOnlineBetHistoryFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.betHistory.online.sport.filters,
        userInfo: state.profile.userInfo,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
