import React from 'react';

import {Button, Switch} from "antd";
import Tooltip from "components/common/tooltip";
import EditableCell from './columns/columnCellModes/editableCell';
import CommonActions from "./commonActions";
import ColumnWithCopyButton from "./columns/columnCellModes/columnWithCopyButton";
import DateTimeColumn from "./columns/columnCellModes/dateTimeColumn";
import TitleWithEditIcon from "./columns/columnTitleModes/titleWithEditICon";
import TitleWithSortingIcon from './columns/columnTitleModes/titleWithSortingIcon';
import TitleWithTooltip from './columns/columnTitleModes/titleWithTooltip';
import MultiItems from 'components/common/multiItems';

import { isFunction, classNames } from "utils/common";

import {
    DEFAULT_UNIQUE_PROP,
    MiN_PAGE_SIZE,
    PAGE_SIZE_OPTIONS,
    PAGINATION_ARROW_NAMES,
    TEXT_SIZES,
    ROW_CHOOSER_TYPES,
    COPY_SIZES,
    EXPANDABLE_TYPES
} from "./constants";
import DragHandle from "components/common/table/dragHandle";
import TitleWithCurrencySymbol from "components/common/table/columns/columnTitleModes/titleWithCurrencySymbol";
import {TABLE_PAGINATION_MODE} from "components/common/table/desktop/constants";

//#region ======================================== PAGINATION HELPER FUNCTIONS ==========================================//

const renderPaginationContent = (_, item, originalElement) => {
    if (item === PAGINATION_ARROW_NAMES.PREV) {
        return <i className="icon-left rt--font-bigest" />
    }

    if (item === PAGINATION_ARROW_NAMES.NEXT) {
        return <i className="icon-right rt--font-bigest" />
    }

    return originalElement;
}

const renderPrevNext = ([_, item], hasNext, t) => {
    if (item === PAGINATION_ARROW_NAMES.PREV) {
        return (
            <Button
                className="rt--button rt--button-secondary"
                icon={<i className="icon-left rt--font-bigest"/>}
                iconPosition="start"
            >
                {t("backoffice.common.back")}
            </Button>
        )
    }

    if (item === PAGINATION_ARROW_NAMES.NEXT) {
        return (
            <span>
                <Button
                    type="primary"
                    disabled={!hasNext}
                    icon={<i className="icon-right rt--font-bigest"/>}
                    iconPosition="end"
                    className="rt--button rt--button-primary rt--table-pagination-step-next"
                >
                    {t("backoffice.common.next")}
                </Button>
            </span>
        )
    }

    return null;
}

const renderTotal = (total, range) => {
    return `${range[0]} - ${range[1]} of ${total}`
}

export const getPaginationData = ({ total, hasNext, currentPage, currentPageSize, paginationMode, t }) => {
    if (paginationMode === TABLE_PAGINATION_MODE.PREV_NEXT) {
        return {
            total: currentPage === 1 && !hasNext ? 0 : 9999,
            limit: currentPageSize,
            current: currentPage,
            pageSize: currentPageSize,
            showSizeChanger: true,
            itemRender: (...args) => renderPrevNext(args, hasNext, t),
            hideOnSinglePage: !hasNext,
            suffixIcon: <i className="icon-down" />,
            className: "rt--table-pagination-step"
        }
    }

    return {
        total: total,
        limit: currentPageSize,
        showSizeChanger: true,
        current: currentPage,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        showTotal: renderTotal,
        hideOnSinglePage: currentPageSize === MiN_PAGE_SIZE, // will hide pagination if items amount less than min
        itemRender: renderPaginationContent,
        suffixIcon: <i className="icon-down" />
    }
}

//#endregion

//#region ======================================= EXPAMDABLE HELPER FUNCTIONS ===========================================//

export const renderExpandedIcon = ({
    params,
    disabledRowKeys,
    uniquePropFromRowData = DEFAULT_UNIQUE_PROP
}) => {
    const { record: rowData, onExpand, expanded } = params;

    const rowKey = rowData[uniquePropFromRowData];
    const isRowDisabled = disabledRowKeys.some(key => key === rowKey);

    if (isRowDisabled) {
        return null;
    }

    return (
        <a
            onClick={(e) => {
                onExpand(rowData, e);
            }}
        >
            <i
                className={classNames(
                    expanded ? "icon-up" : "icon-down",
                    "rt--font-bigest"
                )}
            />
        </a>
    )
}

export const atLeastOneColumnExpandable = ({
    rowsData,
    expandable,
}) => {
    if (!Boolean(rowsData?.length)) {
        return false;
    }

    if (!Boolean(expandable)) {
        return false;
    }

    if (expandable.disabled?.length === rowsData.length) {
        return false;
    }

    return true;
}

export const allExpandableRowsAlredyExpanded = ({
    expandedRowKeys,
    rowsData,
    disabledExpandRowKeys,
    uniqueKey,
}) => {
    if (Array.isArray(disabledExpandRowKeys)) {
        const expandedRowKeysWithoutDisabled = expandedRowKeys.filter(key => (
            !disabledExpandRowKeys.includes(key)
        ))

        const rowsDataWithoutDisabled = rowsData.filter(rowData => (
            !disabledExpandRowKeys.includes(rowData[uniqueKey])
        ))

        if (expandedRowKeysWithoutDisabled.length === rowsDataWithoutDisabled.length) {
            return true;
        }

        return false;
    }

    if (expandedRowKeys.length === rowsData.length) {
        return true;
    }

    return false;
}

//#endregion

//#region =================================== COLUMNS DATA CREATE HELPER FUNCTIONS ======================================//

const getColumnTitle = ({ sortingData, editable, title, tooltip, withCurrencySymbol }) => {
    if (isFunction(title)) {
        title = title();
    }

    if (withCurrencySymbol) {
        title = <TitleWithCurrencySymbol title={title} symbol={withCurrencySymbol} />
    }

    if (sortingData.isActive) {
        return (
            <TitleWithSortingIcon title={title} order={sortingData.orderDirection} tooltip={tooltip} />
        )
    }

    if (editable) {
        return (
            <TitleWithEditIcon title={title} />
        )
    }

    if (tooltip) {
        return (
            <TitleWithTooltip title={title} tooltip={tooltip} />
        )
    }

    return title;
}

const getColumnRenderFn = ({
    copyMode,
    dateTimeMode,
    tooltipModeData = {},
    editModeData = {},
    multiModeData = {}, // If only one item show that or show count of items
    textSize,
    additionalRenderFn,
    numericMode,
    formatNumber
}) => {
    if (numericMode) {
        return function (value, record) {
            const valueToRender = (
                isFunction(additionalRenderFn) ? additionalRenderFn(value, record) : value
            )

            if (isNaN(Number(valueToRender))) {
                return "-";
            }

            return formatNumber(valueToRender);
        }
    }

    if (copyMode) {
        return function (value) {
            const valueToRender = (
                isFunction(additionalRenderFn) ? additionalRenderFn(value) : value
            )

            const copyButtonSize = (
                textSize === TEXT_SIZES.MIDDLE
                    ? COPY_SIZES.MIDDLE
                    : COPY_SIZES.DEFAULT
            )

            return (
                <ColumnWithCopyButton
                    value={valueToRender}
                    size={copyButtonSize}
                />
            )
        }
    }
    if (tooltipModeData.isActive) {
        return function (value, record) {
            const valueToRender = (
                isFunction(additionalRenderFn) ? additionalRenderFn(value, record) : value
            )
            const tooltipTitle = tooltipModeData.titleRenderer(value, record)
            return (
                <Tooltip
                    title={tooltipTitle}
                    enableMobile={true}
                >
                    {valueToRender}
                </Tooltip>
            )
        }
    }

    if (textSize === TEXT_SIZES.SHORT || textSize === TEXT_SIZES.MIDDLE) {
        return function (value, record) {
            const valueToRender = (
                isFunction(additionalRenderFn) ? additionalRenderFn(value, record) : value
            )

            return (
                <Tooltip
                    title={valueToRender}
                    enableMobile={true}
                    placement="topLeft"
                >
                    <span
                        className={
                            textSize === TEXT_SIZES.SHORT
                                ? "rt--table-col-short"
                                : "rt--table-col-middle"
                        }
                    >
                        {valueToRender}
                    </span>
                </Tooltip>
            )
        }
    }

    if (dateTimeMode) {
        return function (value) {
            if (!value) {
                return "-";
            }

            return <DateTimeColumn value={value} />
        }
    }

    if (editModeData.isActive) {
        return function (value, record) {
            const valueToRender = (
                isFunction(additionalRenderFn) ? additionalRenderFn(value, record) : value
            )

            if (isFunction(editModeData.disabled) && editModeData.disabled(value, record)) {
                return valueToRender;
            }

            return (
                <EditableCell
                    value={valueToRender}
                    record={record}
                    onEdit={editModeData.onEdit}
                    extraButtons={editModeData.extraButtons}
                    maxLength={editModeData.maxLength}
                    regex={editModeData.regex}
                    type={editModeData.editableFieldType}
                />
            )
        }
    }

    if (multiModeData.isActive) {
        return function (value) {
            const items = (
                isFunction(multiModeData.mapper)
                    ? multiModeData.mapper(value)
                    : value
            );

            if (!items || items.length === 0) {
                return "-";
            }

            return (
                <MultiItems
                    items={items}
                    tagTitle={multiModeData.title}
                />
            )
        }
    }

    return additionalRenderFn;
}

const getChooserColumnData = ({ chooserData }) => {
    if (chooserData.type === ROW_CHOOSER_TYPES.ICON) {
        const chooserColumnData = {
            title: "",
            dataIndex: chooserData.dataIndex,
            className: "rt--table-col-chooser-col",
            sorter: false,
            align: "center",
            render: (_, record) => {
                return chooserData.renderIcon(record);
            }
        }
        return chooserColumnData;
    }

    return null;
}

const getSwitcherColumnData = ({ switcherData }) => {
    const switcherColumnData = {
        title: "",
        dataIndex: switcherData.dataIndex,
        className: "rt--table-col-chooser-col",
        sorter: false,
        align: "center",
        mobileLevel: 0,
        isSwitcher: true,
        render: (_, record) => (
            <div
                className="rt--switcher"
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Switch
                    checked={
                        switcherData.valueHandler
                            ? switcherData.valueHandler(record[switcherData.valueIndex])
                            : record[switcherData.valueIndex]
                    }
                    onChange={checked => switcherData.onChange(checked, record)}
                    disabled={switcherData.disabled?.(record)}
                />
            </div>
        ),
    }

    return switcherColumnData;
}

const getActionsColumnData = ({ actions, reloaderData }) => {
    const actionsColumnData = {
        title: (
            reloaderData?.isActive
                ? (
                    <div className='rt--width-full rt--flex rt--align-center rt--justify-end'>
                        <div className='rt--table-reload rt--flex rt--align-center rt--justify-center'>
                            <i className="icon-reload rt--font-prebigest rt--cursor-pointer" onClick={reloaderData.onClick} />
                        </div>
                    </div>
                ) : (
                    null
                )
        ),
        dataIndex: "actions",
        className: "rt--table-col-actions-col",
        fixed: "right",
        render: (_, record) => {
            if (!Boolean(actions?.length)) {
                return null
            }

            return (
                <CommonActions rowData={record} actions={actions} />
            )
        },
    }

    return actionsColumnData;
}

const getRowSortingColumnData = (sortableData, uniqueKey) => {
    return {
        key: "rowSort",
        align: "center",
        width: 56,
        render: (_, record) => {
            if (sortableData.findIndex(item => item[uniqueKey] === record[uniqueKey]) === -1) {
                return null;
            }

            return <DragHandle/>;
        }
    }
}

//#region =================================== DESKTOP ==================================//

export const antdTableColumnsCreator = ({
    mainColumns,
    additionalColumnsObj = {},
    otherFeatures = {}
}) => {
    const antdTableColumns = [];

    const { chooser, switcher, actions } = additionalColumnsObj;
    const { reloaderData, sorting, formatNumber, sortableData } = otherFeatures;

    if (Boolean(sortableData) && Boolean(sortableData.sortable)) {
        antdTableColumns.push(
            getRowSortingColumnData(sortableData.sortableData, sortableData.uniqueKey)
        );
    }

    if (Boolean(switcher)) {
        antdTableColumns.push(
            getSwitcherColumnData({ switcherData: switcher })
        );
    }

    if (Boolean(chooser) && chooser.type === ROW_CHOOSER_TYPES.ICON) {
        antdTableColumns.push(
            getChooserColumnData({ chooserData: chooser })
        );
    }

    antdTableColumns.push(
        ...mainColumns.map(columnData => {
            const constructedColumnData = { ...columnData };

            const textSize = (
                Boolean(columnData.short)
                    ? TEXT_SIZES.SHORT
                    : Boolean(columnData.middle)
                        ? TEXT_SIZES.MIDDLE
                        : TEXT_SIZES.DEFAULT
            )

            constructedColumnData.title = getColumnTitle({
                sortingData: {
                    isActive: Boolean(columnData.sorter),
                    orderDirection: sorting?.orderDirection
                },
                editable: Boolean(columnData.editable),
                title: columnData.title,
                tooltip: columnData.titleTooltip,
                withCurrencySymbol: columnData.withCurrencySymbol,
            })

            constructedColumnData.render = getColumnRenderFn({
                textSize,
                numericMode: columnData.isNumeric,
                copyMode: Boolean(columnData.copy),
                dateTimeMode: columnData.isDateTime,
                additionalRenderFn: columnData.render,
                editModeData: {
                    isActive: Boolean(columnData.editable),
                    onEdit: columnData.onEdit,
                    disabled: columnData.disableEdit,
                    extraButtons: columnData.extraButtons,
                    maxLength: columnData.maxLength,
                    regex: columnData.regex,
                    editableFieldType: columnData.editableFieldType
                },
                tooltipModeData: {
                    isActive: Boolean(columnData.tooltip),
                    titleRenderer: columnData.tooltip
                },
                multiModeData: {
                    isActive: Boolean(columnData.multi),
                    mapper: columnData.multiMapper,
                    title: columnData.title
                },
                formatNumber
            })

            return constructedColumnData;
        })
    )

    // Actions should be last element (in the right corner of the table)
    if (Boolean(actions) || reloaderData?.isActive) {
        antdTableColumns.push(
            getActionsColumnData({ actions, reloaderData })
        )
    }

    return antdTableColumns;
}

//#endregion

//#region =================================== MOBILE ===================================//

export const mobileTableColumnsCreator = ({ mainColumns, additionalColumnsObj = {}, otherFeatures={} }) => {
    const mobileTableColumns = [];

    const { switcher } = additionalColumnsObj;

    const { formatNumber } = otherFeatures;

    if (Boolean(switcher)) {
        mobileTableColumns.push(
            getSwitcherColumnData({ switcherData: switcher })
        );
    }

    mobileTableColumns.push(
        ...mainColumns.map(columnData => {
            const constructedColumnData = { ...columnData };

            const textSize = (
                Boolean(columnData.short)
                    ? TEXT_SIZES.SHORT
                    : Boolean(columnData.middle)
                        ? TEXT_SIZES.MIDDLE
                        : TEXT_SIZES.DEFAULT
            )

            constructedColumnData.render = getColumnRenderFn({
                textSize,
                numericMode: columnData.isNumeric,
                dateTimeMode: columnData.isDateTime,
                additionalRenderFn: columnData.render,
                tooltipModeData: {
                    isActive: Boolean(columnData.tooltip) || Boolean(columnData.copy),
                    titleRenderer: columnData.tooltip ? columnData.tooltip : value => value
                },
                multiModeData: {
                    isActive: Boolean(columnData.multi),
                    mapper: columnData.multiMapper,
                    title: columnData.title
                },
                formatNumber
            })

            return constructedColumnData;
        })
    )

    return mobileTableColumns;
}

export const mobileExpandableColumnsCreator = ({ mainColumns }) => {
    const mobileExpandableTableColumns = [];

    mobileExpandableTableColumns.push(
        ...mainColumns.map(columnData => {
            const constructedColumnData = { ...columnData };

            constructedColumnData.render = getColumnRenderFn({
                dateTimeMode: columnData.isDateTime,
                additionalRenderFn: columnData.render
            })

            return constructedColumnData;
        })
    )

    return mobileExpandableTableColumns
}

//#endregion

//#endregion

//#region ============================== DESKTOP TABLE ROWS DATA CREATE HELPER FUNCTIONS ================================//

export const antdTableDataCreator = ({
    uniquePropFromRowData = DEFAULT_UNIQUE_PROP,
    rowsData
}) => {
    return rowsData.map((rowData) => ({
        ...rowData,
        key: rowData[uniquePropFromRowData],
    }));
}

//#endregion

//#region =================================== ROW CLASS NAME CREATE HELPER FUNCTIONS ====================================//

export const getRowClassName = ({
    rowData,
    rowIndex,
    expandable,
    clickable,
    expandedRowKeys,
    uniqueKey,
    shouldShowExpandedColumn,
}) => {
    let ret = "";

    if ((rowIndex + 1) % 2 == 0) {
        ret += "rt--table-row-even";
    } else {
        ret += "rt--table-row-odd";
    }

    if (expandedRowKeys.includes(rowData[uniqueKey])) {
        if (Boolean(expandable)) {
            if (!shouldShowExpandedColumn && expandable.expandAll) {
                ret += " rt--table-row-always-expended";
            } else {
                ret += " rt--table-row-expended";
            }
        }
    }

    if (rowData.totalCol === "total") {
        ret += " rt--table-row-total";
    }

    if (clickable && (!clickable.disabled || !clickable.disabled(rowData))) {
        ret += " rt--table-row-clickable";
    }

    return ret;
};

//#endregion

//#region ============================== (MOBILE) FILTER AND SORT COLUMNS HELPER FUNCTIONS ==============================//

export const filterAndSortColumnsByMobileLevel = (columns) => {
    const filteredColumns = columns.filter(c => c.mobileLevel !== undefined);
    const sortedColumns = filteredColumns.sort((a, b) => a.mobileLevel > b.mobileLevel ? 1 : -1);

    return sortedColumns;
}

//#endregion

//#region ====================================== (MOBILE) DITALIS HAS TABS OR NOT =======================================//

export const hasMobileDetailsTab = ({
    disableFullView,
    rowData,
    uniqueKey,
    expandable,
}) => {
    if (disableFullView) {
        return false;
    }

    if (!expandable) {
        return false;
    }

    if (expandable.disabled?.includes(rowData[uniqueKey])) {
        return false;
    }

    const detailsObj = expandable.details?.(rowData);

    if ((expandable.type === EXPANDABLE_TYPES.CONTENT || expandable.type === EXPANDABLE_TYPES.BOTH) && !detailsObj.content) {
        return false;
    }

    if (expandable.type === EXPANDABLE_TYPES.TABLE && (!detailsObj || !detailsObj.columns)) {
        return false;
    }

    return true
}

//#endregion
