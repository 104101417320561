import React, {useEffect, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import useProjectType from "hooks/useProjectType";
import Select from "components/common/select";
import {ENTITY_TYPE, TIME_PERIOD} from "pages/dashboard/constants";
import {FILTER_TYPE} from "constants/common.constants";
import {isFranchisingMode} from "utils/common";
import AutoComplete from "components/common/autoComplete";
import {Form} from "antd";
import useAutosuggestion from "hooks/useAutosuggestion";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const StatisticsFiltersDesktop = ({ filters, onChange, currencyOptions, hasOneCurrency }) => {
    const { t } = useTranslation();

    const { hasAgentSystem } = useProjectType();

    const [ betshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: isFranchisingMode()
    });

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!filters.betShopNameOrId;

    const betShopOptions = useMemo(() => ([
        {
            text: t('backoffice.dashboard.allBetshops'),
            value: ''
        },
        ...betshopNames.map(betshop => ({
            text: betshop.name,
            value: betshop.id
        }))
    ]), [betshopNames, t]);

    return (
        <>
            {isFranchisingMode() && (
                <Select
                    className="rt--main-layout-header-actions-dropdown-big rt--mr-16"
                    placeholder={t('backoffice.dashboard.allBetshops')}
                    options={betShopOptions}
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    value={filters.betShopNameOrId}
                    onChange={value => onChange({ betShopNameOrId: value })}
                    search
                />
            )}

            <Select
                options={
                    [
                        { value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today") },
                        { value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday") },
                        { value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek") },
                        { value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek") },
                        { value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth") },
                        { value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth") },
                        { value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter") },
                        { value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd") },
                        { value: TIME_PERIOD.LAST_YEAR, text: t("backoffice.dashboard.lastYear") },
                    ]
                }
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle rt--mr-16"
                onChange={value => onChange({ timePeriod: value })}
                value={filters.timePeriod}
            />

            {!hasOneCurrency && (
                <Select
                    className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle rt--mr-16"
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    value={filters.currencyCode}
                    options={[
                        ...currencyOptions
                    ]}
                    onChange={(value) => onChange({"currencyCode": value})}
                />
            )}

            {hasAgentSystem && !isBetShopOwner && !hasSelectedBetShop && (
                <Select
                    className="rt--tab-table-layout-header-actions-dropdown rt--tab-table-layout-header-actions-dropdown-middle"
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    value={filters.filterType}
                    options={[
                        {value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct")},
                        {value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network")},
                    ]}
                    onChange={(value) => onChange({"filterType": value})}
                />
            )}
        </>
    );
};

export default StatisticsFiltersDesktop;
