import React, { useMemo } from "react";

import {Collapse, Form} from "antd";

import CommissionDetailsRow from "./components/commissionDetailsRow";

import {useTranslation} from "react-i18next";

import "./styles.scss";
import ExpandIcon
	from "pages/agentSystem/agents/edit/sections/commission/components/agentCommissionDetailsForm/components/expandIcon";

const AgentCommissionDetailsForm = ({ formInstance, canEdit, commissionDetails, onFinish, onFieldsChange }) => {
	const { t } = useTranslation();

	const collapses = useMemo(() => {
		return commissionDetails.agentCommissionSettings?.categories.map((category, categoryIndex) => (
			<Collapse
				key={category.id}
				className="rt--commission-details-form-collapse"
				expandIcon={(props) => <ExpandIcon {...props} />}
				items={[
					{
						key: category.id,
						forceRender: true,
						label: (
							<CommissionDetailsRow
								label={category.name}
								category={category}
								parentCategory={commissionDetails.parentCommissionSettings?.categories[categoryIndex]}
								formInstance={formInstance}
							/>
						),
						children: (
							<div className="rt--commission-details-form-collapse-content">
								{category.providers.map((provider, providerIndex) => (
									<CommissionDetailsRow
										key={provider.id}
										label={provider.name}
										category={category}
										parentCategory={
											commissionDetails.parentCommissionSettings?.categories[categoryIndex]
										}
										provider={provider}
										parentProvider={
											commissionDetails.parentCommissionSettings?.categories[categoryIndex]
												.providers[providerIndex]
										}
										formInstance={formInstance}
									/>
								))}
							</div>
						)
					}
				]}
			/>
		));
	}, [commissionDetails]);

	return (
		<Form
			disabled={!canEdit}
			form={formInstance}
			className="rt--commission-details-form"
			onFieldsChange={onFieldsChange}
			onFinish={onFinish}
		>
			{collapses}
		</Form>
	);
};

export default AgentCommissionDetailsForm;
