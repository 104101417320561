import React from 'react';

import NumericInput from "components/common/numericInput";

import "./styles.scss";

const CommissionItem = ({ currency, value = 0, onChange }) => {
    return (
        <div className="rt--agent-commission-item">
            <label>{currency.currency}</label>

            <NumericInput
                value={value}
                addonAfter="%"
                emptyOnZero
                max={100}
                onChange={onChange}
            />
        </div>
    );
};

export default CommissionItem;
