import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Checkbox } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getCommissionCalculationsReport, setCommissionCalculationsReportFilters } from "store/actions/dashboard/reports/commissionCalculations.action";

import { isFranchisingMode } from "utils/common";
import { getCurrencyFieldOptions } from 'utils/currency';

import useUserCurrencies from 'hooks/useUserCurrencies';
import useProjectType from 'hooks/useProjectType';

import {
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_SOURCE_DIRECTION
} from "constants/commission.constants";
import { PROJECT_PROVIDER_TYPE } from "constants/project.constants"

import gameProviderType from "types/project/gameProvider.type";

/** Cashier Transaction Report Page FiltersComponent */

const Filters = ({
    setCommissionCalculationsReportFilters,
    getCommissionCalculationsReport,
    filters,
    globalProjectId,
    gameProviders
}) => {
    const { t } = useTranslation();

    const [ userCurrencies ] = useUserCurrencies(false);

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const { hasRetail } = useProjectType();

    return (
        <FiltersWrapper
            loadFn={getCommissionCalculationsReport}
            setFiltersFn={setCommissionCalculationsReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName="nameOrId"
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name="currencyCode"
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                ...currencyFieldOptions
                            ]}
                        />
                    </Form.Item>
                </Col>
                {
                    !isFranchisingMode() && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.commissioncalculations.incomeType')}
                                name="type"
                            >
                                <Select
                                    options={[
                                        { value: "", text: t("backoffice.common.all") },
                                        { value: COMMISSION_PLAN_TYPE.DIRECT, text: t("backoffice.commissionplans.direct") },
                                        { value: COMMISSION_PLAN_TYPE.NETWORK, text: t("backoffice.commissionplans.network") },
                                        // { value: COMMISSION_PLAN_TYPE.MIX, text: t("backoffice.commissionplans.mix") }
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.incomeType')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                {
                    !isFranchisingMode() && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.commissioncalculations.sourceDirection')}
                                name="sourceDirection"
                            >
                                <Select
                                    options={[
                                        { value: "", text: t("backoffice.common.all") },
                                        { value: COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS, text: t("backoffice.commissionplans.players") },
                                        { value: COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS, text: t("backoffice.commissionplans.betshops") },
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.sourceDirection')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                {
                    !isFranchisingMode() && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.commissioncalculations.onlineProviders')}
                                name="onlineProvider"
                            >
                                <Select
                                    options={
                                        [
                                            { value: "", text: t("backoffice.common.all") },
                                            ...gameProviders
                                                .map(provider => (
                                                    { value: provider.id, text: provider.name }
                                                ))
                                        ]
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.onlineProviders')}`}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                {
                    hasRetail && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.commissioncalculations.retailProviders')}
                                name="retailProviders"
                            >
                                <Select
                                    options={
                                        [
                                            { value: "", text: t("backoffice.common.all") },
                                            { value: PROJECT_PROVIDER_TYPE.SPORTBOOK, text: t("backoffice.common.Sportsbook") },
                                            { value: PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, text: t("backoffice.common.DGVirtuals") },
                                            { value: PROJECT_PROVIDER_TYPE.GOLDEN_RACE, text: t("backoffice.common.GoldenRace") }
                                        ]
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.retailProviders')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }


                <Col span={24}>
                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                        <Form.Item
                            className='rt--form-item-inline rt--form-item-without-margin'
                            name="includeZeroAmounts"
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.commissioncalculations.includeWithZeroAmount')}</span>
                    </div>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get commission calculations report */
    getCommissionCalculationsReport: PropTypes.func,
    /** Redux action to set commission calculations report sorting details */
    setCommissionCalculationsReportFilters: PropTypes.func,
    /** Redux state property, commission calculations report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType)
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionCalculationsReport: nextPage => {
            dispatch(getCommissionCalculationsReport(nextPage));
        },
        setCommissionCalculationsReportFilters: filters => {
            dispatch(setCommissionCalculationsReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.commissionCalculationsReport.filters,
        globalProjectId: state.common.globalProjectId,
        gameProviders: state.common.gameProviders
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
