import React from 'react';
import i18n from "translations/config";

import Status from 'components/common/status';

import { BETTING_TYPE, BET_TYPE } from "constants/bet.constants";
import { STATUS_TYPES } from 'constants/status.constants';

const BET_TYPE_NAMES = {
    [BET_TYPE.SINGLE]: "single",
    [BET_TYPE.MULTI]: "multi",
}

const BETTING_TYPE_NAMES = {
    [BETTING_TYPE.PREMATCH]: "preMatch",
    [BETTING_TYPE.LIVE]: "live",
}

export const getTableColumns = (additionalProps) => {
    const {
        formatAmount,
        hasOneCurrency
    } = additionalProps;

    return [
        {
            title: "backoffice.bets.betslipId",
            dataIndex: "id",
            copy: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.bets.playerUserName",
            dataIndex: "userName",
            render: value => value || "-",
            alwaysVisible: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.bets.playerId",
            dataIndex: "userId",
            copy: true,
            alwaysVisible: true,
        },
        {
            title: 'backoffice.bets.betDate',
            dataIndex: 'betTime',
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.bets.type",
            dataIndex: "betType",
            mobileLevel: 4,
            render: value => (
                Boolean(BET_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.bets.${BET_TYPE_NAMES[value]}`)
                    : i18n.t("backoffice.bets.system")
            ),
        },
        {
            title: "backoffice.bets.bettingType",
            dataIndex: "bettingType",
            render: value => (
                Boolean(BETTING_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.common.${BETTING_TYPE_NAMES[value]}`)
                    : i18n.t("backoffice.common.kombi")
            ),
        },
        {
            title: 'backoffice.bets.amount',
            dataIndex: 'amount',
            sorter: true,
            mobileLevel: 5,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime),
            withCurrencySymbol: hasOneCurrency,
        },
        !hasOneCurrency && {
            title: 'backoffice.bets.currency',
            dataIndex: 'currencyCode',
            sorter: true,
            mobileLevel: 6,
        },
        {
            title: 'backoffice.bets.odds',
            dataIndex: 'factor',
            sorter: true,
            render: (value, record) => (
                (record.betType === BET_TYPE.SINGLE && record.bets.length > 1)
                    ? "-"
                    : Number(value).toFixed(2) + "x"
            ),
        },
        {
            title: 'backoffice.bets.status',
            dataIndex: 'status',
            sorter: true,
            render: (status, record) => <Status status={status} additionalData={{ cashedOut: record.isCashedout }} type={STATUS_TYPES.BET} />,
            mobileLevel: 7,
        },
        {
            title: 'backoffice.bets.calculatedAt',
            dataIndex: 'calculateTime',
            isDateTime: true,
        },
        {
            title: 'backoffice.bets.possibleWin',
            dataIndex: 'possibleWin',
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime),
        },
        {
            title: 'backoffice.bets.win',
            dataIndex: 'winning',
            sorter: true,
            render: (value, record) => formatAmount(value, record.currencyCode, record.betTime),
        },
        {
            title: "backoffice.bets.agentId",
            dataIndex: "agentId",
            copy: true,
        },
        {
            title: "backoffice.bets.agentUserName",
            dataIndex: "agentUserName",
            render: value => value || "-",
        },
    ];
}
