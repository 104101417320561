//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region libraries
import { Row, Col, Form } from 'antd';
//#endregion

//#region actions
import {
    getBetshopPerformanceReport,
    setBetshopPerformanceReportFilters
} from 'store/actions/dashboard/reports/betshopPerformanceReport.action';
//#endregion

//#region components
import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import AutoComplete from "components/common/autoComplete";
import DateRangePicker from "components/common/dateRangePicker";
//#endregion

//#region hooks
import useUserCurrencies from 'hooks/useUserCurrencies';
import useAutosuggestion from 'hooks/useAutosuggestion';
//#endregion

//#region utils
import { getCurrencyFieldOptions } from 'utils/currency';
import { getUser } from 'utils/auth';
import { isFranchisingMode, getPossibleParentsName } from 'utils/common';
//#endregion

//#region constants
import { USER_ROLE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';
import { FILTER_TYPE } from 'constants/common.constants';
//#endregion

const FILTERS_FIELD_NAMES = {
    DATE: "date",
    CURRENCIES: "currencies",
    AGENT_USERNAME_OR_ID: "agentUserNameOrId",
    FILTER_TYPE: "filterType"
}

/** Betshop Performance Report Page Filters Component */
const Filters = ({
    globalProjectId,
    filters,
    getBetshopPerformanceReport,
    setBetshopPerformanceReportFilters
}) => {
    const { t } = useTranslation();

    const [agentNames, getAgentNames] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: { excludeAgent: false },
        autoGet: false
    });
    const [userCurrencies] = useUserCurrencies(false);

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    return (
        <FiltersWrapper
            loadFn={getBetshopPerformanceReport}
            setFiltersFn={setBetshopPerformanceReportFilters}
            filters={filters}
            updateProp={[globalProjectId]}
            searchFieldName="betShopNameOrId"
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: true }
            ]}
            onInit={() => {
                getAgentNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker
                            showTime={true}
                            timePickerMode={TIME_PICKER_MODES.ONLY_HOURS}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name={FILTERS_FIELD_NAMES.CURRENCIES}
                    >
                        <Select
                            options={currencyFieldOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            isMultiple={true}
                        />
                    </Form.Item>
                </Col>

                {(!isFranchisingMode() || getUser()?.role === USER_ROLE.ADMIN) && (
                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.performancereport.belongsTo')}
                            name={FILTERS_FIELD_NAMES.AGENT_USERNAME_OR_ID}
                        >
                            <AutoComplete
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.performancereport.belongsTo')}`}
                                items={getPossibleParentsName({
                                    globalProjectId,
                                    agentNames,
                                })}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                )}

                {(!isFranchisingMode() || getUser()?.role === USER_ROLE.ADMIN) && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.performancereport.type')}
                            name={FILTERS_FIELD_NAMES.FILTER_TYPE}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.performancereport.type')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                options={[
                                    { value: FILTER_TYPE.DIRECT, text: t('backoffice.performancereport.direct') },
                                    { value: FILTER_TYPE.NETWORK, text: t('backoffice.performancereport.network') },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </FiltersWrapper>
    );
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux state property, betshop performance report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to set betshop performance report filters */
    setBetshopPerformanceReportFilters: PropTypes.func,
    /** Redux action to get betshop performance report */
    getBetshopPerformanceReport: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopPerformanceReport: () => {
            dispatch(getBetshopPerformanceReport());
        },
        setBetshopPerformanceReportFilters: filters => {
            dispatch(setBetshopPerformanceReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.betshopPerformanceReport.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
