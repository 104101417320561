import React, {useMemo} from 'react';
import {Col, Row} from "antd";
import SubProjectItem
    from "pages/agentSystem/agents/edit/sections/general/components/subProjects/components/subProjectItem";

const SubProjects = ({ value, subProjects, currencies, onChange }) => {
    console.log({value});

    const mappedSubProjects = useMemo(() => {
        return subProjects?.reduce((acc, subProject) => {
            const { currencyCodes } = subProject;

            currencyCodes.forEach(currencyCode => {
                currencyCode = currencyCode.toUpperCase();

                if (!acc[currencyCode]) {
                    acc[currencyCode] = [];
                }

                acc[currencyCode].push(subProject);
            })

            return acc;
        }, {})
    }, [subProjects])

    const handleItemChange = (itemValue, currency) => {
        onChange({ ...value, [currency]: itemValue });
    }

    return (
        <Row gutter={[24, 16]}>
            {currencies.map(currency => {
                const name = currency.currency.toLowerCase();
                const currencyValue = value?.[name];

                return (
                    <Col key={currency.currency} lg={12} xs={24}>
                        <SubProjectItem
                            currency={currency}
                            subProjects={mappedSubProjects[currency.currency]}
                            value={currencyValue}
                            onChange={(value) => handleItemChange(value, name)}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default SubProjects;
