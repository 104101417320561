import React, {useEffect, useState} from 'react';
import Select from "components/common/select";
import getUserTypeOptions from "pages/reports/productReport/helpers/getUserTypeOptions";
import {FILTER_USER_TYPE, USER_ROLE} from "constants/user.constants";
import Search from "components/common/search";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {Space} from "antd";
import {FILTER_TYPE} from "constants/common.constants";
import {connect} from "react-redux";
import {getProductReport, setProductReportFilters} from "store/actions/dashboard/reports/productReport.action";
import {useTranslation} from "react-i18next";
import {getUser} from "utils/auth";
import {classNames, isMobile} from "utils/common";

const SearchWithSelect = ({ value = [null, ""], onChange, onUserTypeChange, globalProjectId, getProductReport}) => {
    const [userType, setUserType] = useState(value[0]);

    const { t } = useTranslation();

    const { role: userRole } = getUser();

    useEffect(() => {
        setUserType(value[0])
    }, [value[0]]);

    const handleSearchChange = (changedValue, item) => {
        onChange?.([userType, changedValue], item);
    }

    const handleUserTypeChange = (changedValue) => {
        setUserType(changedValue);

        onUserTypeChange?.(changedValue);
    }

    return (
        <Space.Compact className="rt--search-with-select">
            <Select value={userType} options={getUserTypeOptions(t)} onChange={handleUserTypeChange} />
            {
                userType === FILTER_USER_TYPE.PLAYER
                    ? (
                        <Search
                            key="player"
                            isInForm
                            value={value[1]}
                            placeholder={t("backoffice.reports.usernameOrId")}
                            // loadFn={!isMobile() ? getProductReport : () => {}}
                            autoSuggestion={{
                                type: AUTOSUGGESTION_TYPE.PLAYER,
                                dependencies: [globalProjectId],
                                autoGet: false,
                                searchFieldName: "userNameOrId"
                            }}
                            onSearch={handleSearchChange}
                        />
                    )
                    : (
                        <Search
                            key="agent"
                            isInForm
                            value={value[1]}
                            onSearch={handleSearchChange}
                            // loadFn={!isMobile() ? getProductReport : () => {}}
                            autoSuggestion={{
                                type: AUTOSUGGESTION_TYPE.AGENT,
                                dependencies: [globalProjectId],
                                actionParams: {
                                    excludeAgent: userRole === USER_ROLE.ADMIN
                                }
                            }}
                            placeholder={t("backoffice.reports.usernameOrId")}
                        />
                    )
            }
        </Space.Compact>
    );
};

const mapStateToProps = (state) => {
    return {
        globalProjectId: state.common.globalProjectId,
    };
};

const mapDispatchToProps = dispatch => ({
    getProductReport: () => {
        dispatch(getProductReport());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchWithSelect);
