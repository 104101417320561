export const getTableColumns = additionalProps => {
    const {
        formatAmount,
        dateService,
        hasOneCurrency
    } = additionalProps;

    return [
        {
            title: "backoffice.bets.provider",
            dataIndex: "provider",
            mobileLevel: 1
        },
        {
            title: 'backoffice.bets.currency',
            dataIndex: 'currencyCode',
            mobileLevel: 2,
        },
        {
            title: "backoffice.bets.playerUserName",
            dataIndex: "playerUserName",
            mobileLevel: 3,
            alwaysVisible: true,
            render: value => value || "-",
        },
        {
            title: "backoffice.bets.playerExternalId",
            dataIndex: "playerExternalId",
            copy: true,
            alwaysVisible: true,
        },
        {
            title: 'backoffice.bets.amount',
            dataIndex: 'turnover',
            sorter: true,
            mobileLevel: 4,
            withCurrencySymbol: hasOneCurrency,
            render: (value, record) => formatAmount(value, record.currencyCode, record.from)
        },
        {
            title: 'backoffice.bets.winAmount',
            dataIndex: 'won',
            sorter: true,
            mobileLevel: 5,
            withCurrencySymbol: hasOneCurrency,
            render: (value, record) => formatAmount(value, record.currencyCode, record.from)
        },
        {
            title: 'backoffice.bets.bonusWin',
            dataIndex: 'bonusWon',
            withCurrencySymbol: hasOneCurrency,
            render: (value, record) => formatAmount(value, record.currencyCode, record.from)
        },
        {
            title: "backoffice.bets.bonusTurnover",
            dataIndex: "bonusTurnover",
            render: value => value || "-",
        },
        {
            title: 'backoffice.bets.ggr',
            dataIndex: 'gGR',
            sorter: true,
            withCurrencySymbol: hasOneCurrency,
            render: (_, record) => formatAmount(record.ggr, record.currencyCode, record.from)
        },
        {
            title: 'backoffice.bets.bonusGGR',
            dataIndex: 'bonusGGR',
            withCurrencySymbol: hasOneCurrency,
            render: (value, record) => formatAmount(value, record.currencyCode, record.from)
        },
        {
            title: 'backoffice.bets.ngr',
            dataIndex: 'nGR',
            sorter: true,
            withCurrencySymbol: hasOneCurrency,
            render: (_, record) => formatAmount(record.ngr, record.currencyCode, record.from)
        },
        {
            title: "backoffice.bets.agentId",
            dataIndex: "agentId",
            copy: true,
        },
        {
            title: "backoffice.bets.agentUserName",
            dataIndex: "agentUserName",
            mobileLevel: 6,
            render: value => value || "-",
        },
        {
            title: "backoffice.bets.date",
            dataIndex: "from",
            render: (value, record) => `${dateService.format(value)} - ${dateService.format(record.to)}`
        }
    ];
}
