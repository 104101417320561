import i18n from "translations/config";

import { isString } from "utils/javaScriptTypes";
import { isFranchisingMode, isFunction, isMobile } from "utils/common";

import { ALL } from "constants/common.constants";

export const tableColumnsCreator = ({
    mainColumns, // Can be array of object (columns data) or functions which returns array of object
    expandColumns, // Can be array of object (columns data) or functions which returns array of object
    includedColumns,
    constructForExport = false,
    includeExpandColumnsInExport = false,
    constructForInclude = false, // table include/exclude functionality
    additionalProps, // In case of mainColumns/expandColumns need more functionality and/or info
}) => {
    const franchisingMode = isFranchisingMode();
    const isMobileDevice = isMobile();

    const computedMainColumns = (
        isFunction(mainColumns)
            ? mainColumns(additionalProps)
            : mainColumns
    )

    const computedExpandColumns = (
        isFunction(expandColumns)
            ? expandColumns(additionalProps)
            : expandColumns
    )

    const data = {
        /**
         * Here will be all columns (in separate properties) that can be used in components
         * See below all property names
         * mainTableColumns
         * expandTableColumns
         * columnsForExport
         * columnsThatCanBeIncluded
         */
    }
    //TODO: create function for constructing main/expand columns
    if (computedMainColumns) {
        data.mainTableColumns = [];

        for (const columnData of computedMainColumns) {
            if (!columnData) {
                continue;
            }

            if (franchisingMode && columnData.disabledForFranchisingMode) {
                continue;
            }

            const newColumnData = { ...columnData };

            if (isString(newColumnData.title)) {
                newColumnData.title = i18n.t(newColumnData.title);
            }

            if (isString(newColumnData.titleTooltip)) {
                newColumnData.titleTooltip = i18n.t(newColumnData.titleTooltip);
            }

            data.mainTableColumns.push(newColumnData);
        }
    }

    if (computedExpandColumns) {
        data.expandTableColumns = [];

        for (const columnData of computedExpandColumns) {
            if (!columnData) {
                continue;
            }

            if (franchisingMode && columnData.disabledForFranchisingMode) {
                continue;
            }

            const newColumnData = { ...columnData };

            if (isString(newColumnData.title)) {
                newColumnData.title = i18n.t(newColumnData.title);
            }

            if (isString(newColumnData.titleTooltip)) {
                newColumnData.titleTooltip = i18n.t(newColumnData.titleTooltip);
            }

            data.expandTableColumns.push(newColumnData);
        }
    }

    // In mobile version we don't support include/exclude and export functionality
    if (isMobileDevice) {
        return data;
    }

    if (constructForExport) {
        //TODO: create function for constructing export columns
        data.columnsForExport = data.mainTableColumns.map(columnData => ({
            title: isFunction(columnData.title) ? columnData.title() : columnData.title,
            key: columnData.dataIndex,
            disable: (
                columnData.disableExport
                    ? columnData.disableExport
                    : undefined
            )
        }));

        if (includeExpandColumnsInExport) {
            data.expandTableColumns.forEach(columnData => {
                const exist = data.columnsForExport.some(c => c.key === columnData.dataIndex);

                if (exist) {
                    return;
                }

                data.columnsForExport.push({
                    title: columnData.title,
                    key: columnData.dataIndex,
                    disable: (
                        columnData.disableExport
                            ? columnData.disableExport
                            : undefined
                    )
                })

            })
        }

    }

    if (constructForInclude) {
        //TODO: create function for constructing included columns
        const columnsThatCanBeIncluded = data.mainTableColumns.filter(columnData => (
            !columnData.alwaysVisible
        ));

        data.columnsThatCanBeIncluded = columnsThatCanBeIncluded.map(columnData => ({
            title: columnData.title,
            key: columnData.dataIndex,
        }));

        //TODO: create function for filtering main columns
        data.mainTableColumns = (
            includedColumns !== ALL
                ? (
                    data.mainTableColumns.filter(columnData => {
                        const isColumnCanBeIncluded = (
                            data.columnsThatCanBeIncluded.some(column => (
                                column.key === columnData.dataIndex
                            ))
                        )

                        if (isColumnCanBeIncluded) {
                            return includedColumns.includes(columnData.dataIndex);
                        }

                        return true;
                    })
                ) : (
                    data.mainTableColumns
                )
        )
    }

    return data;
}
