import React from "react"

import { getWorkingHoursConfigs, saveWorkingHoursConfigs } from "../api";

import WorkingHours from "components/common/workingHours";

const WorkingHoursConfigs = ({
    onTabChange
}) => {
    return (
        <WorkingHours 
            onTabChange={onTabChange}
            getWorkingHoursConfigs={getWorkingHoursConfigs}
            saveWorkingHoursConfigs={saveWorkingHoursConfigs}
        />
    )
}

export default WorkingHoursConfigs;