import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import { getWalletHistory, setWalletHistoryFilters } from "store/actions/dashboard/wallet/history.action";
import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";
import { getAgentCurrencies } from "store/actions/dashboard/agentSystem/agents/currencies.action";

import { makeTransactionText } from "utils/wallet"

import { WALLET_OWNER_TYPE } from "constants/wallet.constants";
import { DATE_PICKER_RANGES } from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import currencyType from "types/currency/currency.type";
import useProjectType from "hooks/useProjectType";
import {getAvailableOperationTypes} from "pages/wallet/history/helpers/getAvailableOperationTypes";

/** Wallet Transfer History Page Filters Component */

const Filters = ({
    setWalletHistoryFilters,
    getWalletHistory,
    filters,
    currencies,
    hasOneCurrency,
    ownerType
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();
    const { hasLiteMode } = useProjectType();

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: false
    });

    let availableOperationTypes = getAvailableOperationTypes({ownerType, hasLiteMode});

    return (
        <FiltersWrapper
            loadFn={() => getWalletHistory(searchParams.id, ownerType)}
            setFiltersFn={setWalletHistoryFilters}
            filters={filters}
            datePickerFields={[
                { name: "date", time: true }
            ]}
            searchFieldName="transactionId"
            onInit={() => {
                getAgentNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            disabledRanges={[DATE_PICKER_RANGES.YTD, DATE_PICKER_RANGES.LAST_YEAR]}
                        />
                    </Form.Item>
                </Col>

                {
                    ownerType === WALLET_OWNER_TYPE.AGENT && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.wallet.usernameOrId')}
                                name="userNameOrId"
                            >
                                <AutoComplete
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.usernameOrId')}`}
                                    items={agentNames}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }


                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.wallet.transactionType')}
                        name="transactionType"
                    >
                        <Select
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                ...availableOperationTypes.map(type => (
                                    { value: type, text: makeTransactionText(type, hasLiteMode) }
                                ))
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.transactionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                {
                    !hasOneCurrency
                        ? (
                            <Col span={24} >
                                <Form.Item
                                    label={t('backoffice.wallet.currency')}
                                    name="currencyCode"
                                >
                                    <Select
                                        options={currencies}
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.currency')}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                        : null
                }


            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get wallet history */
    getWalletHistory: PropTypes.func,
    /** Redux action to set wallet history filters */
    setWalletHistoryFilters: PropTypes.func,
    /** Redux state property, errors filters */
    filters: PropTypes.object,
    /** Redux action to get agent currencies */
    getAgentCurrencies: PropTypes.func,
    /** Redux action to get project currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux state, represents the currencies of current editing agent  */
    agentCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state, represents the currencies of current editing project  */
    projectCurrencies: PropTypes.arrayOf(currencyType),
    /** Wallet owner type */
    ownerType: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE))
}

const mapDispatchToProps = dispatch => (
    {
        getWalletHistory: (ownerId, ownerType) => {
            dispatch(getWalletHistory(ownerId, ownerType))
        },
        setWalletHistoryFilters: filters => {
            dispatch(setWalletHistoryFilters(filters))
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.wallet.history.filters,
        agentCurrencies: state.agents.edit.currencies.currencies,
        projectCurrencies: state.projects.edit.currencies
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
