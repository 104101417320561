import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Divider, Checkbox } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getAgentTransactionsReport, setAgentTransactionsReportFilters } from "store/actions/dashboard/reports/agentTransactions.action";

import { isFranchisingMode } from "utils/common";
import { getUser } from 'utils/auth';
import { makeTransactionText } from "utils/wallet"
import { getCurrencyFieldOptions } from 'utils/currency';

import { USER_ROLE, USER_TYPE } from "constants/user.constants";
import { WALLET_OWNER_TYPE } from "constants/wallet.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useProjectType from 'hooks/useProjectType';
import {getAvailableOperationTypes} from "pages/reports/agentTransactionsReport/helpers/getAvailableOperationTypes";

/** Agent Transaction Report Page FiltersComponent */

const Filters = ({
    userCurrencies,
    hasOneCurrency,
    setAgentTransactionsReportFilters,
    getAgentTransactionsReport,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const { hasRetail, hasAgentSystem, hasLiteMode } = useProjectType();

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: { userRole: USER_TYPE.AGENT, excludeAgent: false },
        autoGet: false
    });

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: false
    });

    const [ playerNames, getPlayerNames, resetPlayerNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PLAYER,
        disable: isFranchisingMode(),
        autoGet: false
    });

    const showParticipants = (!isFranchisingMode() || getUser().role !== USER_ROLE.AGENT);

    const handlePlayersChange = (v) => {
        if(v?.length >= 3){
            getPlayerNames({"userNameOrId" : v})
        } else if(v?.length === 0){
            resetPlayerNames();
        }
    }

    const [participant1Type, setParticipant1Type] = useState(filters.participant1Type);
    const [participant2Type, setParticipant2Type] = useState(showParticipants ? filters.participant2Type : WALLET_OWNER_TYPE.BETSHOP);

    const availableOperationTypes = getAvailableOperationTypes({ hasLiteMode, hasAgentSystem, hasRetail })

    return (
        <FiltersWrapper
            loadFn={getAgentTransactionsReport}
            setFiltersFn={setAgentTransactionsReportFilters}
            filters={filters}
            updateProp={[globalProjectId]}
            resetFields={{
                "participant1Type": "participant1",
                "participant2Type": "participant2"
            }}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName="groupId"
            onReset={() => {
                setParticipant1Type("");
                setParticipant2Type(showParticipants ? "" : WALLET_OWNER_TYPE.BETSHOP);
            }}
            onInit={() => {
                getAgentNames();
                getBetshopNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                {!hasOneCurrency && <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={[
                                {value: "", text: t("backoffice.common.all")},
                                ...currencyFieldOptions
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>}

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.reports.transactionType')}
                        name="transactionTypes"
                    >
                        <Select
                            options={
                                availableOperationTypes.map(type => (
                                    { value: type, text: makeTransactionText(type, hasLiteMode) }
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.transactionType')}`}
                            isMultiple={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                    <Divider className='rt--filters-divider' />
                </Col>

                {showParticipants
                    ? (
                        <>
                        <Col span={24}>
                            <div className='rt--mb-16'>
                            <span
                                className='rt--title rt--font-normal rt--font-bold'>{t('backoffice.reports.participant') + " 1"}</span>
                            </div>
                            <Form.Item
                                label={t('backoffice.reports.participantType')}
                                name="participant1Type"
                            >
                                <Select
                                    options={[
                                        {value: "", text: t('backoffice.common.all')},
                                        ...(!isFranchisingMode() || hasAgentSystem) ? [
                                            {
                                                value: WALLET_OWNER_TYPE.AGENT,
                                                text: isFranchisingMode() ? t('backoffice.common.betshopOwner') : t('backoffice.common.agent')
                                            },
                                        ] : [],
                                        ...getUser()?.role === USER_ROLE.ADMIN ? [
                                            {value: WALLET_OWNER_TYPE.SUPER_AGENT, text: t('backoffice.common.admin')}
                                        ] : []
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.participantType')}`}
                                    onChange={value => setParticipant1Type(value)}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            {
                                participant1Type === WALLET_OWNER_TYPE.AGENT && (
                                    <Form.Item
                                        label={t('backoffice.reports.usernameOrId')}
                                        name="participant1"
                                        className={participant1Type !== WALLET_OWNER_TYPE.AGENT ? "rt--form-item-disabled" : ""}
                                    >
                                        <AutoComplete
                                            placeholder={t('backoffice.reports.usernameOrId')}
                                            items={(participant1Type === WALLET_OWNER_TYPE.AGENT ? agentNames : [])}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                )
                            }

                            <Divider className='rt--filters-divider'/>
                        </Col>

                        <Col span={24}>
                            <div className='rt--mb-16'>
                            <span
                                className='rt--title rt--font-normal rt--font-bold'>{t('backoffice.reports.participant') + " 2"}</span>
                            </div>
                            <Form.Item
                                label={t('backoffice.reports.participantType')}
                                name="participant2Type"
                            >
                                <Select
                                    options={[
                                        {value: "", text: t('backoffice.common.all')},
                                        ...(!isFranchisingMode() || hasAgentSystem) ? [
                                            {
                                                value: WALLET_OWNER_TYPE.AGENT,
                                                text: isFranchisingMode() ? t('backoffice.common.betshopOwner') : t('backoffice.common.agent')
                                            },
                                        ] : [],
                                        ...hasRetail ? [
                                            {value: WALLET_OWNER_TYPE.BETSHOP, text: t('backoffice.common.betshop')}
                                        ] : [],
                                        ...!isFranchisingMode() ? [
                                            {value: WALLET_OWNER_TYPE.PLAYER, text: t('backoffice.common.player')}
                                        ] : [],
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.participantType')}`}
                                    onChange={value => setParticipant2Type(value)}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />

                            </Form.Item>

                        </Col>

                        <Col span={24}>
                            {
                                participant2Type && (
                                    <Form.Item
                                        label={
                                            participant2Type === WALLET_OWNER_TYPE.BETSHOP ? t('backoffice.reports.betshopNameOrId') :
                                                participant2Type === WALLET_OWNER_TYPE.AGENT || participant2Type === WALLET_OWNER_TYPE.AGENT_PLAYER ? t('backoffice.reports.usernameOrId') :
                                                    participant2Type === WALLET_OWNER_TYPE.PLAYER ? t('backoffice.reports.playerNameOrId') : ""
                                        }
                                        name="participant2"
                                    >
                                        <AutoComplete
                                            placeholder={`${t('backoffice.common.enter')} ${participant2Type === WALLET_OWNER_TYPE.BETSHOP ? t('backoffice.reports.betshopNameOrId') :
                                                participant2Type === WALLET_OWNER_TYPE.AGENT || participant2Type === WALLET_OWNER_TYPE.AGENT_PLAYER ? t('backoffice.reports.usernameOrId') :
                                                    participant2Type === WALLET_OWNER_TYPE.PLAYER ? t('backoffice.reports.playerNameOrId') : ""
                                            }`}
                                            items={(
                                                participant2Type === WALLET_OWNER_TYPE.BETSHOP ? betshopNames :
                                                    participant2Type === WALLET_OWNER_TYPE.AGENT ? agentNames :
                                                        //participant2Type === WALLET_OWNER_TYPE.AGENT_PLAYER ? agentPlayerNames :
                                                        participant2Type === WALLET_OWNER_TYPE.PLAYER ? playerNames : []
                                            )}
                                            changeHandler={participant2Type === WALLET_OWNER_TYPE.PLAYER ? handlePlayersChange : undefined}
                                            disableFiltering={participant2Type === WALLET_OWNER_TYPE.PLAYER}

                                            valueMapper={(items, value) => {
                                                if (participant2Type !== WALLET_OWNER_TYPE.PLAYER) {
                                                    return value;
                                                }
                                                const foundItem = items.find(item => item.longId?.toString() === value?.toString());

                                                if (foundItem) {
                                                    return foundItem.name;
                                                }

                                                return value;
                                            }}

                                            valueGetter={(items, value) => {
                                                if (participant2Type !== WALLET_OWNER_TYPE.PLAYER) {
                                                    return value;
                                                }

                                                const searchedItem = value !== "" ? items.find(item => (
                                                    item.longId?.toString() === value ||
                                                    item.name?.toLowerCase() === value.toLowerCase()
                                                )) || null : null;

                                                return searchedItem?.longId ?? value;
                                            }}
                                        />
                                    </Form.Item>
                                )
                            }

                            <Divider className='rt--filters-divider'/>
                        </Col>
                    </>
                    )
                    : (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.reports.betshopUsernameOrId')}
                                name="participant2"
                            >
                                <AutoComplete
                                    placeholder={t('backoffice.reports.betshopUsernameOrId')}
                                    items={betshopNames}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    valueMapper={(items, value) => {
                                        if (showParticipants) {
                                            return value;
                                        }

                                        const foundItem = items.find(item => item.id?.toString() === value?.toString());

                                        if (foundItem) {
                                            return foundItem.name;
                                        }

                                        return value;
                                    }}

                                    valueGetter={(items, value) => {
                                        if (showParticipants) {
                                            return value;
                                        }

                                        const searchedItem = value !== "" ? items.find(item => (
                                            item.id?.toString() === value ||
                                            item.name?.toLowerCase() === value.toLowerCase()
                                        )) || null : null;

                                        return searchedItem?.id ?? value;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                <Col span={24}>
                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                        <Form.Item
                            className='rt--form-item-inline rt--form-item-without-margin'
                            name="includeRelated"
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.reports.includeRelatedTransactions')}</span>
                    </div>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get agent transactions report */
    getAgentTransactionsReport: PropTypes.func,
    /** Redux action to set agent transactions report sorting details */
    setAgentTransactionsReportFilters: PropTypes.func,
    /** Redux state property, errors filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getAgentTransactionsReport: nextPage => {
            dispatch(getAgentTransactionsReport(nextPage));
        },
        setAgentTransactionsReportFilters: filters => {
            dispatch(setAgentTransactionsReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.agentTransactionsReport.filters,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
