import React, {useMemo} from 'react';

import { Tabs as AntTabs} from 'antd';

import {
    AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA, AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA_LITE,
    AGENT_REGISTRATIONS_STATISTICS_LINE_CHARTS_DATA,
} from "pages/dashboard/constants";
import FinancialLineChart from "pages/dashboard/components/financialLineChart";
import {useTranslation} from "react-i18next";
import OperationalLineChart from "pages/dashboard/components/operationalLineChart";
import useProjectType from "hooks/useProjectType";

const AgentStatisticsTab = ({ filters, updateLastUpdatedDate, hasOneCurrency }) => {
    const { hasRetail, hasLiteMode } = useProjectType();
    const { t } = useTranslation();

    const performanceLineChartItems = useMemo(() => {
        let configs = AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA;

        if (hasLiteMode) {
            configs = AGENT_PERFORMANCE_STATISTICS_LINE_CHARTS_DATA_LITE;
        }

        return (
            configs.map(data => ({
                key: data.id,
                label: t(data.title),
                children: (
                    <FinancialLineChart
                        key={data.id}
                        title={t(data.title)}
                        dataType={data.dataType}
                        playerLineColor={data.playerLineColor}
                        betshopLineColor={data.betshopLineColor}
                        disableLegend={data.disableLegend}
                        filters={filters}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                        calcTotalAmount={data.calcTotalAmount}
                        hasOneCurrency={hasOneCurrency}
                    />
                )
            }))
        );
    }, [filters, hasLiteMode, t, updateLastUpdatedDate]);

    const registrationLineChartItems = useMemo(() => (
        AGENT_REGISTRATIONS_STATISTICS_LINE_CHARTS_DATA.map(data => {
            if (data.onlyRetailEnabled && !hasRetail) {
                return null;
            }

            return ({
                key: data.id,
                label: t(data.title),
                children: (
                    <OperationalLineChart
                        filters={filters}
                        hasRetail={hasRetail}
                        entityType={data.entityType}
                        updateLastUpdatedDate={updateLastUpdatedDate}
                    />
                )
            });
        })
    ), [filters, t, updateLastUpdatedDate, hasRetail]);

    return (
        <div className="rt--dashboard-statistics-agent">
            <div>
                <span className="rt--dashboard-title">
                    {t("backoffice.dashboard.performance")}
                </span>

                <div className="rt--chart-section">
                    <AntTabs
                        animated={false}
                        destroyInactiveTabPane={true}
                        className='rt--tabs rt--chart-tab'
                        items={performanceLineChartItems}
                    />
                </div>
            </div>
            <div>
                <span className="rt--dashboard-title">
                    {t("backoffice.dashboard.registrations")}
                </span>

                <div className="rt--chart-section">
                    <AntTabs
                        animated={false}
                        destroyInactiveTabPane={true}
                        className='rt--tabs rt--chart-tab'
                        items={registrationLineChartItems}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgentStatisticsTab;
