import { useEffect, useCallback, useState } from "react";

import { load } from "js-yaml";

const fetchYaml = url => {
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.text();
        })
        .then(yamlText => load(yamlText))
        .catch(error => {
            console.error("Error fetching or parsing YAML:", error);
        });
}

const useTerminalVersion = () => {

    const [ version, setVersion ] = useState(null)

    const getVersion = useCallback(() => {
        fetchYaml(`${import.meta.env.SYSTEM_SSBT_UPDATE_URL}latest.yml?fake=${Math.random()}`)
            .then(yamlJson => {
                setVersion(yamlJson.version)
            })
    }, []);

    useEffect(() => {
		getVersion()
	}, []);

    return { version };
}

export default useTerminalVersion;