import React, {useEffect, useMemo} from 'react';
import {Col, Form, TreeSelect} from "antd";
import NativeTag from "components/common/nativeTag";
import {useTranslation} from "react-i18next";

const SubProjectSelectorItem = ({ formInstance, validator, currencyCode, subProjects }) => {
    const { t } = useTranslation();

    const filteredSupProjects = useMemo(() => (
        subProjects.filter(subProject => subProject.currencyCodes.includes(currencyCode))
    ), [currencyCode, subProjects]);

    useEffect(() => {
        return () => {
            formInstance.setFieldValue(["subProjects", currencyCode], []);
        }
    }, []);

    return (
        <Col xs={24} sm={12}>
            <Form.Item
                label={`${t('backoffice.registrationform.project')} (${currencyCode}) *`}
                name={["subProjects", currencyCode]}
                className='rt--general-form-item'
                rules={[
                    { validator }
                ]}
                validateFirst
            >
                <TreeSelect
                    treeCheckable
                    treeData={filteredSupProjects}
                    treeDefaultExpandAll
                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + "subProjectIds")}`}
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    suffixIcon={<i className="icon-down"/>}
                    maxTagCount="responsive"
                    tagRender={(props) => {
                        const subProject = filteredSupProjects.find((option) => option.value === props.value);
                        const firstDomain = subProject?.children[0];

                        return (
                            <NativeTag
                                {...props}
                                title={subProject?.children.map(child => child.title).join(", ")}
                                label={props.isMaxTag ? props.label : `${firstDomain?.title} (${subProject?.children.length})`}
                            />
                        );
                    }}
                />
            </Form.Item>
        </Col>
    );
};

export default SubProjectSelectorItem;
