import React, {useEffect} from 'react';
import SubProjectSelectorItem from "pages/agentSystem/agents/components/subProjectSelectorItem";
import {Form} from "antd";
import {useTranslation} from "react-i18next";

const SubProjectSelectors = ({ formInstance, subProjects }) => {
    const currencyCodes = Form.useWatch("currencyCodes", formInstance) ?? [];
    const subProjectsValue = Form.useWatch("subProjects", formInstance);

    const { t } = useTranslation();

    useEffect(() => {
        formInstance.validateFields(currencyCodes.map(currencyCode => ["subProjects", currencyCode]), { dirty: true })
    }, [subProjectsValue, currencyCodes]);

    const validator = () => {
        if (Object.values(subProjectsValue).some(subProject => subProject?.length)) {
            return Promise.resolve();
        }

        return Promise.reject(t("backoffice.validation.oneOfProjectFieldsShouldHaveAValue"));
    }

    return (
        currencyCodes.map(currencyCode => (
            <SubProjectSelectorItem
                key={currencyCode}
                formInstance={formInstance}
                validator={validator}
                currencyCode={currencyCode}
                subProjects={subProjects}
            />
        ))
    );
};

export default SubProjectSelectors;
