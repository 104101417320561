import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_PERMISSION_REQUESTS, SET_PERMISSION_REQUESTS_FILTERS
} from "../../../../actionTypes";

import {
    setPermissionRequestsActionBefore,
    setPermissionRequestsActionFinished,
    setPermissionRequestsSaveActionBefore,
    setPermissionRequestsSaveActionFinished
} from "./requests.action";

const setPermissionRequests = requests => ({
    type: SET_PERMISSION_REQUESTS,
    payload: { requests }
});

export const setPermissionRequestsFilters = filters => ({
    type: SET_PERMISSION_REQUESTS_FILTERS,
    payload: { filters }
})

export const getPermissionRequests = (objectId, actionTypes, objectType) => {
    return (dispatch, getState) => {
        dispatch(setPermissionRequestsActionBefore());

        const filters = { ...getState().permissionRequests.pending.filters };

        if (objectId) {
            filters.objectId = objectId;
        }

        if (objectType) {
            filters.objectType = objectType;
        }

        if (actionTypes) {
            filters.actionTypes = actionTypes;
        } else if (filters.actionType) {
            filters.actionTypes = [filters.actionType];
        }

        let actionTypesStr = "";

        if (filters.actionTypes?.length > 0) {
            filters.actionTypes.forEach(type => {
                actionTypesStr += `${filters.actionTypes !== "" ? "&" : ""}actionTypes=${type}`
            })
        }

        delete filters.actionTypes;
        delete filters.actionType;

        return axios({
            url: `${ApiUrls.GET_PERMISSION_REQUESTS}?${actionTypesStr}`,
            method: Methods.GET,
            params: {
                ...filters
            }
        })
        .then(({data : {value: requests}}) => {
            dispatch(setPermissionRequests(requests))
            dispatch(setPermissionRequestsActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionRequestsActionFinished());
        })
    }
}

export const approvePermissionRequest = ids => {
    return dispatch => {
        dispatch(setPermissionRequestsSaveActionBefore());
        return axios({
            url: ApiUrls.APPROVE_PERMISSION_REQUEST,
            method: Methods.POST,
            data: {
                ids
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPermissionRequests(data.value.accessManagerPendingData));
            }
            dispatch(setPermissionRequestsSaveActionFinished());

            return data.value;
        })
        .catch((ex) => {
            dispatch(setPermissionRequestsSaveActionFinished());
        })
    }
}

export const rejectPermissionRequest = (ids, note) => {
    return dispatch => {
        dispatch(setPermissionRequestsSaveActionBefore());
        return axios({
            url: ApiUrls.REJECT_PERMISSION_REQUEST,
            method: Methods.POST,
            data: {
                ids, note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPermissionRequests(data.value.accessManagerPendingData));
            }

            dispatch(setPermissionRequestsSaveActionFinished());

            return data.value;
        })
        .catch((ex) => {
            dispatch(setPermissionRequestsSaveActionFinished());
        })
    }
}
