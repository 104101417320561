import React, {useMemo} from 'react';
import NativeTag from "components/common/nativeTag";
import {TreeSelect} from "antd";
import {useTranslation} from "react-i18next";
import {mapSubProjectsToTree} from "utils/subProjects";

const SubProjectItem = ({ currency, subProjects = [], value = [], onChange }) => {
    const { t } = useTranslation();

    const treeSubProjects = useMemo(() => mapSubProjectsToTree(subProjects), [subProjects])

    return (
        <div className="rt--agent-commission-item">
            <label>{currency.currency}</label>

            <TreeSelect
                value={value}
                treeCheckable
                treeData={treeSubProjects}
                treeDefaultExpandAll
                placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + "subProjectIds")}`}
                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                suffixIcon={<i className="icon-down"/>}
                maxTagCount="responsive"
                onChange={onChange}
                tagRender={(props) => {
                    const subProject = treeSubProjects.find((option) => option.value === props.value);
                    const firstDomain = subProject?.children[0];

                    return (
                        <NativeTag
                            {...props}
                            title={subProject?.children.map(child => child.title).join(", ")}
                            label={props.isMaxTag ? props.label : `${firstDomain?.title} (${subProject?.children.length})`}
                        />
                    );
                }}
            />
        </div>
    );
};

export default SubProjectItem;
