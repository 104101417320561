import React from "react"
import { useParams } from "react-router-dom";

import { getWorkingHoursConfigs, saveWorkingHoursConfigs } from "../api";

import WorkingHours from "components/common/workingHours";

const WorkingHoursConfigs = ({
    onTabChange
}) => {
    const searchParams = useParams();

    return (
        <WorkingHours 
            onTabChange={onTabChange}
            getWorkingHoursConfigs={() => getWorkingHoursConfigs(searchParams.id)}
            saveWorkingHoursConfigs={data => saveWorkingHoursConfigs(data, searchParams.id)}
        />
    )
}

export default WorkingHoursConfigs;