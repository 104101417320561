import React from 'react';
import {LEADERBOARD_TYPE} from "components/common/leaderboard/constants";
import Leaderboard from "components/common/leaderboard";
import useFinancialLeaderboard from "pages/dashboard/hooks/useFinancialLeaderboard";
import {FINANCIAL_DASHBOARD_FILTER_NAMES} from "pages/dashboard/constants";

const UsersLeaderboardFinancial = ({ title, endpoint, filters, updateLastUpdatedDate, hasOneCurrency }) => {
    const { leaderBoardData, isLoading } = useFinancialLeaderboard(filters.dataType, filters, updateLastUpdatedDate, endpoint);

    return (
        <Leaderboard
            leaders={leaderBoardData}
            isLoading={isLoading}
            title={title}
            currencyCode={filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]}
            type={LEADERBOARD_TYPE.FINANCIAL}
            hasOneCurrency={hasOneCurrency}
        />
    );
};

export default UsersLeaderboardFinancial;
