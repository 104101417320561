import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getTerminalGeneralInfo = id => {
    return axios({
        url: `${ApiUrls.GET_TERMINAL_GENERAL_INFO}`,
        method: Methods.GET,
        params: {
            id
        }
    }).then(({data: {value}}) => {
        return value
    });
}

export const saveTerminalGeneralInfo = data => {
    return axios({
        url: `${ApiUrls.SAVE_TERMINAL_GENERAL_INFO}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return { value, message };
    });
}

export const changeTerminalState = data => {
    return axios({
        url: `${ApiUrls.CHANGE_TERMINAL_STATE}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return { value, message };
    });
}

export const updateTerminal = data => {
    return axios({
        url: `${ApiUrls.UPDATE_TERMINAL}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return { value, message };
    });
}
