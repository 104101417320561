import React from 'react';
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Switch } from "antd";

import Section from "components/common/section";
import NumericInput from 'components/common/numericInput';

const IdleConfigs = ({ 
    hasModifyPermission 
}) => {
    const { t } = useTranslation();

    return (
        <Section title={t("backoffice.projects.idleConfig")}>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={`${t('backoffice.projects.inactivityTimeInMinutes')} *`}
                        name="inactivationTime"
                        className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.inactivityTimeInMinutes')}`}
                        rules={[
                            { required: true, message: t('backoffice.validation.fieldRequired') },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value !== null && value !== "") {
                                        if (Number(value) > 999 || Number(value) < 1) {
                                            return Promise.reject(
                                                t("backoffice.validation.mustBeBetween")
                                                    .replace("%X%", 1)
                                                    .replace("%Y%", 999)
                                            );
                                        } else {
                                            return Promise.resolve()
                                        }
                                    }
                                    return Promise.resolve()
                                }
                            })
                        ]}
                        validateFirst
                    >
                        <NumericInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.inactivityTimeInMinutes')}`}
                            disabled={!hasModifyPermission}
                            isInteger={true}
                            maxLength={4}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                        <Form.Item
                            name="idleSessionConfirmation"
                            valuePropName="checked"
                            className='rt--form-item-without-margin'
                        >
                            <Switch
                                disabled={!hasModifyPermission }
                            />
                        </Form.Item>
                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.projects.inactivityWarning')}</label>
                    </div>

                </Col>
            </Row>
        </Section>
    );
};

export default IdleConfigs;
