import React, {useEffect} from 'react';
import Section from "components/common/section";
import {useTranslation} from "react-i18next";
import {Col, Form, Row} from "antd";
import CheckboxItem from "pages/projects/edit/sections/terminal/main/components/generalConfigs/checkboxItem";

const GeneralConfigs = ({ 
    formInstance,
    hasModifyPermission 
}) => {
    const scanVoucherOnTerminal = Form.useWatch("scanVoucherOnTerminal", formInstance);
    const issueVoucherByCashier = Form.useWatch("issueVoucherByCashier", formInstance);

    const { t } = useTranslation();

    const handleScanVoucherChange = ({ target: { checked } }) => {
        if (!checked) {
            formInstance.setFieldsValue({
                issueVoucherByCashier: false,
                reprintVoucher: false
            });
        }
    }

    const handleIssueVoucherChange = ({ target: { checked } }) => {
        if (!checked) {
            formInstance.setFieldsValue({
                reprintVoucher: false
            });
        }
    }

    return (
        <Section title={t("backoffice.projects.configuration")}>
            <Row gutter={[16, 0]}>
                <Col xs={24} sm={24} xl={14}>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <div className='rt--checkbox-group-list'>
                                <CheckboxItem key={"enableCash"} name={"enableCash"} disabled={!hasModifyPermission}/>
                                <CheckboxItem key={"depositWinningOnlineBalance"} name={"depositWinningOnlineBalance"} disabled={!hasModifyPermission} />
                                <CheckboxItem key={"topUpBalanceWithTicket"} name={"topUpBalanceWithTicket"} disabled={!hasModifyPermission} />
                                <CheckboxItem key={"partialWithdrawal"} name={"partialWithdrawal"} disabled={!hasModifyPermission} />
                                <CheckboxItem key={"withdrawWithVoucher"} name={"withdrawWithVoucher"} disabled={!hasModifyPermission} />
                            </div>
                        </Col>
                        <Col xs={24} md={12} xl={12}>
                            <div className='rt--checkbox-group-list'>
                                <CheckboxItem key={"withdrawToOnlineBalance"} name={"withdrawToOnlineBalance"} mb={8} disabled={!hasModifyPermission} />
                                <CheckboxItem key={"allowBlockTerminal"} name={"allowBlockTerminal"} hasInfo={false} mb={8} disabled={!hasModifyPermission} />

                                <CheckboxItem
                                    key={"scanVoucherOnTerminal"}
                                    name={"scanVoucherOnTerminal"}
                                    mb={8}
                                    hasInfo={false}
                                    onChange={handleScanVoucherChange}
                                    disabled={!hasModifyPermission}
                                />
                                <div className="rt--pl-24">
                                    <CheckboxItem
                                        key={"issueVoucherByCashier"}
                                        name={"issueVoucherByCashier"}
                                        disabled={!hasModifyPermission || !scanVoucherOnTerminal}
                                        mb={8}
                                        onChange={handleIssueVoucherChange}
                                    />
                                    <div className="rt--pl-24">
                                        <CheckboxItem
                                            key={"reprintVoucher"}
                                            name={"reprintVoucher"}
                                            disabled={!hasModifyPermission || !issueVoucherByCashier}
                                            mb={8}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Section>
    );
};

export default GeneralConfigs;
