import React, {useMemo, useState} from 'react';
import { message } from "antd";

import { useTranslation } from "react-i18next";
import useTerminalVersion from 'hooks/useTerminalVersion';

import EntityActions from 'components/common/entityActions';

import { TERMINAL_STATUS } from "constants/terminal.constants";

import { changeTerminalState, updateTerminal } from "pages/retail/terminals/edit/sections/general/api";

const TerminalActions = ({ terminal, onStatusChange }) => {

    const { t } = useTranslation();

    const { version } = useTerminalVersion()

    const canDeactivate = terminal.status === TERMINAL_STATUS.ACTIVE || terminal.status === TERMINAL_STATUS.BLOCKED;
    const canBlock = terminal.status === TERMINAL_STATUS.ACTIVE;
    const canUnblock = terminal.status === TERMINAL_STATUS.BLOCKED;
    const canUpdate = terminal.version !== version && version && terminal.status;

    const terminalRequestInfo = useMemo(() => ({
        id: terminal.id
    }), [terminal])

    const changeState = (state) => {
        changeTerminalState({
            ...terminalRequestInfo,
            status: state
        })
            .then(({ message: successMessage }) => {
                message.success(successMessage);
                onStatusChange(state)
            })
    }

    const update = () => {
        updateTerminal({ updateVersion: version, id: terminal.id })
            .then(({ message: msg }) => {
                message.success(msg)
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    /** Actions list */
    const actions = [
        /** update */
        {
            key: "update",
            buttonType: "primary",
            buttonClassName: "rt--button-primary",
            title: t('backoffice.projects.update'),
            icon: "icon-reload",
            isConfirmation: true,
            confirmationMessage: t("backoffice.terminals.updateMessage"),
            disabled: !canUpdate,
            onClick: update
        },
        /** deactivate */
        {
            key: "deactivate",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.terminals.deactivate'),
            icon: "icon-deactivate",
            isConfirmation: true,
            confirmationMessage: t("backoffice.terminals.deactivateMessage"),
            disabled: !canDeactivate,
            onClick: () => changeState(TERMINAL_STATUS.INACTIVE)
        },
        /** Block */
        {
            key: "block",
            buttonType: "secondary",
            title: t('backoffice.common.block'),
            buttonClassName: "rt--button-danger",
            onClick: () => changeState(TERMINAL_STATUS.BLOCKED),
            icon: "icon-block2",
            isConfirmation: true,
            confirmationMessage: t("backoffice.terminals.blockMessage"),
            disabled: !canBlock
        },

        /** Block */
        {
            key: "unblock",
            buttonType: "primary",
            title: t('backoffice.common.unblock'),
            buttonClassName: "rt--button-secondary",
            onClick: () => changeState(TERMINAL_STATUS.ACTIVE),
            icon: "icon-unblock",
            isConfirmation: true,
            confirmationMessage: t("backoffice.terminals.unblockMessage"),
            disabled: !canUnblock
        }
    ]

    return (
        <EntityActions 
            actions={actions}
        />
    );
};

export default TerminalActions;
