//#region react
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getWalletHistory,
    setWalletHistorySorting,
    setWalletHistoryFilters
} from "store/actions/dashboard/wallet/history.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import Table from "components/common/table";
import TabTableDashboardLayout from "components/layouts/tab/table";
import WalletBalanceChanges from "components/common/walletBalanceChanges";
import Filters from "./filters.component";
import Wallets from "../wallets";
//#endregion

//#region utils
import { getUser } from 'utils/auth';
import { hasPermission } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { isMobile } from "utils/common";
//#endregion

//#region constants
import {
    PERMISSION_ACTION,
    PERMISSION_RESOURCE
} from 'constants/permissions.constants';
import {
    WALLET_OWNER_TYPE,
    WALLET_OPERATION_TYPE,
    WALLET_TRANSACTION_TYPE,
} from "constants/wallet.constants";
import { EXPAND_TABLE_TYPES } from "constants/common.constants";
import ApiUrls from 'constants/api.constants';
import { USER_ROLE, USER_TYPE } from "constants/user.constants";
import { WALLET_HISTORY } from "constants/pageName.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import walletTransferType from "types/wallet/transfer.type";
import { SEARCH_TYPE } from "components/common/search/constants";
import useProjectType from "hooks/useProjectType";
import { TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME, useTransfer } from "components/common/transfer";
import {getAgentCurrencies} from "store/actions/dashboard/agentSystem/agents/currencies.action";
import {getProjectCurrencies} from "store/actions/dashboard/projects/currencies.action";
import useFormat from "hooks/useFormat";
//#endregion

const ADJUSTABLE_OPERATIONS = [
    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT,
    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT,
    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT,
    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT,
    WALLET_OPERATION_TYPE.AGENT_GIVE_CREDIT,
    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH,
    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_NON_CASH,
    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_FROM_BALANCE,
    WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH,
    WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT,
    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_TO_CREDIT_LINE,
    WALLET_OPERATION_TYPE.AGENT_WITHDRAW_FROM_CREDIT_LINE,
];

const ROW_UNIQUE_KEY_PROP = "transactionId";
const EXPANDED_ROW_UNIQUE_KEY_PROP = "type";

const getPermissionResourceByOwnerType = (ownerType) => {
    if (ownerType === WALLET_OWNER_TYPE.SUPER_AGENT) {
        return PERMISSION_RESOURCE.PROJECT_WALLETS;
    }

    if (ownerType === WALLET_OWNER_TYPE.AGENT || ownerType === WALLET_OWNER_TYPE.AGENT_PLAYER) {
        return PERMISSION_RESOURCE.AGENT_WALLETS;
    }

    return PERMISSION_RESOURCE.BETSHOP_WALLETS;
}

/** Wallet Transfer History Tab Component */
const WalletHistoryComponent = ({
    getWalletHistory,
    getAgentCurrencies,
    getProjectCurrencies,
    setWalletHistorySorting,
    setWalletHistoryFilters,
    isLoading,
    transfers,
    total,
    sorting,
    filters,
    ownerType,
    agentCurrencies,
    projectCurrencies,
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const { show: showTransfer} = useTransfer();

    const { hasLiteMode } = useProjectType();

    const { formatAmount, formatCurrencyWithSymbol } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: WALLET_HISTORY });

    const isMobileDevice = isMobile();

    const { role, userName } = getUser();

    const currencies = useMemo(() => {
        let res = [{ text: t('backoffice.common.all'), value: '' }];

        switch (ownerType) {
            case WALLET_OWNER_TYPE.AGENT:
                res = [...res,...agentCurrencies.map(currency => ({ text: currency.currency.toUpperCase(), value: currency.currency.toUpperCase() }))];
                break;
            case WALLET_OWNER_TYPE.SUPER_AGENT:
                res = [...res,...projectCurrencies.map(currency => ({ text: currency.code.toUpperCase(), value: currency.code.toUpperCase() }))]
                break;
            default:
                res = [];
        }

        return res;
    }, [t, ownerType, agentCurrencies, projectCurrencies]);

    const hasOneCurrency = useMemo(() => {
        const filteredCurrencies = currencies.filter(currency => currency.value !== '');

        return filteredCurrencies.length === 1;
    }, [currencies])

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasProjectWalletsModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_WALLETS,
        action: PERMISSION_ACTION.MODIFY
    })

    const permissionResource = getPermissionResourceByOwnerType(ownerType);

    const hasDataExportPermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasWalletModifyPermission = hasPermission({
        resource: permissionResource,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setWalletHistoryFilters({
            ...filters,
            transactionId: value
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                t,
                hasLiteMode,
                hasOneCurrency,
                formatAmount,
                formatCurrencyWithSymbol,
                currencyCode: currencies[1]?.value
            }
        });
    }, [includedColumns, t, hasLiteMode, hasOneCurrency, formatAmount, formatCurrencyWithSymbol, currencies])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (role !== USER_ROLE.ADMIN || hasWalletModifyPermission) {
        tableRowActions.push({
            title: t("backoffice.wallet.rollback"),
            icon: "icon-rollback",
            onClick: data => {
                showTransfer({
                    entityType: TRANSFER_ENTITY_TYPE.TRANSACTION,
                    transactionType: WALLET_TRANSACTION_TYPE.ROLLBACK,
                    additionalData: {
                        groupId: data.groupId,
                        amount: data.transactionAmount
                    }
                })
            },
            disabled: record => (
                record.associatedTransactionId ||
                !ADJUSTABLE_OPERATIONS.includes(record.transactionType) ||
                (record.userRole === USER_TYPE.ADMIN && role !== USER_ROLE.ADMIN) ||
                ((record.userRole === USER_TYPE.AGENT || record.userRole === USER_TYPE.AGENT_PLAYER) && userName !== record.userName)
            )
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters ownerType={ownerType} currencies={currencies} hasOneCurrency={hasOneCurrency} />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "icon-refill",
            type: "primary",
            text: t("backoffice.wallet.adjustBalance"),
            onClick: () => {
                showTransfer({
                    entityType: TRANSFER_ENTITY_TYPE.SUPER_AGENT
                })
            },
            enabled: (
                hasProjectWalletsModifyPermission &&
                role === USER_ROLE.ADMIN &&
                ownerType === WALLET_OWNER_TYPE.SUPER_AGENT
            ),
        },
        search: {
            type: SEARCH_TYPE.NUMERIC,
            placeholder: t("backoffice.wallet.transactionId"),
            onSearch: handleSearchChange,
            loadFn: () => getWalletHistory(searchParams.id, ownerType),
            numeric: true,
            maxLength: 12,
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.wallet.walletHistory"),
            url: `${ApiUrls.EXPORT_WALLET_HISTORY}?ownerId=${searchParams.id}&ownerType=${ownerType}`,
            filters: filters
        }
    }

    if (isMobileDevice && ownerType !== WALLET_OWNER_TYPE.BETSHOP && !hasOneCurrency) {
        headerPartsData.content = <Wallets ownerType={ownerType} />
    }

    //#endregion

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                getWalletHistory(searchParams.id, ownerType);
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, []);

    useEffect(() => {
        if (ownerType === WALLET_OWNER_TYPE.AGENT) {
            getAgentCurrencies(searchParams.id);
        } else if (ownerType === WALLET_OWNER_TYPE.SUPER_AGENT) {
            getProjectCurrencies();
        }
    }, [getAgentCurrencies, getProjectCurrencies, ownerType, searchParams.id])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={
                (!isMobileDevice && ownerType !== WALLET_OWNER_TYPE.BETSHOP && !hasOneCurrency) && (
                    <Wallets ownerType={ownerType} />
                )
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={transfers}
                loadFn={nextPage => getWalletHistory(searchParams.id, ownerType, nextPage)}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                sorting={sorting}
                setSortingFn={setWalletHistorySorting}
                total={total}
                actions={tableRowActions}
                expandable={{
                    title: t("backoffice.wallet.transaction"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    expandAll: true,
                    details: record => Boolean(record.changes) && ({
                        data: record.changes,
                        uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
                        content: (
                            <WalletBalanceChanges
                                balanceChanges={record.changes}
                                transactionTime={record.transactionTime}
                                currencyCode={record.currencyCode}
                            />
                        ),
                    }),
                }}
            />

        </TabTableDashboardLayout>
    );
};

/** WalletHistoryComponent propTypes
 * PropTypes
*/
WalletHistoryComponent.propTypes = {
    /** Redux action to get wallet history */
    getWalletHistory: PropTypes.func,
    /** Redux state property, represents the array of wallet transfers  */
    transfers: PropTypes.arrayOf(walletTransferType),
    /** Redux state property, wallet transfers total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading wallet history */
    isLoading: PropTypes.bool,
    /** Redux action to set wallet transfers history sorting details */
    setWalletHistorySorting: PropTypes.func,
    /** Redux action to set wallet transfers history filters */
    setWalletHistoryFilters: PropTypes.func,
    /** Redux state property, betshop transactions sorting details */
    sorting: sortingType,
    /** Redux state property, betshops transactions filters */
    filters: PropTypes.object,
    /** Wallet owner type */
    ownerType: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getWalletHistory: (ownerId, ownerType, nextPage) => {
        dispatch(getWalletHistory(ownerId, ownerType, nextPage))
    },
    setWalletHistorySorting: sorting => {
        dispatch(setWalletHistorySorting(sorting));
    },
    setWalletHistoryFilters: filters => {
        dispatch(setWalletHistoryFilters(filters));
    },
    getAgentCurrencies: id => {
        dispatch(getAgentCurrencies(id));
    },
    getProjectCurrencies: () => {
        dispatch(getProjectCurrencies());
    }
});

const mapStateToProps = state => {
    return {
        isLoading: state.wallet.isLoading,
        transfers: state.wallet.history.transfers,
        total: state.wallet.history.total,
        sorting: state.wallet.history.sorting,
        filters: state.wallet.history.filters,
        agentCurrencies: state.agents.edit.currencies.currencies,
        projectCurrencies: state.projects.edit.currencies
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WalletHistoryComponent);
