import React, {useMemo} from 'react';

import FiltersWrapper from "components/common/filters";
import {Form} from "antd";
import {getSelectFieldOptions} from "utils/dashboard";
import {DATA_TYPE_TRANSLATION_SOURCE, DATA_TYPES, LEADERBOARD_TYPES, TIME_PERIOD} from "pages/dashboard/constants";
import Select from "components/common/select";
import mapFiltersToScopes from "pages/dashboard/tabs/usersTab/filters/mobile/helpers/mapFiltersToScopes";
import getCorrespondingDataTypes from "pages/dashboard/helpers/getCorrespondingDataTypes";
import useProjectType from "hooks/useProjectType";
import {FILTER_TYPE} from "constants/common.constants";
import {useTranslation} from "react-i18next";
import {useDashboardContext} from "pages/dashboard/contexts/dashboardContext";
import getDataTypeOptions from "pages/dashboard/helpers/getDataTypeOptions";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {isFranchisingMode} from "utils/common";
import isOperationalDataType from "pages/dashboard/helpers/isOperationalDataType";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const UsersFiltersMobile = ({ filters, onChange, currencyOptions, hasOneCurrency }) => {
    const { state: { leaderboardTab } } = useDashboardContext();

    const { t } = useTranslation();
    const { hasLiteMode, hasRetail, hasAgentSystem } = useProjectType();

    const [ betshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: isFranchisingMode()
    });

    const mappedFilters = useMemo(() => ({ ...filters.global,...filters.leaderboard }), [filters]);

    const isBetShopOwner = isFranchisingMode() && getUser().role === USER_ROLE.AGENT;
    const hasSelectedBetShop = !!mappedFilters.betShopNameOrId;

    const betShopOptions = useMemo(() => ([
        {
            text: t('backoffice.dashboard.allBetshops'),
            value: ''
        },
        ...betshopNames.map(betshop => ({
            text: betshop.name,
            value: betshop.id
        }))
    ]), [betshopNames, t]);

    const dataTypeOptions = getDataTypeOptions(leaderboardTab, hasLiteMode, hasRetail).filter(option => option.value !== DATA_TYPES.COMMISSIONS);

    const showCurrency = !isOperationalDataType(mappedFilters.dataType) && !hasOneCurrency;

    const handleFilterChange = (values) => {
        onChange(mapFiltersToScopes(filters, values));
    }

    return (
        <FiltersWrapper
            loadFn={() => { }}
            setFiltersFn={handleFilterChange}
            updateProps={[]}
            filters={mappedFilters}
        >
            {hasAgentSystem && !isBetShopOwner && !hasSelectedBetShop && (
                <Form.Item
                    name="filterType"
                    label={t("backoffice.dashboard.type")}
                >
                    <Select
                        options={[
                            {value: FILTER_TYPE.DIRECT, text: t("backoffice.dashboard.direct")},
                            {value: FILTER_TYPE.NETWORK, text: t("backoffice.dashboard.network")},
                        ]}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    />
                </Form.Item>
            )}

            {isFranchisingMode() && leaderboardTab === LEADERBOARD_TYPES.CASHIER && (
                <Form.Item
                    name="betShopNameOrId"
                    label={t("backoffice.dashboard.betShop")}
                >
                    <Select
                        placeholder={t('backoffice.dashboard.allBetshops')}
                        options={betShopOptions}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        search
                    />
                </Form.Item>
            )}

            <Form.Item
                name="dataType"
                label={t("backoffice.dashboard.by")}
            >
                <Select
                    options={dataTypeOptions}
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                />
            </Form.Item>

            {showCurrency && (
                <Form.Item
                    name="currencyCode"
                    label={t("backoffice.dashboard.currency")}
                >
                    <Select
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        options={[
                            ...currencyOptions
                        ]}
                    />
                </Form.Item>
            )}

            <Form.Item
                name="timePeriod"
                label={t("backoffice.dashboard.date")}
            >
                <Select
                    options={
                        [
                            { value: TIME_PERIOD.TODAY, text: t("backoffice.dashboard.today") },
                            { value: TIME_PERIOD.YESTERDAY, text: t("backoffice.dashboard.yesterday") },
                            { value: TIME_PERIOD.THIS_WEEK, text: t("backoffice.dashboard.thisWeek") },
                            { value: TIME_PERIOD.LAST_WEEK, text: t("backoffice.dashboard.lastWeek") },
                            { value: TIME_PERIOD.THIS_MONTH, text: t("backoffice.dashboard.thisMonth") },
                            { value: TIME_PERIOD.LAST_MONTH, text: t("backoffice.dashboard.lastMonth") },
                            { value: TIME_PERIOD.LAST_QUARTER, text: t("backoffice.dashboard.lastQuarter") },
                            { value: TIME_PERIOD.THIS_YEAR, text: t("backoffice.dashboard.ytd") },
                            { value: TIME_PERIOD.LAST_YEAR, text: t("backoffice.dashboard.lastYear") },
                        ]
                    }
                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                />
            </Form.Item>
        </FiltersWrapper>
    );
};

export default UsersFiltersMobile;
