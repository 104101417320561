import React from 'react';

const Section = ({ title = "", actions, children }) => {
    return (
        <div className='rt--section'>
            <div className='rt--section-header'>
                <span className='rt--section-header-title'>{title}</span>

                <div className="rt--section-header-actions">
                    {actions}
                </div>
            </div>

            <div className="rt--section-content">
                {children}
            </div>
        </div>
    );
};

export default Section;
