import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Form, Button, Drawer } from 'antd';

import Modal from "components/common/modal";

import { isMobile, isRTL, countDifferentProperties } from "utils/common";
import { isFormChanged } from "utils/form";

import LanguageUtils from 'utils/languages';

import useFirstRender from 'hooks/useFirstRender';
import useDate from 'hooks/useDate';

/** Table Filters Component */
const Filters = forwardRef(({
    loadFn,
    setFiltersFn,
    filters,
    children,
    updateProps=[],
    resetFields,
    datePickerFields=[],
    searchFieldName,
    onReset,
    onInit,
    disabled,
    disableDataLoadOnReset=false
}, ref) => {

    const { t } = useTranslation();

    const currentLn = LanguageUtils.getSelectedLanguage();

    const { dateService } = useDate();

    const [formInstance] = Form.useForm();
    const { setFieldsValue, getFieldsValue } = formInstance;

    const [isFiltersTouched, setIsFiltersTouched] = useState(false);
    const [canApply, setCanApply] = useState(false);

    const [active, setActive] = useState(false);

    const [initial, setInitial] = useState(filters);

    /** State to detect when form related data like autosuggestion should be loaded */
    const notOpenedYet = useRef(true);

    useImperativeHandle(ref, () => ({
        setFieldValue: formInstance.setFieldValue
    }), [formInstance]);

    useEffect(() => {
        if(active){
            if(notOpenedYet.current){
                onInit && onInit();
            }
            notOpenedYet.current = false;
        }
    }, [active])

    useFirstRender({
        dependencies: updateProps,
        afterFirstRenderCB: () => {
            doReset(false);
            notOpenedYet.current = true;
        }
    })

    /** Reset to initial on component unmount */
    useEffect(() => {
        return () => resetFilters()
    }, [])

    /** Update field values when filters updated from outside */
    useEffect(() => {
        if (active) {
            const updated = {
                ...filters,
                ...makeDatePickerValues(filters)
            }
            setFieldsValue(updated);

            setIsFiltersTouched(isFiltersChanged(updated));
        }

    }, [filters, active])


    /** Function to make date picker values
       * @function
       * @param {object} values
       * @returns {object}
       * @memberOf Filters
   */
    const makeDatePickerValues = values => {
        const result = {};

        datePickerFields.forEach(field => {
            const fieldName = field.name;
            const fieldNameFrom = field.name === "date" ? "from" : fieldName + "From";
            const fieldNameTo = field.name === "date" ? "to" : fieldName + "To";
            result[fieldName] = [];
            result[fieldName][0] = values[fieldNameFrom] ? dateService.getDate(values[fieldNameFrom]) : "";
            result[fieldName][1] = values[fieldNameTo] ? dateService.getDate(values[fieldNameTo]) : "";
        })

        return result;
    }

    /** Reset Filters
       * @function
       * @memberOf Filters
   */
    const resetFilters = () => {
        const values = {
            ...initial,
        }

        const searchField = Array.isArray(searchFieldName) ? searchFieldName : searchFieldName ? [searchFieldName] : searchFieldName;
        if(searchField){
            searchField.forEach(field => {
                values[field] = filters[field];
            })
        }
        setFiltersFn(values);
    };

    /** Funtion, handle form values, and make them according to filters
       * @function
       * @memberOf Filters
   */
    const getDataFromFormValues = () => {
        const data = { ...getFieldsValue() };
        datePickerFields.forEach(field => {
            const fieldName = field.name;
            const fieldNameFrom = field.name === "date" ? "from" : fieldName + "From";
            const fieldNameTo = field.name === "date" ? "to" : fieldName + "To";

            let fromValue = "", toValue = "";

            if(data[fieldName]){
                fromValue = data[fieldName][0] ? data[fieldName][0].clone() : "";
                toValue = data[fieldName][1] ? data[fieldName][1].clone() : "";
            }

            if(field.time){
                if(fromValue){
                    fromValue = dateService.toISOString(fromValue)
                }

                if(toValue){
                    toValue = dateService.toISOString(toValue)
                }
            } else {
                if(fromValue){
                    fromValue = dateService.toString(fromValue, "YYYY-MM-DD")
                }

                if(toValue){
                    toValue = dateService.toString(toValue, "YYYY-MM-DD")
                }
            }

            delete data[fieldName];

            if(fromValue){
                data[fieldNameFrom] = fromValue;
            }

            if(toValue){
                data[fieldNameTo] = toValue;
            }

        })
        return data;
    }

    /** Funtion, fires on submit button click
       * @function
       * @memberOf Filters
   */
    const doFilter = () => {
        const values = {
            ...getDataFromFormValues(),
        }

        const searchField = Array.isArray(searchFieldName) ? searchFieldName : searchFieldName ? [searchFieldName] : searchFieldName;
        if(searchField){
            searchField.forEach(field => {
                values[field] = filters[field];
            })
        }

        setFiltersFn(values);
        setActive(false);
        setCanApply(false);
        setTimeout(() => {
            loadFn()
        }, 0)
    };

    /** Funtion, fires on reset button click
       * @function
       * @param {boolean} loadData - if should load new data after reset
       * @memberOf Filters
   */
    const doReset = loadData => {
        resetFilters();
        setTimeout(() => {
            const values = {
                ...initial,
                ...makeDatePickerValues(initial)
            }
            setFieldsValue(values);
            if (loadData && !disableDataLoadOnReset) {
                setCanApply(false);
                loadFn();
            }
            onReset && onReset();
            setIsFiltersTouched(false);
        }, 0)
    }

    /** Check is filters changed
       * @function
       * @returns {boolean}
       * @memberOf Filters
   */
    const isFiltersChanged = values => {
        const updated = values ? values : { ...getDataFromFormValues() };
        return isFormChanged(initial, updated)
    }


    /** Function , fires on form value change
       * @function
       * @returns {object} changed - name-value mapping for changed field
       * @memberOf Filters
   */
    const handleFormValuesChange = changed => {
        if(resetFields){
            const fields = Object.keys(resetFields);
            const changedKey = Object.keys(changed)[0];
            if(fields.includes(changedKey)){
                setFieldsValue({[resetFields[changedKey]] : initial[resetFields[changedKey]]})
            }
        }
        setIsFiltersTouched(isFiltersChanged());
        setCanApply(true);
    }

    /** Function , to get applied filters count
       * @function
       * @returns {number}
       * @memberOf Filters
   */
    const getAppliedFiltersCount = () => {
        const obj1 = { ...initial };
        const obj2 = { ...filters };

        datePickerFields.forEach(field => {
            const fieldName = field.name;

            const fieldNameFrom = field.name === "date" ? "from" : fieldName + "From";
            const fieldNameTo = field.name === "date" ? "to" : fieldName + "To";
            obj1[fieldNameFrom] = obj1[fieldNameFrom] ?? "";
            obj1[fieldNameTo] = obj1[fieldNameTo] ?? "";
            obj2[fieldNameFrom] = obj2[fieldNameFrom] ?? "";
            obj2[fieldNameTo] = obj2[fieldNameTo] ?? "";

            obj1[fieldName] = obj1[fieldNameFrom] + "_" + obj1[fieldNameTo];
            obj2[fieldName] = obj2[fieldNameFrom] + "_" + obj2[fieldNameTo];
            delete obj1[fieldNameFrom];
            delete obj1[fieldNameTo];
            delete obj2[fieldNameFrom];
            delete obj2[fieldNameTo]
        })

        return countDifferentProperties(obj1, obj2, Array.isArray(searchFieldName) ? searchFieldName : searchFieldName ? [searchFieldName] : searchFieldName)
    }

    /** Applied filters count */
    const appliedFiltersCount = getAppliedFiltersCount();

    /** Function , to render form
       * @function
       * @returns {JSX}
       * @memberOf Filters
   */
    const renderForm = () => (
        <Form
            colon={false}
            form={formInstance}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                ...initial,
                ...makeDatePickerValues(initial)
            }}
            onValuesChange={handleFormValuesChange}
        >
            { children }
        </Form>
    )

    return (
        <div className={"rt--filters " + (isMobile() ? "rt--ml-8" : "rt--ml-16")}>
            <Button
                className="rt--button rt--button-secondary rt--filters-button"
                type={ active ? "primary" : "secondary" }
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setActive(!active)
                }}
                disabled={disabled}
            >
                <i className="icon-filter rt--font-bigest" />

                {
                    appliedFiltersCount > 0 && (
                        <div className='rt--filters-button-mark rt--flex rt--align-center rt--justify-center'>
                            <span className='rt--title rt--font-bold rt--font-smallest'>{appliedFiltersCount}</span>
                        </div>
                    )
                }
            </Button>

            {
                !isMobile() ? (
                    <Drawer
                        open={active}
                        className='rt--filters-drawer'
                        title={t("backoffice.common.filters")}
                        placement={(isRTL(currentLn) ? 'left' : 'right')}
                        closable={true}
                        mask={true}
                        onClose={() => setActive(false)}
                        closeIcon={(<i className='icon-close rt--font-bigest' />)}
                        width={420}
                        footer={(
                            <div className='rt--flex rt--justify-between rt--align-cneter'>
                                <Button
                                    type="link"
                                    className="rt--button rt--button-ghost rt--mr-4"
                                    onClick={() => doReset(true)}
                                    disabled={!isFiltersTouched}
                                >
                                    <span>{t("backoffice.common.reset")}</span>
                                </Button>
                                <Button
                                    type="primary"
                                    className="rt--button rt--button-primary rt--ml-4"
                                    onClick={doFilter}
                                    disabled={!canApply}
                                >
                                    <span>{t("backoffice.common.apply")}</span>
                                </Button>
                            </div>
                        )}
                        getContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    >
                        { renderForm() }
                    </Drawer>
                ) : active ? (
                    <Modal
                        title={t('backoffice.common.filters')}
                        cancelText={t('backoffice.common.reset')}
                        okText={t('backoffice.common.apply')}
                        onOk={doFilter}
                        onCancel={() => setActive(false)}
                        onCancelButtonClick={() => doReset(true)}
                        disableOkButton={!canApply}
                        disableCancelButton={!isFiltersTouched}

                    >
                        { renderForm() }
                    </Modal>
                ) : null
            }


        </div>
    )
})

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Function to call to laod table data */
    loadFn: PropTypes.func.isRequired,
    /** Function to call to set filters */
    setFiltersFn: PropTypes.func.isRequired,
    /** Current filters */
    filters: PropTypes.object.isRequired,
    /** The JSX content of filters */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** Update props */
    updateProps: PropTypes.array,
    /** Fields mapping for reseting */
    resetFields: PropTypes.object,
    /** Date Picker Field Names */
    datePickerFields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        time: PropTypes.bool
    })),
    /** Search field name, to exclude, when comparing filters */
    searchFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    /** Function to call on reset */
    onReset: PropTypes.func,
    /** Function to call on first time filters init */
    onInit: PropTypes.func,
    /** Is Button disabled */
    disabled: PropTypes.bool
}


export default Filters;
