import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Tabs from "components/common/tabs";
import WorkingHours from './workingHours';

import { getHashValue} from 'utils/common';

/** Project Edit Page Terminal Tab Component */
const TerminalComponent = ({
    tabId,
    onTabChange
}) => {
    const { t } = useTranslation();

    const isActive = tabId === getHashValue('tab');

    const items = [
        {
            title: t("backoffice.projects.workingHours"),
            component: <WorkingHours />,
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            isParentActive={isActive}
            onTabChange={onTabChange}
        />
    )
}

/** TerminalComponent propTypes
    * PropTypes
*/
TerminalComponent.propTypes = {
    /** Tab identifier */
    tabId: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default TerminalComponent;
