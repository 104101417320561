import React, {useMemo} from 'react';
import {Col, Form, Row} from "antd";
import DateRangePicker from "components/common/dateRangePicker";
import Select from "components/common/select";
import Input from "components/common/input";
import FiltersWrapper from "components/common/filters";
import {connect} from "react-redux";
import {
    getPermissionRequests,
    setPermissionRequestsFilters
} from "store/actions/dashboard/userManagement/requests/pending.action";
import {useTranslation} from "react-i18next";
import {
    PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_OBJECT_TYPE_TRANSLATIONS,
    PERMISSION_REQUEST_TYPE,
    PERMISSION_REQUEST_TYPE_TRANSLATIONS
} from "constants/permissionRequest.constants";

const FILTERS_FIELD_NAMES = {
    DATE: "date",
    ACTION_TYPE: "actionType",
    OBJECT_TYPE: "objectType",
    NOTE: "note",
}

const Filters = ({ filters, getPermissionRequests, setPermissionRequestsFilters }) => {
    const { t } = useTranslation();

    const actionTypeOptions = useMemo(() => (
        Object.values(PERMISSION_REQUEST_TYPE).map(value => ({
            text: t(PERMISSION_REQUEST_TYPE_TRANSLATIONS[value]),
            value
        }))
    ), [t]);

    const objectTypeOptions = useMemo(() => (
        Object.values(PERMISSION_REQUEST_OBJECT_TYPE).map(value => ({
            text: t(PERMISSION_REQUEST_OBJECT_TYPE_TRANSLATIONS[value]),
            value
        }))
    ), [t]);

    return (
        <FiltersWrapper
            loadFn={getPermissionRequests}
            setFiltersFn={setPermissionRequestsFilters}
            filters={filters}
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: true },
            ]}
            searchFieldName="nameOrId"
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker showTime allowClear={false} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.actionType')}
                        name={FILTERS_FIELD_NAMES.ACTION_TYPE}
                    >
                        <Select
                            options={actionTypeOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.actionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.objectType')}
                        name={FILTERS_FIELD_NAMES.OBJECT_TYPE}
                    >
                        <Select
                            options={objectTypeOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.objectType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.notes')}
                        className='rt--general-form-item'
                        name={FILTERS_FIELD_NAMES.NOTE}
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.notes')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.notes')}`}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
};

const mapDispatchToProps = dispatch => (
    {
        getPermissionRequests: () => {
            dispatch(getPermissionRequests())
        },
        setPermissionRequestsFilters: filters => {
            dispatch(setPermissionRequestsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.permissionRequests.pending.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
