//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getAgentCurrencies,
    activateAgentCurrency,
    deactivateAgentCurrency
} from "store/actions/dashboard/agentSystem/agents/currencies.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import CurrencyAddComponent from "./currency-add.component";
//#endregion

//#region utils
import { hasPermission } from "utils/permissions";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { CURRENCY_STATE } from "constants/currency.constants";
import { AGENT_CURRENCIES } from 'constants/pageName.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import currencyType from 'types/currency/currency.type';
import userInfoType from 'types/profile/userInfo.type';
//#endregion

const ROW_UNIQUE_KEY_PROP = "currency";

/** Agent Edit Page Currencies Tab Component */
const CurrenciesComponent = ({
    getAgentCurrencies,
    isLoading,
    currencies,
    userInfo,
    activateAgentCurrency,
    deactivateAgentCurrency
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [isAddPopupVisible, setIsAddPopupVisible] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AGENT_CURRENCIES });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCurrenciesCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_CURRENCIES,
        action: PERMISSION_ACTION.CREATE
    })

    const hasCurrenciesModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_CURRENCIES,
        action: PERMISSION_ACTION.MODIFY
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSwitcherChange = (isChecked, record) => {
        if (isChecked) {
            activateAgentCurrency(searchParams.id, record.currency)
        } else {
            deactivateAgentCurrency(searchParams.id, record.currency)
        }
    }

    const showAddPopup = () => {
        setIsAddPopupVisible(true)
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            includedColumns,
            constructForInclude: true,
        });
    }, [includedColumns])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        button: {
            icon: "icon-plus",
            type: "primary",
            onClick: showAddPopup,
            text: t("backoffice.currencies.addCurrency"),
            enabled: hasCurrenciesCreatePermission && userInfo.id !== searchParams.id
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
    }

    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={currencies}
                loadFn={() => getAgentCurrencies(searchParams.id)}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                disableFullView={true}
                switcher={{
                    onChange: handleSwitcherChange,
                    valueIndex: "state",
                    dataIndex: "state",
                    className: !hasCurrenciesCreatePermission ? "rt--form-item-disabled" : "",
                    valueHandler: value => value === CURRENCY_STATE.ACTIVE,
                    disabled: record => (
                        !hasCurrenciesModifyPermission ||
                        searchParams.id === userInfo.id ||
                        !record.editable
                    )
                }}
            />

            {isAddPopupVisible && <CurrencyAddComponent onClose={hideAddPopup} />}
        </TabTableDashboardLayout>
    )
}

/** CurrenciesComponent propTypes
    * PropTypes
*/
CurrenciesComponent.propTypes = {
    /** Redux action to get agent currencies */
    getAgentCurrencies: PropTypes.func,
    /** Redux state property, is true when agent currencies are loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the currencies of current editing agent  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to activate agent currency */
    activateAgentCurrency: PropTypes.func,
    /** Redux action to deactivate agent currency */
    deactivateAgentCurrency: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getAgentCurrencies: id => {
            dispatch(getAgentCurrencies(id));
        },

        activateAgentCurrency: (id, currency) => {
            dispatch(activateAgentCurrency(id, currency));
        },

        deactivateAgentCurrency: (id, currency) => {
            dispatch(deactivateAgentCurrency(id, currency));
        }
    }
)

const mapStateToProps = state => {
    return {
        currencies: state.agents.edit.currencies.currencies,
        isLoading: state.agents.edit.currencies.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrenciesComponent)
