import React from 'react';

import {useTranslation} from "react-i18next";

import Confirmation from "components/common/confirmation";

const ProcessResultModal = ({ open, processed, completed, onClose }) => {
    const { t } = useTranslation();

    if (!open) {
        return null;
    }

    return (
        <Confirmation
            title={t('backoffice.common.result')}
            message={t('backoffice.common.processResultMessage').replace("%X%", completed).replace("%Y%", processed)}
            cancelText={t('backoffice.common.close')}
            onCancel={onClose}
            isVisible
            hideOkButton
        />
    );
};

export default ProcessResultModal;
