import React from 'react';

import {useTranslation} from "react-i18next";

import {Col, Form, Row} from "antd";

import TextAreaInput from "components/common/textAreaInput";
import Modal from "components/common/modal";

const ACTIONS = {
    APPROVE: 1,
    REJECT: 2
}

const ProcessModal = ({ confirmation, formInstance, onOk, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={confirmation.type === ACTIONS.APPROVE ? t('backoffice.common.approve') : t('backoffice.common.reject')}
            onOk={onOk}
            okText={t('backoffice.common.confirm')}
            cancelText={t('backoffice.common.cancel')}
            onCancel={onCancel}
            open={true}
        >
            {confirmation.ids?.length > 1 ? t('backoffice.common.proccessByBulk') : (confirmation.type === ACTIONS.APPROVE ? t('backoffice.common.approveConfirmationMessage') : "")}

            {confirmation.type === ACTIONS.REJECT && (
                <Form
                    className="rt--form rt--mt-8"
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        note: ""
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.users.notes')} *`}
                                name="note"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('backoffice.validation.fieldRequired')
                                    },
                                    {max: 1000, message: t('backoffice.validation.fieldInvalid')},
                                    {min: 6, message: t('backoffice.validation.fieldInvalid')}
                                ]}
                                className="rt--form-item-without-margin rt--general-form-item"
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                    maxLength={1000}
                                    rows={6}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            )}
        </Modal>
    );
};

export default ProcessModal;
