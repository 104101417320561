import React from 'react';
import {Checkbox, Form} from "antd";
import Tooltip from "components/common/tooltip";
import {useTranslation} from "react-i18next";

const CheckboxItem = ({ mb = 16, name, disabled, hasInfo = true, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className={`rt--flex rt--align-center rt--mb-${mb}` + (disabled ? " rt--disabled" : "")} key={name}>
            <Form.Item
                className='rt--form-item-without-margin rt--form-item-inline'
                name={name}
                valuePropName='checked'
            >
                <Checkbox disabled={disabled} onChange={onChange}/>
            </Form.Item>

            <div className='rt--flex rt--align-center'>
                <span className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--pr-4'>{t(`backoffice.projects.${name}`)}</span>
                {
                    hasInfo && (
                        <Tooltip
                            title={t(`backoffice.projects.${name}Info`)}
                            trigger={["hover", "click"]}
                            enableMobile={true}
                        >
                            <i className='icon-info rt--font-big'/>
                        </Tooltip>
                    )
                }
            </div>
        </div>
    );
};

export default CheckboxItem;
