import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { getAgentGeneralInfo } from "./general.action";

import {
    SET_AGENTS_ACTION_BEFORE,
    SET_AGENTS_ACTION_FINISH,
    SET_AGENTS_SAVE_ACTION_BEFORE,
    SET_AGENTS_SAVE_ACTION_FINISH,
    SET_AGENTS,
    SET_AGENTS_SORTING,
    SET_AGENTS_FILTERS,
    SET_AGENT_SUB_AGENTS_ACTION_BEFORE,
    SET_AGENT_SUB_AGENTS_ACTION_FINISH,
    SET_AGENT_SUB_AGENTS,
    SET_AGENT_SUB_AGENTS_SORTING,
    SET_AGENT_SUB_AGENTS_FILTERS,
    SET_AGENT_PLAYERS_ACTION_BEFORE,
    SET_AGENT_PLAYERS_ACTION_FINISH,
    SET_AGENT_PLAYERS,
    SET_AGENT_PLAYERS_SORTING,
    SET_AGENT_PLAYERS_FILTERS,
    SET_UPDATE_AGENT_PLAYER_DATA,
    SET_AGENT_BETSHOPS_ACTION_BEFORE,
    SET_AGENT_BETSHOPS_ACTION_FINISH,
    SET_AGENT_BETSHOPS,
    SET_AGENT_BETSHOPS_SORTING,
    SET_AGENT_BETSHOPS_FILTERS,
    SET_AGENT_BALANCE_OR_CREDIT,
    SET_AGENT_REGISTRATION_FORM_BEFORE,
    SET_AGENT_REGISTRATION_FORM_FINISH,
    SET_AGENT_REGISTRATION_FORM,
    SET_AVAILABLE_AGENTS_FOR_REPARENTING_BEFORE,
    SET_AVAILABLE_AGENTS_FOR_REPARENTING_FINISH,
    SET_AVAILABLE_AGENTS_FOR_REPARENTING,
    SET_AGENT_TOTALS_ACTION_BEFORE,
    SET_AGENT_TOTALS_ACTION_FINISH,
    SET_AGENT_TOTALS,
    SET_AGENT_PLAYER_TOTALS,
    SET_AGENT_PLAYER_TOTALS_ACTION_BEFORE,
    SET_AGENT_PLAYER_TOTALS_ACTION_FINISH
} from "../../../../actionTypes";

import { constructAgentsWalletData, getOrderedCreationFormFields } from 'utils/agents';
import { flagsToBinary } from 'utils/common';

import { USER_STATE } from 'constants/user.constants';

export const setAgentsActionBefore = () => ({
    type: SET_AGENTS_ACTION_BEFORE,
});

export const setAgentsActionFinished = () => ({
    type: SET_AGENTS_ACTION_FINISH,
});

export const setAgentsSaveActionBefore = () => ({
    type: SET_AGENTS_SAVE_ACTION_BEFORE,
});

export const setAgentsSaveActionFinished = () => ({
    type: SET_AGENTS_SAVE_ACTION_FINISH,
});

/** Agents */

const setAgents = (agents, add) => ({
    type: SET_AGENTS,
    payload: { agents, add },
});

export const setAgentsSorting = sorting => ({
    type: SET_AGENTS_SORTING,
    payload: { sorting },
});

export const setAgentsFilters = filters => ({
    type: SET_AGENTS_FILTERS,
    payload: { filters },
});


export const getAgents = nextPage => {
    return (dispatch, getState) => {

        const sorting = getState().agents.sorting;
        const filters = getState().agents.filters;
        const total = getState().agents.total;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if (page > 1 && total <= (page - 1) * sorting.limit) {
            return Promise.resolve();
        }

        dispatch(setAgentsActionBefore());

        let countriesStr = "";
        let countries = filters.countries;

        if (countries?.length > 0) {
            countries.forEach(c => {
                countriesStr += `${countriesStr !== "" ? "&" : ""}countries=${c}`
            })
        }
        let currenciesStr = "";
        let currencies = filters.currencies;

        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }
        let sendDeleted = filters.sendDeleted;
        let state = filters.state;
        if (!state) {
            if (!sendDeleted) {
                params.state = flagsToBinary(Object.values(USER_STATE).filter(v => v !== USER_STATE.DELETED))
            } else {
                params.state = flagsToBinary(Object.values(USER_STATE))
            }
        }


        delete params.sendDeleted;
        delete params.countries;
        delete params.currencies;

        return axios({
            url: `${ApiUrls.GET_AGENTS}?${countriesStr}${Boolean(currenciesStr) ? `&${currenciesStr}` : ""}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: agents } }) => {
                agents.item2 = constructAgentsWalletData(agents.item2);

                dispatch(setAgents(agents, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAgentsSorting({ ...sorting, page: page }));
                dispatch(setAgentsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentsActionFinished());
            });
    }
}

export const suspendAgents = (agentIds, onSuccess) => {
    return (dispatch) => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.SUSPEND_AGENT,
            method: Methods.POST,
            data: { agentIds },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess();
                }
                dispatch(setAgentsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentsActionFinished());
            });
    }
}

export const unsuspendAgents = (agentIds, onSuccess) => {
    return (dispatch) => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.UNSUSPEND_AGENT,
            method: Methods.POST,
            data: { agentIds },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess();
                }
                dispatch(setAgentsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentsActionFinished());
            });
    }
}

/** Sub Agents */

const setAgentSubAgentsActionBefore = () => ({
    type: SET_AGENT_SUB_AGENTS_ACTION_BEFORE,
});

const setAgentSubAgentsActionFinished = () => ({
    type: SET_AGENT_SUB_AGENTS_ACTION_FINISH,
});

const setAgentSubAgents = (agents, add) => ({
    type: SET_AGENT_SUB_AGENTS,
    payload: { agents, add },
});

export const setAgentSubAgentsSorting = sorting => ({
    type: SET_AGENT_SUB_AGENTS_SORTING,
    payload: { sorting },
});

export const setAgentSubAgentsFilters = filters => ({
    type: SET_AGENT_SUB_AGENTS_FILTERS,
    payload: { filters },
});

export const getAgentSubAgents = (agentId, nextPage) => {
    return (dispatch, getState) => {

        const filters = getState().agents.subAgents.filters;
        const sorting = getState().agents.subAgents.sorting;
        const total = getState().agents.subAgents.total;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        params.parentUserNameOrId = agentId;

        let countriesStr = "";
        let countries = filters.countries;
        if (countries?.length > 0) {
            countries.forEach(c => {
                countriesStr += `${countriesStr !== "" ? "&" : ""}countries=${c}`
            })
        }
        delete params.countries;

        let sendDeleted = filters.sendDeleted;
        let state = filters.state;
        if (!state) {
            if (!sendDeleted) {
                params.state = flagsToBinary(Object.values(USER_STATE).filter(v => v !== USER_STATE.DELETED))
            } else {
                params.state = flagsToBinary(Object.values(USER_STATE))
            }
        }

        if (page > 1 && total <= (page - 1) * sorting.limit) {
            return Promise.resolve();
        }

        dispatch(setAgentSubAgentsActionBefore());

        return axios({
            url: `${ApiUrls.GET_AGENTS}?${countriesStr}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: agents } }) => {
                agents.item2 = constructAgentsWalletData(agents.item2);

                dispatch(setAgentSubAgents(agents, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAgentSubAgentsSorting({ ...sorting, page: page }));
                dispatch(setAgentSubAgentsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentSubAgentsActionFinished());
            });
    }
}


/** Players */

const setAgentPlayersActionBefore = () => ({
    type: SET_AGENT_PLAYERS_ACTION_BEFORE,
});

const setAgentPlayersActionFinished = () => ({
    type: SET_AGENT_PLAYERS_ACTION_FINISH,
});

const setAgentPlayers = (players, add) => ({
    type: SET_AGENT_PLAYERS,
    payload: { players, add },
});

export const setAgentPlayersSorting = sorting => ({
    type: SET_AGENT_PLAYERS_SORTING,
    payload: { sorting },
});

export const setAgentPlayersFilters = filters => ({
    type: SET_AGENT_PLAYERS_FILTERS,
    payload: { filters },
});

const setUpdateAgentPlayerData = (data, id) => ({
    type: SET_UPDATE_AGENT_PLAYER_DATA,
    payload: { data, id },
});

const setAgentPlayersTotals = (totals) => ({
    type: SET_AGENT_PLAYER_TOTALS,
    payload: { totals },
})

export const setAgentPlayersTotalsActionBefore = () => ({
    type: SET_AGENT_PLAYER_TOTALS_ACTION_BEFORE,
});

export const setAgentPlayersTotalsActionFinished = () => ({
    type: SET_AGENT_PLAYER_TOTALS_ACTION_FINISH,
});

export const getAgentPlayers = (agentId, nextPage) => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().agents.players.sorting.page + 1 : getState().agents.players.sorting.page;
        let params = {
            ...getState().agents.players.sorting,
            ...getState().agents.players.filters,
            page: page
        };

        params.parentUserNameOrId = agentId;

        if (page > 1 && getState().agents.players.total <= (page - 1) * getState().agents.players.sorting.limit) {
            return Promise.resolve();
        }

        dispatch(setAgentPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: players } }) => {
                dispatch(setAgentPlayers(players, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAgentPlayersSorting({ ...getState().agents.players.sorting, page: page }));
                dispatch(setAgentPlayersActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentPlayersActionFinished());
            });
    }
}

export const getAgentPlayersTotals = (agentId) => {
    return (dispatch, getState) => {

        let params = {
            ...getState().agents.players.filters,
        };

        params.parentUserNameOrId = agentId;

        dispatch(setAgentPlayersTotalsActionBefore());

        return axios({
            url: ApiUrls.PLAYER_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value } }) => {
                dispatch(setAgentPlayersTotals(value));
                dispatch(setAgentPlayersTotalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentPlayersTotalsActionFinished());
            });
    }
}

export const updateAgentPlayerData = id => {
    return dispatch => {
        dispatch(setAgentPlayersActionBefore());

        return axios({
            url: ApiUrls.GET_PLAYER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
            .then(({ data }) => {
                dispatch(setUpdateAgentPlayerData(data.value, id))
                dispatch(setAgentPlayersActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentPlayersActionFinished());
            })
    }
}

/** Betshops */

const setAgentBetshopsActionBefore = () => ({
    type: SET_AGENT_BETSHOPS_ACTION_BEFORE,
});

const setAgentBetshopsActionFinished = () => ({
    type: SET_AGENT_BETSHOPS_ACTION_FINISH,
});

const setAgentBetshops = (betshops, add) => ({
    type: SET_AGENT_BETSHOPS,
    payload: { betshops, add },
});

export const setAgentBetshopsSorting = sorting => ({
    type: SET_AGENT_BETSHOPS_SORTING,
    payload: { sorting },
});

export const setAgentBetshopsFilters = filters => ({
    type: SET_AGENT_BETSHOPS_FILTERS,
    payload: { filters },
});

export const getAgentBetshops = (agentId, nextPage) => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().agents.betshops.sorting.page + 1 : getState().agents.betshops.sorting.page;
        let params = {
            ...getState().agents.betshops.sorting,
            ...getState().agents.betshops.filters,
            page: page
        };

        params.parentUserNameOrId = agentId;

        if (page > 1 && getState().agents.betshops.total <= (page - 1) * getState().agents.betshops.sorting.limit) {
            return Promise.resolve();
        }

        dispatch(setAgentBetshopsActionBefore());

        return axios({
            url: ApiUrls.GET_BETSHOPS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: betshops } }) => {
                dispatch(setAgentBetshops(betshops, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAgentBetshopsSorting({ ...getState().agents.betshops.sorting, page: page }));
                dispatch(setAgentBetshopsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentBetshopsActionFinished());
            });
    }
}

export const setAgentBalanceOrCredit = wallets => ({
    type: SET_AGENT_BALANCE_OR_CREDIT,
    payload: { wallets }
});

export const createAgent = (agent, onSuccess) => {
    return (dispatch) => {
        dispatch(setAgentsSaveActionBefore());

        const { subProjects } = agent;

        if (subProjects) {
            agent.subProjects = (
                Object
                    .entries(subProjects)
                    .reduce((acc, [key, value]) => {
                        value.forEach(id => {
                            if (!acc[id]) {
                                acc[id] = [];
                            }

                            acc[id].push(key.toUpperCase());
                        })

                        return acc;
                    }, {})
            );
        }


        return axios({
            url: ApiUrls.CREATE_AGENT,
            method: Methods.POST,
            data: {
                ...agent
            },
        })
            .then(({ data }) => {
                dispatch(setAgentsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setAgentsSaveActionFinished());
            });
    };
};

export const changeAgentState = d => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_STATE,
            method: Methods.POST,
            data: d
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAgentGeneralInfo(d.id));
                }
                dispatch(setAgentsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentsSaveActionFinished());
            })
    }
}

export const forceLogout = () => {
    return (dispatch, getState) => {
        dispatch(setAgentsSaveActionBefore());

        const filters = { ...getState().agents.filters };

        Object.keys(filters).map(k => {
            if (
                (
                    filters[k] === "" || filters[k] === undefined
                )
            ) {
                delete filters[k];
            }
        })

        if (Array.isArray(filters.countries) && filters.countries.length === 0) {
            filters.countries = null;
        }

        if (Array.isArray(filters.currencies) && filters.currencies.length === 0) {
            filters.currencies = null;
        }

        return axios({
            url: ApiUrls.FORCE_LOGOUT_AGENT,
            method: Methods.POST,
            data: {
                ...getState().agents.sorting,
                ...filters,
                page: getState().agents.sorting.page
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAgents());
                }
                dispatch(setAgentsSaveActionFinished());
            })
            .catch(() => {
                dispatch(setAgentsSaveActionFinished());
            })
    }
}

export const deleteAgent = (id, parentId, onSuccess) => {
    return (dispatch) => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_AGENT,
            method: Methods.DELETE,
            data: {
                id,
                parentId
            },
        })
            .then(({ data }) => {
                dispatch(setAgentsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setAgentsSaveActionFinished());
            });
    };
};

export const reparentAgent = (id, parentId, onSuccess) => {
    return (dispatch) => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.REPARENT_AGENT,
            method: Methods.POST,
            data: {
                id,
                parentId
            },
        })
            .then(({ data }) => {
                dispatch(setAgentsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setAgentsSaveActionFinished());
            });
    };
};

export const unlockAgent = id => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.UNLOCK_ACCOUNT,
            method: Methods.POST,
            data: {
                id
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAgentGeneralInfo(id));
                }
                dispatch(setAgentsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentsSaveActionFinished());
            })
    }
}

/** Registration form */

const setAgentRegistrationFormBefore = () => ({
    type: SET_AGENT_REGISTRATION_FORM_BEFORE,
});

const setAgentRegistrationFormFinished = () => ({
    type: SET_AGENT_REGISTRATION_FORM_FINISH,
});

const setAgentRegistrationForm = registrationForm => ({
    type: SET_AGENT_REGISTRATION_FORM,
    payload: { registrationForm }
})

export const getAgentRegistrationForm = () => {
    return dispatch => {

        dispatch(setAgentRegistrationFormBefore());
        return axios({
            url: ApiUrls.GET_AGENT_REGISTRATION_FORM,
            method: Methods.GET
        })
            .then(({ data: { value: registrationForm } }) => {
                dispatch(setAgentRegistrationForm(getOrderedCreationFormFields(registrationForm)))
                dispatch(setAgentRegistrationFormFinished());
            })
            .catch((ex) => {
                dispatch(setAgentRegistrationFormFinished());
            })
    }
}

/** Available agents for reparenting */

const setAvailableAgentsForReparentingBefore = () => ({
    type: SET_AVAILABLE_AGENTS_FOR_REPARENTING_BEFORE,
});

const setAvailableAgentsForReparentingFinished = () => ({
    type: SET_AVAILABLE_AGENTS_FOR_REPARENTING_FINISH,
});

const setAvailableAgentsForReparenting = agents => ({
    type: SET_AVAILABLE_AGENTS_FOR_REPARENTING,
    payload: { agents }
})

export const getAvailableAgentsForReparenting = (id, isForDelete, reparentEntityType) => {
    return dispatch => {

        dispatch(setAvailableAgentsForReparentingBefore());
        return axios({
            url: ApiUrls.GET_AVAILABLE_AGENTS_FOR_REPARENTING,
            method: Methods.GET,
            params: {
                id, isForDelete, reparentEntityType
            }
        })
            .then(({ data: { value: agents } }) => {
                dispatch(setAvailableAgentsForReparenting(agents))
                dispatch(setAvailableAgentsForReparentingFinished());
            })
            .catch((ex) => {
                dispatch(setAvailableAgentsForReparentingFinished());
            })
    }
}

export const getAvailableAgentsForPlayersReparenting = ids => {
    return dispatch => {

        dispatch(setAvailableAgentsForReparentingBefore());

        let idsStr = "";
        ids.forEach(id => {
            idsStr += `${idsStr !== "" ? "&" : ""}ids=${id}`
        })

        return axios({
            url: `${ApiUrls.GET_AVAILABLE_AGENTS_FOR_PLAYERS_REPARENTING}?${idsStr}`,
            method: Methods.GET,
            params: {}
        })
            .then(({ data: { value: agents } }) => {
                dispatch(setAvailableAgentsForReparenting(agents))
                dispatch(setAvailableAgentsForReparentingFinished());
            })
            .catch((ex) => {
                dispatch(setAvailableAgentsForReparentingFinished());
            })
    }
}

/** Totals */

const setAgentTotalsActionBefore = () => ({
    type: SET_AGENT_TOTALS_ACTION_BEFORE,
});

const setAgentTotalsActionFinished = () => ({
    type: SET_AGENT_TOTALS_ACTION_FINISH,
});

const setAgentTotals = totals => ({
    type: SET_AGENT_TOTALS,
    payload: { totals },
});

export const getAgentTotals = readFromFunction => {
    return (dispatch, getState) => {
        const filters = getState().agents.filters;

        let params = {
            ...filters,
        };

        dispatch(setAgentTotalsActionBefore());

        let countriesStr = "";
        let countries = filters.countries;

        if (countries?.length > 0) {
            countries.forEach(c => {
                countriesStr += `${countriesStr !== "" ? "&" : ""}countries=${c}`
            })
        }

        let currenciesStr = "";
        let currencies = filters.currencies;

        if (currencies?.length > 0) {
            currencies.forEach(code => {
                currenciesStr += `${currenciesStr !== "" ? "&" : ""}currencies=${code}`
            })
        }
        let sendDeleted = filters.sendDeleted;
        let state = filters.state;
        if (!state) {
            if (!sendDeleted) {
                params.state = flagsToBinary(Object.values(USER_STATE).filter(v => v !== USER_STATE.DELETED))
            } else {
                params.state = flagsToBinary(Object.values(USER_STATE))
            }
        }


        delete params.sendDeleted;
        delete params.countries;
        delete params.currencies;
        if(readFromFunction){
            params.readFromFunction = true;
        }

        return axios({
            url: `${ApiUrls.GET_AGENTS_WALLET_TOTALS}?${countriesStr}${Boolean(currenciesStr) ? `&${currenciesStr}` : ""}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data }) => {
                const totals = data.value;
                dispatch(setAgentTotals(totals));
                dispatch(setAgentTotalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAgentTotalsActionFinished());
            });
    }
}
