import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { TOKEN_TYPE } from 'constants/auth.constants';

import { isResponseSuccess } from "utils/request";
import LanguageUtils from 'utils/languages';
import sessionStorageUtils from 'utils/sessionStorage';
import {getUser, loginUser} from 'utils/auth';

import { initTranslations } from 'translations/config';

import {
    setGlobalCompanyId,
    setGlobalProjectId,
    setGlobalProjectType,
    setGlobalPaymentType,
    setGlobalPlatVersion, setGlobalProjectMode
} from 'store/actions/dashboard/common/common.action';

import {
    SET_USER_INFO_ACTION_BEFORE,
    SET_USER_INFO_ACTION_FINISH,
    SET_USER_INFO,
    UPDATE_USER_AVATAR,
    SET_CHANGE_PROJECT_ACTION_BEFORE,
    SET_CHANGE_PROJECT_ACTION_FINISH,
    SET_NOTIFICATION_SETTINGS
} from "../../../actionTypes";
import {injectScript} from "utils/common";
import {USER_ROLE} from "constants/user.constants";

/** User Info */

const setUserInfoActionBefore = () => ({
    type: SET_USER_INFO_ACTION_BEFORE,
});

const setUserInfoActionFinished = () => ({
    type: SET_USER_INFO_ACTION_FINISH,
});

const setUserInfo = userInfo => ({
    type: SET_USER_INFO,
    payload: { userInfo }
})

const setNotificationSettings = settings => ({
    type: SET_NOTIFICATION_SETTINGS,
    payload: { settings }
})

export const getUserInfo = loadInBackground => {
    return dispatch => {
        if(!loadInBackground){
            dispatch(setUserInfoActionBefore());
        }

        return axios({
            url: ApiUrls.GET_USER_INFO,
            method: Methods.GET,
            params: {
                languageCode: LanguageUtils.getSelectedLanguage()
            }
        })
        .then(({data : {value: info}}) => {
            const infoValue = {...info };
            infoValue.permissions = info.permissions ?? {}
            dispatch(setUserInfo(infoValue));

            sessionStorageUtils.set("userInfo", infoValue);

            dispatch(setUserInfoActionFinished());

            if(!loadInBackground){
                const isAgentUser = getUser()?.role === USER_ROLE.AGENT;

                initTranslations(LanguageUtils.getSelectedLanguage(), infoValue.translationPath);

                if (info.chatScriptData?.isEnable && isAgentUser) {
                    injectScript(info.chatScriptData.data, document.body);
                }
            }
        })
        .catch((ex) => {
            dispatch(setUserInfoActionFinished());
        })
    }
}

export const updateUserAvatar = avatarId => {
    const userInfo = sessionStorageUtils.get("userInfo");
    if(userInfo){
        userInfo.avatarId = avatarId;
        sessionStorageUtils.set("userInfo", userInfo)
    }
    return {
        type: UPDATE_USER_AVATAR,
        payload: { avatarId }
    }
}

/** Change Project */
const setChangeProjectActionBefore = () => ({
    type: SET_CHANGE_PROJECT_ACTION_BEFORE,
});

const setChangeProjectActionFinished = () => ({
    type: SET_CHANGE_PROJECT_ACTION_FINISH,
});

export const changeProject = (companyId, projectId, onSuccess) => {
    return dispatch => {
        dispatch(setChangeProjectActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_PROJECT,
            method: Methods.POST,
            data: { companyId, projectId }
        })
        .then(({ status, data }) => {
            if (isResponseSuccess(data)) {
                if (data.value.tokenType === TOKEN_TYPE.NONE) {
                    const value = data.value;
                    loginUser(value);
                    dispatch(setGlobalProjectId(value.projectId));
                    dispatch(setGlobalCompanyId(value.companyId));
                    dispatch(setGlobalProjectType(value.projectType));
                    dispatch(setGlobalProjectMode(value.projectMode));
                    dispatch(setGlobalPaymentType(value.paymentType));
                    dispatch(setGlobalPlatVersion(value.platformIntegrationVersion));

                    if(onSuccess){
                        if (!projectId) {
                            onSuccess();
                        } else {
                            setTimeout(() => {
                                onSuccess();
                            }, 0)
                        }
                    }

                }
                dispatch(setChangeProjectActionFinished());
            }
        })
        .catch(() => {
            dispatch(setChangeProjectActionFinished());
        })
    }
}

export const changeNotificationSettings = (data) => {
    return dispatch => {
        return axios({
            url: ApiUrls.CHANGE_NOTIFICATION_SETTINGS,
            method: Methods.POST,
            data: data
        })
        .then(() => {
            const {id: _, ...settings} = data;
            dispatch(setNotificationSettings(settings));
        })
        .catch((ex) => {})
    }
}
