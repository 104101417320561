export const WEEKDAYS = {
    SUNDAY: "sunday",
    MONDAY: "monday",
    TUESDAY: "tuesday",
    WEDNESDAY: "wednesday",
    THURSDAY: "thursday",
    FRIDAY: "friday",
    SATURDAY: "saturday"
}

export const WEEKDAYS_TRANSLATIONS = {
    [WEEKDAYS.SUNDAY]: "backoffice.weekdays.sunday",
    [WEEKDAYS.MONDAY]: "backoffice.weekdays.monday",
    [WEEKDAYS.TUESDAY]: "backoffice.weekdays.tuesday",
    [WEEKDAYS.WEDNESDAY]: "backoffice.weekdays.wednesday",
    [WEEKDAYS.THURSDAY]: "backoffice.weekdays.thursday",
    [WEEKDAYS.FRIDAY]: "backoffice.weekdays.friday",
    [WEEKDAYS.SATURDAY]: "backoffice.weekdays.saturday"
}