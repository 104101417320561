import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAgentAvailableCurrencies} from "store/actions/dashboard/agentSystem/agents/currencies.action";

const useAgentAvailableCurrencies = ({ id }) => {
    const currencies = useSelector(state => state.agents.edit.currencies.availableCurrencies);
    const loading = useSelector(state => state.agents.edit.currencies.isAvailableLoading);
    const hasOneCurrency = Object.keys(currencies || {}).length === 1;

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getAgentAvailableCurrencies(id));
        }
    }, [id]);

    return {
        currencies,
        loading,
        hasOneCurrency
    }
}

export default useAgentAvailableCurrencies;
