import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDate from "hooks/useDate";

import { Col, Form, message, Row, Spin, Switch } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import TimePicker from "components/common/timepicker";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import { WEEKDAYS, WEEKDAYS_TRANSLATIONS } from "./constants";

const WorkingHours = ({
    onTabChange,
    getWorkingHoursConfigs, 
    saveWorkingHoursConfigs
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const TIME_FORMAT = dateService.getFormat(true, false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [ workingHoursConfigs, setWorkingHoursConfigs ] = useState(false);

    const [ timeDiffs, setTimeDiffs ] = useState({});

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_TERMINALS, action: PERMISSION_ACTION.MODIFY });

    useEffect(() => {
        setIsLoading(true)
        getWorkingHoursConfigs()
            .then(value => {
                const mapped = mapDataToForm(value.weekDays);
                setWorkingHoursConfigs(value.weekDays);
                updateTimeDiffs(value.weekDays);
                setFieldsValue(mapped);
            })
            .finally(value => {
                setIsLoading(false)
            })
    }, [])

    const onFormValuesChange = (_, formValues) => {
        const initialValues = { ...workingHoursConfigs }, values = { ...mapFormToData(formValues)};

        updateTimeDiffs(values);

        setIsFormTouched(isFormChanged(initialValues, values));
    }

    const handleForm = () => {
        validateFields()
            .then(data => {
                setIsSaving(true);
                const d = {
                    weekDays: mapFormToData(data)
                }
                saveWorkingHoursConfigs(d)
                    .then(({value, message: msg}) => {
                        message.success(msg)
                        setWorkingHoursConfigs(value);
                        setIsFormTouched(false);
                    })
                    .finally(() => {
                        setIsSaving(false)
                    })
                
            }).catch(err => {
                console.log(err)
            })
    }

    const mapDataToForm = data => {
        const mapped = {};

        Object.keys(data).forEach(w => {
            mapped[w] = {
                active: data[w].active,
                startTime: dateService.getDate(`${data[w].startTime.hour}:${data[w].startTime.minute}`, TIME_FORMAT),
                endTime: dateService.getDate(`${data[w].endTime.hour}:${data[w].endTime.minute}`, TIME_FORMAT)
            };
        })

        return mapped;
    }

    const mapFormToData = data => {
        const mapped = {};

        Object.keys(data).forEach(w => {
            mapped[w] = {
                active: data[w].active,
                startTime: {
                    hour: data[w].startTime.hour(),
                    minute: data[w].startTime.minutes()
                },
                endTime: {
                    hour: data[w].endTime.hour(),
                    minute: data[w].endTime.minutes()
                }
            };
        })

        return mapped;
    }

    const updateTimeDiffs = data => {
        const result = {};
        Object.keys(data).forEach(weekday => {
            const time1Data = data[weekday].startTime;
            const time2Data = data[weekday].endTime;
            let totalMinutes1 = time1Data.hour * 60 + time1Data.minute;
            let totalMinutes2 = time2Data.hour * 60 + time2Data.minute;
            if (totalMinutes2 < totalMinutes1) {
                totalMinutes2 += 24 * 60; 
            }
            let diffMinutes = totalMinutes2 - totalMinutes1;
            let diffHours = Math.floor(diffMinutes / 60);
            let diffMins = diffMinutes % 60;
            result[weekday] = diffMins === 0 ? `${diffHours}h` : `${diffHours}h ${diffMins}m`
        })
        setTimeDiffs(result)
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={onFormValuesChange}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <h4 className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                {
                                     t("backoffice.projects.terminalWorkingHours")
                                }
                            </h4>
                        </Col>
                        <Col span={24}>
                            {
                                Object.values(WEEKDAYS).map(weekday => (
                                    <div
                                        className='rt--flex rt--align-center rt--mb-16 rt--working-hours'
                                        key={weekday}
                                    >
                                        <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                                            <Form.Item
                                                name={[weekday, "active"]}
                                                valuePropName="checked"
                                                className='rt--form-item-without-margin'
                                            >
                                                <Switch
                                                    disabled={!hasModifyPermission}
                                                />
                                            </Form.Item>
                                            <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t(WEEKDAYS_TRANSLATIONS[weekday])}</label>
                                        </div>
                                        <Form.Item
                                            name={[weekday, "startTime"]}
                                            className='rt--form-item-without-margin rt--form-item-without-label rt--form-timepicker'
                                        >
                                            <TimePicker
                                                format={TIME_FORMAT}
                                                allowClear={false}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={!hasModifyPermission}
                                                className='rt--timepicker'
                                                popupClassName='rt--timepicker-dropdown'
                                                placement="bottomRight"
                                                showNow={false}
                                                open={!hasModifyPermission ? false : undefined}
                                            />
                                        </Form.Item>
                                        <span className="rt--working-hours-label">{t("backoffice.common.to")}</span>
                                        <Form.Item
                                            name={[weekday, "endTime"]}
                                            className='rt--form-item-without-margin rt--form-item-without-label rt--form-timepicker'
                                        >
                                            <TimePicker
                                                format={TIME_FORMAT}
                                                allowClear={false}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={!hasModifyPermission}
                                                className='rt--timepicker'
                                                popupClassName='rt--timepicker-dropdown'
                                                placement="bottomRight"
                                                showNow={false}
                                                open={!hasModifyPermission ? false : undefined}
                                            />
                                        </Form.Item>
                                        <div className="rt--flex rt--align-center">
                                            <i className="icon-time rt--font-biger" />
                                            <span className="rt--working-hours-label rt--pl-4">{timeDiffs[weekday] ?? ""}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

export default WorkingHours;