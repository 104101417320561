import {useCallback, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {getCurrencies} from "store/actions/dashboard/profile/currencies.action";

const selectGlobalProjectId = state => state.common.globalProjectId;
const selectCurrencies = state => state.profile.currencies;

const useUserCurrencies = (autoCall = true) => {
    //#region ------------------------------------------- REDUX --------------------------------------------//

    const dispatch = useDispatch();
    const globalProjectId = useSelector(selectGlobalProjectId);
    const currencies = useSelector(selectCurrencies);

    //#endregion

    const fn = useCallback(() => {
        dispatch(getCurrencies());
    }, [globalProjectId]);

    const hasOneCurrency = useMemo(() => {
        return currencies.length === 1;
    }, [currencies]);

    // Load Currencies
    useEffect(() => {
         if(autoCall){
            fn()
         }
    }, [globalProjectId, dispatch]);


    return [currencies, fn, hasOneCurrency];
}

export default useUserCurrencies;
