export const TERMINAL_STATUS = {
    INACTIVE: 1 << 0,
    ACTIVE: 1 << 1,
    BLOCKED: 1 << 2
}

export const TERMINAL_STATE = {
    RUNNING: 1 << 0,
    NOT_RUNNING: 1 << 1,
}
