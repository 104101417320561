export const PERMISSION_REQUEST_TYPE = {
    GROUP_MODIFY: 1 << 0,
    ADD_COMPANY_ACCESS: 1 << 1,
    DELETE_COMPANY_ACCESS: 1 << 2,
    ADD_PROJECT_ACCESS: 1 << 3,
    DELETE_PROJECT_ACCESS: 1 << 4,
    ADD_PERMISSION_GROUP: 1 << 5,
    DELETE_PERMISSION_GROUP: 1 << 6,
    SET_PASSWORD: 1 << 7,
}

export const PERMISSION_REQUEST_TYPE_TRANSLATIONS = {
    [PERMISSION_REQUEST_TYPE.GROUP_MODIFY]: "backoffice.common.groupModify",
    [PERMISSION_REQUEST_TYPE.ADD_COMPANY_ACCESS]: "backoffice.common.addCompanyAccess",
    [PERMISSION_REQUEST_TYPE.DELETE_COMPANY_ACCESS]: "backoffice.common.deleteCompanyAccess",
    [PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS]: "backoffice.common.addProjectAccess",
    [PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS]: "backoffice.common.deleteProjectAccess",
    [PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP]: "backoffice.common.addPermissionGroup",
    [PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]: "backoffice.common.deletePermissionGroup",
    [PERMISSION_REQUEST_TYPE.SET_PASSWORD]: "backoffice.common.setPassword",
}

export const PERMISSION_REQUEST_OBJECT_TYPE = {
    PERMISSION_GROUP: 1 << 0,
    USER: 1 << 1
}

export const PERMISSION_REQUEST_OBJECT_TYPE_TRANSLATIONS = {
    [PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP]: "backoffice.common.permissionGroup",
    [PERMISSION_REQUEST_OBJECT_TYPE.USER]: "backoffice.common.user",
}


export const PERMISSION_REQUEST_STATUS = {
    APPROVED: 1,
    REJECTED: 2
}
